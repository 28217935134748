import styled from 'styled-components';

export const Container = styled.div`
	background: var(--bakground);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;

	.entrar {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
		border: none;
		margin: 30px 0 0 0;
	}

	p {
		height: 0;
	}
	.userInvalid {

		margin-bottom: 25px;

		span {
			color: red;
		}

	}
`;

export const CardContent = styled.div`
	background: white;
	width: 50%;
	max-width: 640px;
	min-width: 360px;
	border-radius: 10px;
	min-height: 465px;
`;

export const CardHeader = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	padding: 30px 0px;
`;
export const CardBody = styled.div`
	width: 100%;
	padding: 5%;
	min-width: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const Title = styled.h4`
	font-size: 14px;
	line-height: 20px;
	letter-spacing: 0.25px;
`;

export const Logo = styled.img``;
