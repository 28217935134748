import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { Container, ContentError } from "./style"

interface IProps  {
    hasOpen: boolean;
    handleClose: () => void;
    errorMsg: string;
    title: string;

}

export const Error = (props: IProps) => {
    
    const {hasOpen, handleClose, errorMsg, title} = props;

    return (
        <Container>
            <Dialog
                    fullWidth={true}
                    maxWidth={'md'}
					open={hasOpen}
					onClose={handleClose}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
				>
					<DialogTitle
                        id="alert-dialog-title"
                        align="center"
                        color={'red'}
                        >
                        
					{title}
					</DialogTitle>
					<DialogContent>
					<DialogContentText id="alert-dialog-description">
                        <ContentError>
                            {errorMsg}
                        </ContentError>
						
					</DialogContentText>
					</DialogContent>
					<DialogActions>
					<Button onClick={handleClose} autoFocus>
						Fechar
					</Button>
					</DialogActions>
				</Dialog>
        </Container>
    )
}