import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    background: #FFFFFF;
    border: 3px solid var(--very-dark-blue);
    backdrop-filter: blur(4px);
    border-radius: 20px;
    padding: 45px;
    .firstContainer {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 31px;
            line-height: 36px;
            text-align: center;
            color: var(--very-dark-gray);
        }
    }
    .description {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.5px;
        color: var(--text-body);
        margin-top: 26px;
        margin-bottom: 45px;
    }

    .subTitle {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
         text-align: center;
        letter-spacing: 0.15px;
        max-width: 400px;
        color: var(--purple-light);
        margin-top: 27px;
        margin-bottom: 17px;
    }
    .itemContainerTab {
        display: flex;
        margin-bottom: 14px;
        img {
            margin-right: 13px;
        }
        div {
            max-width: 303px;
        }

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: var(--black);
        }
    }

    .secondContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-left: 80px;
        form div {
            margin-bottom: 15px;
        }

        h6 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: var(--moderate-cyan);
        }

    }

    .secondTitle {
        margin-bottom: 57px;

    }
    .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
        color: var(--dark-blue);
    }

    .containerForm {
        width: -webkit-fill-available;
    }


`;