import { CardLarge } from "../../CardLarge";
import { ICard } from "../../CardLarge/ICard";
import { Container } from "./style";


interface IProps {
    cards: ICard[]
}
export const FirstTab = (props: IProps) => (
    <Container>
        <div className="FirstTab">
            {
                props.cards.map((card, index) => (
                    <CardLarge card={card} key={index}/>
                ))
            }
        </div>
    </Container>
)