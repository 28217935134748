
interface IFaq {
    question: string;
    answer: string;
}

export const faqs: IFaq[] = [{
    question: 'Como funciona a plataforma CREDIT ON?',
    answer: 'Coloque o CNPJ e dê upload de documentos digitais como balanços, DRE e contrato social e sua análise de crédito será feita automaticamente, sugerindo limite crédito em minutos.',
},{
    question:'Qual o benefício de usar a plataforma CREDIT ON?',
    answer: `Eficiência operacional no processo de análise e concessão de crédito para sua empresa.  
    A Política de Crédito da sua empresa pode ser embarcada na plataforma e a análise de crédito é feita automaticamente usando os dados de mercado e dados dos balanços/DRE, apresentando em poucos minutos o limite de crédito. 
    `,
}, {
    question: 'Como faço para testar?',
    answer: 'Entre em contato com o email: comercial@crediton.tech ou agende pelo link : https://calendly.com/josefina-credit-on/30min para apresentarmos a plataforma e disponibilizar para sua empresa fazer uma POC – Prova de Conceito, gratuita.',
},{
    question: 'Quanto custa a analise de crédito na Plataforma CREDIT ON.',
    answer: `Depende do volume de documentos digitais que são incluídos na plataforma. 
    Entre em contato pelo email: comercial@crediton.tech ou agende pelo https://calendly.com/josefina-credit-on/30min  para agendar uma apresentação da plataforma e da precificação. 
    `,
}

]