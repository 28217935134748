import { useState } from 'react';
import {
    Card,
    CardContent,
    TextField,
    Button as ButtonUi,
    Box,
    InputLabel,
    Select,
    MenuItem,
    TextareaAutosize,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Grid,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    IconButton,
    Typography,
    Switch,
} from '@mui/material';
import { Button } from '../Button';
import { Header } from '../Header';
import {
    Container,
    Form,
    FormField,
    Input,
    InputStyled,
    SubTitle,
    Title,
    Text,
    Load,
    LoadFile,
    AddNewFile,
    ContainerForm,
    ContainerTextArea,
} from './style';
import loadingArrow from '../../assets/arrowLoading.gif';
import { Loading } from '../Loading';
import icoAdd from '../../assets/add.svg';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { mascaraMoeda } from '../../utils/mask';
import { useEffect } from "react";
import { apiOperation, apiExtractor, headers } from '../../services/api';

interface OpcaoConfiguracao {
    opcaoParametroId: string;
    descricaoParamentro: string;
    pesoAte: number;
    pesoAcima: number;
    fator: number;
    ativo: boolean;
    tipoParametro: number;
    valorPeso: number;
}

interface DeParaData {
    descricao: string;
    valor: string;
}

interface Response {
    erro: boolean;
    erros: string[];
    statusCode: number;
    valor: { opcoesParametros: OpcaoConfiguracao[], deParaConfig: DeParaData[] }
}

const paraOptions = ['Consig', 'Boleto'];

const getTituloPorTipoParametro = (tipoParametro: number): string => {
    switch (tipoParametro) {
        case 111:
            return "Tempo de Cooperativa";
        case 63:
            return "Score Serasa";
        case 109:
            return "Nível de Comprometimento em relação à renda";
        case 110:
            return "Posto de Atendimento";
        case 113:
            return "Score BoaVista";
        default:
            return "Tipo de Parametro Desconhecido";
    }
};

// Função para agrupar os dados por tipoParametro
const groupBy = <T, K extends keyof T>(array: T[], key: K): Record<string, T[]> => {
    return array.reduce((result, currentValue) => {
        const groupKey = String(currentValue[key]);  // Convertendo a chave para string
        if (!result[groupKey]) {
            result[groupKey] = [];
        }
        result[groupKey].push(currentValue);
        return result;
    }, {} as Record<string, T[]>);
};

export const ParametrosBrf = () => {
    let navigate = useNavigate();
    const [loadingModal, setLoadingModal] = useState<boolean>(false);
    const [messagesLoading, setMessagesLoading] = useState<string>('');
    const [data, setData] = useState<OpcaoConfiguracao[]>([]);
    const [valorReais, setValorReais] = useState(0);
    const [deParaData, setDeParaData] = useState<DeParaData[]>([]);

    useEffect(() => {
        getOptions()
    }, []);

    const getOptions = async (params = "") => {
        try {
            setLoadingModal(true)
            const config = {
                headers: headers(),
            };

            let urlString = 'configuracao/obter';

            const { data } = await apiOperation.get<Response>(urlString, config);

            if (data) {
                setData(data.valor.opcoesParametros)
                setDeParaData(data.valor.deParaConfig)
                setValorReais(data.valor.opcoesParametros[0].valorPeso)
            }
        } catch (error: any) {

        }

        setLoadingModal(false)
    };

    const handleSubmit = async () => {
        try {
            setLoadingModal(true)
            const config = {
                headers: headers(),
            };

            await apiOperation.put('configuracao', 
            {
                OpcoesParametros: data,
                DeParaConfig: deParaData
            }, config)

        } catch (error) {
            console.error('Failed to save data:', error);
            setLoadingModal(false)
        }
        setLoadingModal(false)
    };

    const handleChange = (index: number, value: string) => {
        const newData = [...deParaData];
        newData[index].valor = value;
        setDeParaData(newData);
    };

    const groupedData = groupBy(data, 'tipoParametro');

    const handleEdit = (id: string, field: keyof OpcaoConfiguracao, value: string | number) => {
        const newData = data.map(row =>
            row.opcaoParametroId === id ? { ...row, [field]: value } : row
        );
        setData(newData);
    };

    const handleDelete = (id: string) => {
        const newData = data.filter(row => row.opcaoParametroId !== id);
        setData(newData);
    };

    const handleValorReaisChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const valor = parseFloat(event.target.value);
        if (!isNaN(valor)) {
            setValorReais(valor);
            updateValorPeso(valor);
        }
    };

    const updateValorPeso = (valor: number) => {
        const updatedData = data.map(item => ({
            ...item,
            valorPeso: valor
        }));
        setData(updatedData);
    };

    const handleAdd = (tipoParametro: number) => {
        const newRow: OpcaoConfiguracao = {
            opcaoParametroId: Math.random().toString(36).substr(2, 9),
            descricaoParamentro: '',
            pesoAte: 0,
            pesoAcima: 0,
            fator: 0,
            ativo: true,
            tipoParametro: tipoParametro,
            valorPeso: 0
        };
        setData([...data, newRow]);
    };

    const handleToggleAtivo = (id: string) => {
        const newData = data.map(item => item.opcaoParametroId === id ? { ...item, ativo: !item.ativo } : item);
        setData(newData);
    };

    return (
        <>
            <Header />
            <Loading
				mode='blurry'
				hidden={!loadingModal}
				messages={messagesLoading}
			/>
            <Container>
                <Title>Configuração Política</Title>

                <Card>
                    <CardContent>
                        <CardContent style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button
                                className='verify'
                                onClick={handleSubmit}
                                sx={{
                                    width: '10.4em',
                                }}
                            >
                                Salvar
                            </Button>
                        </CardContent>
                        <Typography variant="h6" gutterBottom>
                            Valor limite
                        </Typography>

                        <TextField
                            type="number"
                            value={valorReais}
                            onChange={handleValorReaisChange}
                            variant="standard"
                            style={{ marginLeft: '10px' }}
                        />
                    </CardContent>

                    <CardContent>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography variant="h6" gutterBottom>
                                    De/Para
                                </Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <TableContainer>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>De</TableCell>
                                                <TableCell>Para</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deParaData.map((row, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>{row.descricao}</TableCell>
                                                    <TableCell>
                                                        <Select
                                                            value={row.valor}
                                                            onChange={(e) => handleChange(index, e.target.value as string)}
                                                            fullWidth
                                                        >
                                                            {paraOptions.map(option => (
                                                                <MenuItem key={option} value={option}>
                                                                    {option}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </AccordionDetails>
                        </Accordion>
                    </CardContent>

                    {Object.keys(groupedData).map((tipoParametro) => (
                        <CardContent key={tipoParametro}>
                            <Accordion className="custom-accordion" style={{ width: "100%" }}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                                    <h3>{getTituloPorTipoParametro(Number(tipoParametro))}</h3>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item xs={12} sm={12}>
                                            <TableContainer>
                                                <Table>
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell style={{ width: '45%' }}>Descrição</TableCell>
                                                            <TableCell style={{ width: '15%' }}>Peso Até (R$ {valorReais})</TableCell>
                                                            <TableCell style={{ width: '15%' }}>Peso Acima (R$ {valorReais})</TableCell>
                                                            <TableCell style={{ width: '15%' }}>Fator</TableCell>
                                                            <TableCell style={{ width: '10%' }}>Ações</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {groupedData[tipoParametro].map(row => (
                                                            <TableRow key={row.opcaoParametroId}>
                                                                <TableCell style={{ width: '60%' }}>
                                                                    <TextField
                                                                        value={row.descricaoParamentro}
                                                                        onChange={(e) => handleEdit(row.opcaoParametroId, 'descricaoParamentro', e.target.value)}
                                                                        fullWidth
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: '15%' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        value={row.pesoAte}
                                                                        onChange={(e) => handleEdit(row.opcaoParametroId, 'pesoAte', parseInt(e.target.value))}
                                                                        fullWidth
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: '15%' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        value={row.pesoAcima}
                                                                        onChange={(e) => handleEdit(row.opcaoParametroId, 'pesoAcima', parseInt(e.target.value))}
                                                                        fullWidth
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: '15%' }}>
                                                                    <TextField
                                                                        type="number"
                                                                        value={row.fator}
                                                                        onChange={(e) => handleEdit(row.opcaoParametroId, 'fator', parseInt(e.target.value))}
                                                                        fullWidth
                                                                        variant="standard"
                                                                    />
                                                                </TableCell>
                                                                <TableCell style={{ width: '10%' }}>
                                                                    <Switch
                                                                        checked={row.ativo}
                                                                        onChange={() => handleToggleAtivo(row.opcaoParametroId)}
                                                                    />
                                                                </TableCell>
                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                            <IconButton
                                                color="primary"
                                                onClick={() => handleAdd(Number(tipoParametro))}
                                                style={{ marginTop: '10px', borderRadius: '50%', border: '1px solid #1976d2', padding: '10px' }}
                                            >
                                                <AddIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </CardContent>
                    ))}


                </Card>
            </Container>

        </>
    );
};
