import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Pagination,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Close, Create, Search } from "@mui/icons-material";

import {
  Container,
  Content,
  Form,
  HeaderModal,
  InputStyled,
  PaginationStyled,
  SubTitle,
  Title,
} from "./style";
import { Header } from "../Header";
import { regex } from "../../utils/regex";
import { Loading } from "../Loading";
import { apiAutenticacao, headers } from "../../services/api";
import { Usuario } from "../../models/usuario";
import { IUser } from "../Login/ILogin";
import { getSessionUser } from "../../utils/users";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const user = { id: null, nome: "", email: "", perfil: "", ativo: true };
let usuarioLogado: IUser | null = null;
export const Users = () => {
  const [form, setForm] = useState({ ...user });
  const [validation, setValidation] = useState({ ...user });
  const [showLoading, setShowLoading] = useState(true);
  const [userSearch, setUserSearch] = useState("");
  const [open, setOpen] = useState(false);
  const [mode, setMode] = useState("");
  let navigate = useNavigate();
  const [historyList, setUserList] = useState<Usuario[]>([]);

  const handleClose = () => {
    setForm({ ...user });
    setValidation({ ...user });
    setOpen(false);
  };

  useEffect(() => {
    usuarioLogado = getSessionUser();

    if (usuarioLogado?.perfil !== "Admin") {
      navigate("/historico");
    } else {
      getUsers();
    }
  }, []);

  const handleOpen = (mode: string, user?: any) => {
    setMode(mode);
    if (user)
      setForm({
        id: user.id,
        nome: user.nome,
        email: user.email,
        perfil: user.perfil,
        ativo: user.status,
      });
    setOpen(true);
  };
  const handleChange = (event: any) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };
  const formValidation = () => {
    let isValid = true;
    let nome = "";
    let email = "";
    let perfil = "";
    if (form.nome.length <= 0) {
      nome = "Nome do Usuário Obrigatório.";
      isValid = false;
    }
    if (form.email.length <= 0) {
      email = "E-mail Obrigatório.";
      isValid = false;
    }
    if (!regex.email.test(form.email.toLocaleLowerCase())) {
      email = "E-mail com formato inválido";
      isValid = false;
    }
    if (form.perfil.length === 0) {
      perfil = "Perfil de Usuário Obrigatório.";
      isValid = false;
    }

    isValid
      ? setValidation({ ...user })
      : setValidation({ ...user, nome, email, perfil });
    return isValid;
  };

  const onSave = () => {
    if (formValidation()) mode === "create" ? createUser() : editUser();
  };

  const getUsers = async () => {
    try {
      const config = {
        headers: headers(),
      };
      const response = await apiAutenticacao.get("usuario/listar", config);

      setUserList([...response.data.valor.dados]);

      setShowLoading(false);
    } catch (error: any) {
      setShowLoading(false);
    }
  };

  const createUser = async () => {
    setShowLoading(true);
    try {
      const config = {
        headers: headers(),
      };

      const response = await apiAutenticacao.post(
        "usuario/inserir",
        {
          nome: form.nome,
          email: form.email,
          status: form.ativo,
          perfil: form.perfil,
        },
        config
      );

      handleClose();
      getUsers();
      setShowLoading(false);
    } catch (error: any) {
      setShowLoading(false);
    }
    return;
  };

  const editUser = async () => {
    setShowLoading(true);
    try {
      const config = {
        headers: headers(),
      };

      const response = await apiAutenticacao.post(
        "usuario/atualizar",
        {
          nome: form.nome,
          email: form.email,
          status: form.ativo,
        },
        config
      );

      handleClose();
      getUsers();
      setShowLoading(false);
    } catch (error: any) {
      setShowLoading(false);
    }

    return;
  };

  const SearchUser = () => {
    console.log(userSearch);
  };

  return (
    <>
      <Header />
      <Container>
        <Content>
          <Title>Usuários</Title>
          <Button
            sx={{
              width: "10.4em",
              marginLeft: "auto",
            }}
            color="secondary"
            variant="contained"
            onClick={() => handleOpen("create")}
          >
            Novo Usuario
          </Button>
        </Content>
        <Card sx={{ minWidth: 275 }}>
          {showLoading ? (
            <Loading hidden={!showLoading} />
          ) : (
            <div>
              {/* <CardContent>
                <SubTitle>Filtrar Dados:</SubTitle>
                <Box style={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    id="nomeUsuario"
                    label="Buscar por Nome de Usuário"
                    variant="outlined"
                    name="nomeusuario"
                    type={"text"}
                    style={{ minWidth: "280px" }}
                    value={userSearch}
                    onChange={(event) => setUserSearch(event.target.value)}
                  />

                  <IconButton
                    color="primary"
                    aria-label="Pesquisar"
                    title="Pesquisar"
                    onClick={SearchUser}
                  >
                    <Search />
                  </IconButton>
                </Box>
              </CardContent> */}
              <CardContent>
                <TableContainer>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">Nome </TableCell>
                        <TableCell align="left">E-mail</TableCell>
                        <TableCell align="left">Perfil</TableCell>
                        <TableCell align="center">Status</TableCell>
                        <TableCell align="center"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {historyList.map((item) => (
                        <TableRow
                          key={item.usuarioId}
                          // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                          hover
                        >
                          <TableCell component="th" scope="row">
                            {item.nome}
                          </TableCell>
                          <TableCell align="left">{item.email}</TableCell>
                          <TableCell align="left">{item.perfil}</TableCell>
                          <TableCell align="center">
                            <Chip
                              color={item.status ? "primary" : `default`}
                              label={item.status ? "Ativo" : "Inativo"}
                            />
                          </TableCell>
                          <TableCell align="center">
                            <IconButton
                              color="primary"
                              aria-label="Visualizar"
                              onClick={() => handleOpen("edit", item)}
                            >
                              <Create titleAccess="Editar" />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                  <Pagination sx={PaginationStyled} count={10} page={0} />
                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    sx={{ minWidth: "60%" }}
                    style={{ minWidth: "60%" }}
                  >
                    <Box sx={style}>
                      <HeaderModal>
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Novo Usuário
                        </Typography>
                        <IconButton onClick={handleClose}>
                          <Close />
                        </IconButton>
                      </HeaderModal>
                      <Form autoComplete="false">
                        <TextField
                          id="nome"
                          label="Nome"
                          variant="outlined"
                          name="nome"
                          type={"text"}
                          style={InputStyled}
                          value={form.nome}
                          required={true}
                          onChange={(event: any) => handleChange(event)}
                          error={validation.nome ? true : false}
                          helperText={validation.nome}
                        />
                        <TextField
                          id="email"
                          label="E-mail"
                          variant="outlined"
                          name="email"
                          type={"text"}
                          required={true}
                          style={InputStyled}
                          value={form.email}
                          onChange={(event: any) => handleChange(event)}
                          error={validation.email ? true : false}
                          helperText={validation.email}
                        />
                        {mode === "create" && (
                          <TextField
                            id="perfil"
                            label="Perfil"
                            variant="outlined"
                            name="perfil"
                            style={InputStyled}
                            select
                            value={form.perfil}
                            required={true}
                            onChange={(event: any) => handleChange(event)}
                            error={validation.perfil ? true : false}
                            helperText={validation.perfil}
                          >
                            <MenuItem key={"operador"} value={"operador"}>
                              {"Operador"}
                            </MenuItem>
                            <MenuItem key={"Admin"} value={"Admin"}>
                              {"Administrador"}
                            </MenuItem>
                          </TextField>
                        )}
                        <FormControlLabel
                          control={
                            <Switch
                              checked={form.ativo}
                              onChange={() =>
                                setForm({ ...form, ativo: !form.ativo })
                              }
                              name="ativo"
                            />
                          }
                          label="Ativo"
                        />
                        <Divider sx={{ margin: "15px 0" }} />
                        <Button
                          sx={{
                            width: "10.4em",
                            marginLeft: "auto",
                          }}
                          color="secondary"
                          variant="contained"
                          onClick={() => onSave()}
                        >
                          Salvar
                        </Button>
                      </Form>
                    </Box>
                  </Modal>
                </TableContainer>
              </CardContent>
            </div>
          )}
        </Card>
        <Loading mode="blurry" hidden={!showLoading}></Loading>
      </Container>
    </>
  );
};
