import { Header } from "../Header";
import { Container } from "./style";

export const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Container
        style={{
          maxWidth: "800px",
          margin: "0 auto",
          padding: "20px",
          textAlign: "justify",
        }}
      >
        <h1 style={{ textAlign: "center" }}>Política de Privacidade</h1>
        <br />
        <br />
        <p>
          A CREDIT ON, que é a legitima proprietária do site, agindo sob o nome
          de domínio{" "}
          <a
            href="https://Crediton.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.Crediton.tech
          </a>
          , respeita e protege a privacidade dos indivíduos que acessam o site
          e/ou utilizam os nossos serviços.
          <br />
          <br />
          Reconhecendo que a sua privacidade é muito importante, tomamos todas
          as medidas possíveis para protegê-la, e por isso, esta declaração de
          política de privacidade explica quais informações coletamos e/ou
          podemos coletar e como as usamos. Recomendamos a leitura integral
          deste documento pois alguns dos termos aqui descritos podem se aplicar
          a você se você estiver usando nossos Serviços.
          <br />
          <br />
          Esta Política se aplica quando você utiliza os nossos serviços que
          envolvem coleta de dados online e offline, incluindo os dados
          pessoais, que são coletados por meio de nossos serviços e canais,
          incluindo websites, aplicativos, redes sociais de terceiros, chatbots,
          suporte ou serviço de atendimento, pontos de venda e eventos.
          <br />
          <br />
          Sempre que você fornecer informações pessoais, trataremos essas
          informações de acordo com esta Política.
          <br />
          <br />
          Em todos os momentos, nos comprometemos em estar em conformidade com
          as melhores práticas de segurança da informação. De acordo com o GDPR,
          as medidas apropriadas são avaliadas em termos de uma variedade de
          fatores, incluindo a sensibilidade dos dados, os riscos para os
          indivíduos associados a qualquer violação de segurança, tecnologias de
          ponta e a natureza do processamento. Essas medidas incluem anonimato
          de dados na investigação e resolução de problemas e criptografia.
          <br />
          <br />
          A aceitação da nossa Política será realizada quando você ler o texto
          abaixo e clicar em “li e aceito”. Isso indicará que você conhece e
          entende os termos e condições da presente política de privacidade,
          consentindo e concordando com às condições e finalidades aqui
          descritas de forma livre e espontânea.
          <br />
          <br />
          Se você optar por não fornecer os dados pessoais necessários para
          utilizar os nossos produtos, ou para a prestação dos nossos serviços,
          ou não concordar com essa Política, não será possível atender você com
          nossos produtos e/ou serviços, solicitando por favor, nos informe a
          razão da sua discordância para que possamos esclarecê-las ou
          melhorá-las.
        </p>
        <br />
        <p>
          <b>Última modificação:</b>
          <br />
          <br />
          10 de abril de 2024.
          <br />
          <br />
          <b>FALE CONOSCO </b>
          <br />
          <br />
          Você pode falar conosco em nosso site&nbsp;
          <a
            href="https://Crediton.tech"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.Crediton.tech
          </a>
          , ou enviando um e-mail para <u>comercial@crediton.tech</u>. O prazo
          de resposta é de até 2 (dois) dias úteis.
        </p>
        <br />
        <p>
          <b>QUE TIPO DE INFORMAÇÃO SÃO COLETADAS EM NOSSO SITE</b>
          <br />
          <br />
          Nós coletamos algumas informações quando o usuário, titular dos dados
          pessoais, acessa nosso site ou utiliza os formulários das seções do
          site. Essas informações são importantes para que seja mantido um canal
          aberto de comunicação, com respostas ágeis e precisas com o usuário.
          <br />
          <br />
          A coleta será de forma direta quando o próprio usuário utilizar os
          meios abaixo:
          <br />
          <br />
          <li>
            Formulários: ao entrar em contato conosco por meio do site, nós
            coletaremos alguns dados pessoais, tais como por exemplo, nome,
            e-mail e telefone para contato. Ao preencher o formulário, nós
            poderemos entrar em contato com o usuário para coletar informações
            adicionais necessárias para o atendimento da solicitação formulada.
          </li>
          <br />
          <br />
          <li>
            Informações de localização e navegação: A depender dos serviços
            utilizados em nosso site e das configurações do seu aparelho
            (smartphone), nós poderemos coletar, de forma precisa ou aproximada,
            sua localização por meio dos dados do GPS, endereço IP ou WiFi.
          </li>
          <br />
          <br />
          Mantemos os seus dados por quanto tempo for necessário para
          fornecimento dos nossos serviços, envio de propostas, ofertas
          ou newsletters e divulgação de nossas parcerias. Caso não tenhamos
          sucesso no encaminhamento de e-mail, os seus dados permanecem
          armazenados em servidores de banco de dados que possuem sistemas de
          proteção (firewall) até o atingimento da finalidade para que foram
          coletados. De forma indireta, a coleta de dados ocorre de forma
          automática, através de cookies de navegação, ferramentas de terceiros
          como Google Analytics e similares, ou ainda plug-ins de terceiros que
          funcionam a partir do navegador do próprio usuário.
          <br />
          <br />
          Os cookies são tratados em tópico próprio em nossa Política e desde já
          informamos que o usuário tem o direito de restringir o acesso a
          cookies ou navegar de forma anônima, configurando seu navegador por
          conta própria. Contudo, não descartamos que esta opção pode acarretar
          alguma inconsistência das funcionalidades do site ou blog, gerando uma
          navegação insatisfatória.
          <br />
          <br />
          Geralmente, os plug-ins estão anexados ao site ou ao blog desenvolvido
          na tecnologia WordPress. Assim, não é possível identificarmos o
          usuário titular dos dados, em razão das próprias características de
          proteção à privacidade da internet. A coleta de dados de forma
          automática se dá através de um código de usuário anônimo, IP de
          acesso, tipo de dispositivo utilizado para navegação, data e hora de
          navegação, país de origem e outras informações gerais, de forma a
          tratar elas em relatórios de análises do site.
          <br />
          <br />
          Além disso, mecanismos de busca (por exemplo, Google) e redes sociais
          (por exemplo, Facebook, Instagram ou similares) podem fornecer
          ferramentas no estilo de plug-ins, que se conectam ao website para
          gerar informações através de cookies e exibir anúncios relacionados. O
          fato do usuário ser impactado por anúncios referentes aos mesmos
          produtos ou serviços, não significa identificação.
        </p>
        <br />
        <p>
          <b>PORQUE ESSAS INFORMAÇÕES SÃO COLETADAS</b>
          <br />
          <br />
          Os dados pessoais, definidos conforme legislação, que são tratados em
          razão do fornecimento direto pelo usuário, titular dos dados, permitem
          uma comunicação ágil e eficaz conosco, buscando sempre o melhor
          atendimento no menor tempo possível e aperfeiçoando a experiência e
          usufruto dos diversos serviços ofertados pela nossa empresa.
        </p>
        <br />
        <p>
          <b>COMO AS INFORMAÇÕES SÃO USADAS </b>
          <br />
          <br />
          Nós utilizamos as informações coletadas para propósitos específicos
          que incluem o fornecimento de serviços, agilidade na comunicação,
          divulgação de eventos e parcerias realizadas pela nossa empresa, mas
          também podem ser utilizadas, de tempos em tempos, para envio
          de newsletter e e-mail marketing aos usuários cadastrados, em razão do
          fornecimento consentido dos dados.
          <br />
          <br />
          Os dados armazenados podem vir a ser utilizados para fins de
          estatísticas (analytics). Estes dados são agrupados, com o intuito de
          proporcionar uma análise macro do cenário, e, portanto, não buscam
          identificar ou tornar identificável os titulares dos dados pessoais,
          mas tão somente compreender melhor como é o acesso deles
          nas plataformas da CREDIT ON, a fim de melhorar a prestação de
          serviços e customizar produtos mais direcionados aos interesses dos
          usuários.
          <br />
          <br />
          Nós não tratamos dados que sejam relacionados a crimes, organizações
          criminosas ou terroristas. Dados pessoais enviados por menores de 18
          anos também não são tratados sem o consentimento do responsável. Esses
          dados que porventura cheguem a nossa base serão imediatamente
          eliminados.
          <br />
          <br />
          Para resguardar e proteger direitos da CREDIT ON: Reservamo-nos o
          direito de acessar, ler, preservar e divulgar quaisquer dados que
          acreditamos serem necessários para cumprir uma obrigação legal ou um
          acordo judicial; fazer cumprir ou aplicar nossos Termos de Uso e
          outros acordos; ou proteger os direitos, propriedade ou segurança da
          CREDIT ON, nossos funcionários, nossos usuários ou outros.
        </p>
        <br />
        <p>
          <b>COMPARTILHAMENTO DE INFORMAÇÕES</b>
          <br />
          <br />
          Exceto com relação aos cookies, cujas informações particulares você
          encontra no tópico próprio, não compartilhamos seus dados com
          terceiros, exceto para nossos parceiros de armazenamento das
          informações (proprietários dos servidores) ou em caso de
          obrigação/determinação judicial. Qualquer alteração desse padrão será
          sempre transparente e para fins específicos e previamente repassados
          para você.
          <br />
          <br />
          Entretanto, para o envio de newsletters ou contato comercial,
          atividades anteriormente mencionadas, eventualmente inserimos dados
          coletados em ferramentas de disparos que possamos adotar para a mesma
          finalidade.
          <br />
          <br />
          Além disso, a hospedagem do site e ferramentas ocorre em data
          centers fora do Brasil, com fornecedores mundiais deste tipo de
          serviço.
          <br />
          <br />
          Lembre-se de que quando você usa um link para ir do nosso site para
          outro site ou solicitar um serviço de terceiros, nossa Política não se
          aplica mais. Sua navegação e interação em qualquer outro website ou
          suas negociações com qualquer outro provedor de serviços de terceiros
          estão sujeitas às regras e políticas do próprio provedor de serviços
          de terceiros ou do próprio site. Nós não monitoramos, controlamos ou
          endossamos a coleta de informações ou práticas de privacidade de
          terceiros. Incentivamos você a se familiarizar com as práticas de
          privacidade de todos os sites que você visita ou com o provedor de
          serviços terceirizado com os quais você lida e para contatá-los caso
          tenha alguma dúvida sobre suas respectivas políticas e práticas de
          privacidade. Esta Política aplica-se unicamente aos coletados por nós
          através do nosso site ou serviços e não se aplica a esses sites de
          terceiros e provedores de serviços terceirizados.
        </p>
        <br />
        <p>
          <b>DIREITOS DOS TITULARES</b>
          <br />
          <br />
          Buscamos estar com conformidade com as leis com padrões mais elevados
          de proteção dados do Brasil e no mundo, assim você pode sempre
          solicitar a confirmação de tratamento dos seus dados, a correção ou
          remoção/eliminação de seus dados e informações pessoais, relatar
          eventual uso indevido ou exercer qualquer direito previsto na Lei n.
          13.709/2018 (Lei Geral de Proteção de Dados) entrando em contato por
          meio do e-mail comercial@CREDIT ON.tech.
          <br />
          <br />
          O prazo de resposta é de até 2 (dois) dias úteis.
          <br />
          <br />
          Em caso de obrigações legais, regulatórias, proteção de eventuais
          ações judiciais futuras ou para manutenção e prosseguimento dos
          serviços ofertados, os seus dados pessoais não serão eliminados, e o
          tratamento será mantido de acordo com essas bases legais expostas e
          para os fins informados nesta Política de Privacidade e demais
          documentos complementares.
          <br />
          <br />O usuário concorda que a coleta, armazenamento, uso e
          compartilhamento de dados pessoais foi realizada em perfeita
          consonância com as bases legais previstas nos artigos 7º e 11, da Lei
          n. 13.709/2018, tendo dado consentimento informado, livre, inequívoco
          e específico dos dados que podem ser objeto de tratamento, e que fora
          observado os princípios da finalidade, adequação, necessidade, livre
          acesso, qualidade, transparência, segurança, prevenção e
          não-discriminação (art. 6º, Lei n. 13.709/2018).
        </p>
        <br />
        <p>
          <b>SEGURANÇA DA INFORMAÇÃO</b>
          <br />
          <br />
          Estamos sempre preocupados com a segurança de nossos clientes e dos
          usuários de nosso site. Nossas informações são armazenadas em
          servidores de empresas igualmente preocupadas com segurança, com
          redundância e armazenamento em nuvem, e dispõem de alta tecnologia e
          proteção da informação.
          <br />
          <br />
          Apenas pessoas autorizadas possuem acesso aos dados pessoais tratados
          por nós, garantindo sempre a privacidade dos dados dos titulares.
          <br />
          <br />
          Empregamos medidas técnicas e organizativas aptas a proteger os dados
          pessoais de acessos não autorizados e de situações de destruição,
          perda ou alteração desses dados. As medidas que utilizamos levam em
          consideração a natureza dos dados, o contexto e finalidade do
          tratamento, os riscos que uma eventual violação geraria para os
          direitos e liberdades do usuário, e os padrões atualmente empregados
          no mercado por empresas semelhantes à nossa.
          <br />
          <br />
          Toda tecnologia disponível nunca será suficiente para prevenir 100% de
          ataques ou acessos não autorizados. Na pequena hipótese de ocorrência
          de um vazamento ou violação à privacidade dos dados dos titulares, nós
          comunicaremos em tempo razoável quais dados foram afetados e todas as
          medidas por nós tomadas para reparar possíveis danos.
          <br />
          <br />
          A entrada ou uso não autorizado de conta, falha de hardware ou
          software e outros fatores podem comprometer a segurança dos seus dados
          pessoais a qualquer momento, por isso, nos ajude a manter um ambiente
          seguro para todos.
          <br />
          <br />
          Ao utilizar os nossos serviços, você deve impedir e evitar o acesso
          não autorizado de terceiros à sua conta e aos dados pessoais
          selecionando e protegendo sua senha e/ou outro mecanismo de conexão de
          forma adequada e limitando o acesso ao seu computador ou dispositivo e
          navegador, fazendo logout após ter concluído o acesso à sua conta.
          <br />
          <br />
          Por fim, caso ocorra algum incidente motivado exclusivamente por
          terceiro, como em casos de ataques hackers e/ou em casos de culpa
          exclusiva do usuário, nos eximimos de responsabilidade, conforme o
          inciso III, do art. 43 da Lei n. 13709//2018.
        </p>
        <br />
        <p>
          <b>O QUE SÃO COOKIES E PORQUE SÃO UTILIZADOS</b>
          <br />
          <br />
          Em síntese, um cookie é um dado que um website pode enviar ao seu
          navegador e que pode ser armazenado em seu computador como uma tag que
          identifica seu computador, com a finalidade de rastrear movimentos
          dentro do site, como visitas as páginas e anúncios. Os Cookies não
          armazenam informações pessoais sem que você as tenha fornecido e não
          coletam informações registradas em seu computador.
          <br />
          <br />
          Ao visitar nosso website ou serviços on-line, é possível ter a
          possibilidade de definir suas preferências em relação aos cookies e
          outras tecnologias semelhantes usando as opções e ferramentas
          disponibilizadas pelo seu navegador da web.
          <br />
          <br />
          Nesse sentido, você poderá optar pelo não fornecimento de cookies a
          qualquer momento desabilitando a respectiva função de seu navegador,
          ciente de que ao fazê-lo, os serviços fornecidos ao usuário poderão
          não apresentar todas as suas funcionalidades.
          <br />
          <br />
          O Nosso site utiliza cookies(*) desde os PHP até os fornecidos
          pelo Google Analytics para acompanhar as interações com o visitante,
          medindo o uso do website (tal como o número de visitantes e a duração
          da visita) e a eficácia (como os tópicos em que os visitantes estão
          mais interessados) e para facilitar a navegação ou o uso e, como tal,
          não estão associados a nenhuma informação pessoal, também são usados,
          às vezes, para personalizar a experiência de um visitante conhecido de
          um website, sendo associados a informações de perfil ou preferências
          do usuário. Com o passar do tempo essas informações fornecem insights
          valiosos para ajudar a melhorar a experiência do usuário.
          <br />
          <br />
          Geralmente, os cookies são categorizados como cookies de “sessão” ou
          cookies “persistentes”. Os cookies de sessão ajudam você a navegar
          pelo website de forma eficiente, acompanhando sua progressão de página
          para página, de modo que não sejam solicitadas informações já
          fornecidas durante a visita atual ou informações necessárias para
          concluir uma transação. 
          <br />
          <br />
          Nós utilizamos por padrão cookies PHP, criados como identificador
          único de sessão do usuário, usados para identificar se um usuário
          retorna ao nosso site e/ou por qual meio ele foi direcionado até o
          nosso site.
          <br />
          <br />
          Não descartamos ainda a criação de cookies de forma indireta em razão
          de serviços de terceiros disponibilizados através do nosso site, para
          coleta de forma automática de informações gerais de navegação, de
          forma a melhorar e agregar mais valor a experiência do usuário em
          nosso site.
          <br />
          <br />
          Os navegadores não compartilham seus cookies entre domínios.
        </p>
        <br />
        <p>
          <b>GOOGLE ANALYTICS E TECNOLOGIAS SIMILARES</b>
          <br />
          <br />
          Utilizamos também a ferramenta Google Analytics para avaliar a
          utilização e eficácia nosso site, personalizar a sua experiência e
          melhorar constantemente a oferta de conteúdo para nossos usuários e
          clientes.
        </p>
        <br />
        <p>
          <b>ATUALIZAÇÃO DA POLÍTICA DE PRIVACIDADE</b>
          <br />
          <br />
          Nós poderemos, a qualquer momento e de forma periódica caso
          necessário, alterar esta política de privacidade para atender a
          alterações legislativas, exigências de órgãos regulatórios ou
          necessidades operacionais. Caso a alteração seja significativa, as
          duas versões (antiga e a atualizada) estarão disponíveis no site para
          comparação.
        </p>
      </Container>
      <Container style={{
          maxWidth: "800px",
          margin: "0 auto",
          padding: "20px",
          textAlign: "justify",
        }}>
        <br />
        <h1 style={{ textAlign: "center" }}>Lei Geral de Proteção de Dados (LGPD)</h1>
        <br />

        <p>Se você possuir perguntas ou desejar entrar em contato com a CreditOn sobre os detalhes desta Política, por favor, comunique-se com o encarregado
          de dados através do contato abaixo, O prazo de resposta é de até 2 (dois) dias úteis.
        </p>
        <br />
        <p>DPO: Lorrann Rafael Corrêa <br /> E-mail: <u>dpo@crediton.tech</u></p>
        <br />
      </Container>
      <br />
    </>
  );
};
