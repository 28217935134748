import { TextField } from '@mui/material'
import { useState } from 'react'
import { Container, Content } from './style'

export const Personaldata = () => {

    const [name, setName] = useState<string>('');
    const [cpf, setCpf] = useState<string>('');
    const [phone, setPhone] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    

    const handleChangeName = ( {target}:any ) => {
        setName(target.value);
    }

    const handleChangeCPF = ( {target}:any ) => {
        setCpf(target.value);
    }

    const handleChangePhone = ( {target}:any ) => {
        setPhone(target.value);
    }

    const handleChangeEmail = ( {target}:any ) => {
        setEmail(target.value);
    }

    return (
        <>
            <Container>
                <Content>
                    <div className = "title">
                        <h6>
                            Agora, precisamos dos seus <span  className="colorSpanPack">  dados pessoais </span> para contato e acesso.
                        </h6>
                    </div>
                    <div className = "registration">
                        <div>
                            <TextField 
                                fullWidth 
                                label="Nome completo"
                                value={name}
                                onChange={handleChangeName}
                                ></TextField>
                        </div>
                        <div>
                            <TextField 
                                fullWidth 
                                label="CPF"
                                value={cpf}
                                onChange={handleChangeCPF}
                                ></TextField>
                        </div>
                        <div>
                            <TextField 
                                fullWidth 
                                label="Telefone"
                                value={phone}
                                onChange={handleChangePhone}
                                ></TextField>
                        </div>
                        <div>
                            <TextField 
                                fullWidth 
                                label="E-mail"
                                value={email}
                                onChange={handleChangeEmail}
                                ></TextField>
                        </div>
                    </div>
                </Content>   
            </Container>
        </>
    ) 
}