import styled from "styled-components"

export const Container = styled.div`

`;
export const Content = styled.div`

    h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 144px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--black);

        .colorSpanPack {
            color: var(--dark-blue);
            margin-left: 3px;
            margin-right: 3px;
        }

    }

    .subTitle {
        margin-top: 70px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--black);
    }

    .containerSelect {
        display: flex;
        justify-content: center;

        label {
            margin-right: 15px;
        }

        .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root  {
            max-height: 40px;
            bottom:7px ;
        }
    }
    .containerCotation{
        display: flex;
       justify-content: space-around;
    }
    .cardCotation {
        margin-top: 15px;
        background: var(--blue-light);
        border: 1px solid #001064;
        border-radius: 20px;
        max-width: 176px;
        min-width: 150px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.5px;
            color: var(--black);
            

            .valueMoney {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 18px;
                text-align: center;
                letter-spacing: 0.5px;
                text-transform: uppercase;
                color: var(--text-title);
            }
        }
    }

    .othersCotation {
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.06);
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        div {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.5px;
            color: var(--black);

           
        }
        .pushCotation {
            margin-top: 15px;
            span {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 18px;
                line-height: 21px;
                text-align: center;
                letter-spacing: 0.5px;
                text-decoration-line: underline;
                text-transform: uppercase;
                color: var(--black);
            }

        }
    }

    .lineBlue {
        margin-top: 33px;
        margin-bottom: 33px;
        div {
            border: 1px solid var(--blue-light);
        }
    }

    .footer {
        
        display: flex;
        align-items: center;
        justify-content: flex-end;


        .labelMoney {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: var(--black);
            margin-right: 16px;
        }

        .price {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 18px;
            text-align: center;
            letter-spacing: 0.5px;
            text-transform: uppercase;
            color: var(--text-title);
        }
    }
`;