import { DashedLine } from "../DashedLine";
import { Container } from "./style";

interface IProps {
    card: {
        number: number;
        title: string;
        description: string;
        lineLeft?:boolean;
        lineRight?: boolean
    }
}

export const CardMedium = (props: IProps) => (

        <Container>
            
            <div>
                <div className="containerNumber">
                    <h3>{props.card.number}</h3>
                    <DashedLine className={(props.card.lineRight ? ' lineRight ':'hide') + ' cardWidth'}/>
                    <DashedLine className={(props.card.lineLeft ? ' lineLeft ':'hide')  + ' cardWidth' }/>
                </div>
                <div className="cardTitle">
                    <h6>{props.card.title}</h6>
                </div>
                <div className="cardBody">
                   <span>{props.card.description}</span> 
                </div>
            </div>
            
        </Container>
)