import styled from 'styled-components';


export const Container = styled.div`
    background: var(--background-white);
    width: 368px;
    height: 291px;
    backdrop-filter: blur(4px);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    margin-left: 31px;
    .lineRight {
        bottom: 33px;
        left: 120px;
    }

    .lineLeft {
        bottom: 36px;
        right: 120px;
    }

    .cardWidth {
        width: 154px;
    }
    div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 700;
            font-size: 36px;
            line-height: 56px;
            text-align: center;
            color: var(--dark-moderate-blue);
            padding-top: 10px;
        }

        h6 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.15px;
            color: var(--dark-moderate-blue);
        }

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            color: var(--text-body);
        }

        .cardTitle {
            margin-top: 21px;
            margin-bottom: 22px;
        }

        .containerNumber {
            margin-top: 27px;
            width: 56px;
            height: 56px;
            border: 2px dashed var(--blue-light);
            border-radius: 30px;
        }
        .cardBody {
            width: 308px;
        }
    }
`;