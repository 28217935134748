import styled from 'styled-components';

export const Container = styled.div`
background: var(--bakground);
`

export const Content = styled.div`
    box-sizing: border-box;
    color: var(--grey);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    h2 {
        margin-top: 33px;
        font-size: 1.813rem;
        font-weight: 700;
        
    }

.blocking {
        background-color: var(--background-white);
        box-sizing: border-box;
        
        width: 1074px;
        height: 299px;
        margin-left: 140px;
        padding: 24px;
        margin-top: 20px;
        border: var(--light-gray) solid 1px;
        border-radius: 3px;

        h3 {
            font-size: 1.563rem;
            margin-bottom: 10px;
            margin-top: 10px;
        }

        label {
            font-size: .894rem;
            font-weight: 400;
        }

        input {
            font-weight: 400;
            font-size: .894rem;
            height: 2px;
            margin-bottom: 10px;
        }

        div {
            margin-bottom: 30px;
            height: 40px;
        }

        button {
            margin-top: 40px;
            font-size: .894rem;
            padding: 0.438rem 1.125rem;
            line-height: 1.54;
            border-radius: 60rem;
            
        }
        .save {
                background-color: var(--moderate-cyan);
                border: solid 1px transparent;
                color: var(--shape);
            }
        .back {
                border: var(--light-gray) solid 1px;
                background-color: var(--background-white);
                margin-left: 30px;
            }
    }
    
`