export enum TipoVariavelFinanceira {
	Indefinida = 0,
	Ativo = 1,
	AtivoCirculante = 2,
	AtivoCirculanteDisponivel = 3,
	AtivoCirculanteAplicacaoFinanceira = 4,
	AtivoNaoCirculante = 5,
	Passivo = 6,
	PassivoCirculante = 7,
	PassivoCirculanteInstituicaoFinanceira = 8,
	PassivoNaoCirculante = 9,
	PassivoNaoCirculanteInstituicaoFinanceira = 10,
	PassivoPatrimonioLiquido = 11,
	Resultado = 12,
	ResultadoReceitaOperacionalLiquida = 13,
	ResultadoOperacionalAntesDoResultadoFinanceiro = 14,
	ResultadoFinanceiro = 15,
	ResultadoFinanceiroReceitaFinanceira = 16,
	ResultadoFinanceiroDespesaFinanceira = 17,
	ResultadoAntesDoImpostoDeRendaEContribuicaoSocial = 18,
	ResultadoTributos = 19,
	ResultadoIrpjECsll = 20,
	ResultadoDespesaTributaria = 21,
	Receita = 22,
	Custo = 23,
	CapitalSocial = 24,
	ResultadoEbit = 25,
	ResultadoLucroAntesIrpj = 26,
	LiquidezCorrente = 27,
	EndividamentoGeral = 28,
	DividaBancaria = 29,
	CoberturaDivida = 30,
	CaixaBancos = 31,
	Roic = 32,
}
