import { createGlobalStyle } from 'styled-components';
import * as color from './colors';

export const GlobalStyle = createGlobalStyle`

    :root {
        --bakground: ${color.bakground};
        --background-white: ${color.backgroundWhite};
        --purple-light: ${color.purpleLight};
        --blue-light: ${color.blueLight};
        --very-dark-blue: ${color.veryDarkBlue};
        --dark-moderate-blue2: ${color.darkModerateBlue2};
        --dark-moderate-blue: ${color.darkModerateBlue};
        --moderate-cyan: ${color.moderateCyan};
        --text-disable: ${color.textDisable};
        --dark-blue: ${color.darkBlue};
        --green: ${color.green};
        --blue: ${color.blue};
        --very-dark-grayish-cyan: ${color.veryDarkGrayishCyan};
        --black: ${color.black};
        --very-dark-gray: ${color.veryDarkGray};
        --pure-violet: ${color.pureViolet};
        --light-gray: ${color.lightGray};
        --background-gray: ${color.backGroundGray};
        --grey: ${color.grey};
        --text-title: ${color.textTitle};
        --text-subtitle: ${color.textSubtitle};
        --text-body: ${color.textBody};
        --text-link: ${color.textLink};
        --shape: ${color.shape};
        --black-off: ${color.blackOff};
    }

    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box ;
    }

    html {
        @media (max-width: 1080px) {
            font-size: 93.75% ;
        }

        @media (max-width: 720px) {
            font-size: 87.5%;
        }
    }

    body {
       background: var(--bakground);
        -webkit-font-smoothing: antialiased;
    }

    body, input, textarea, button {
        font-family: 'Roboto', sans-serif ;
        font-weight: 400;
    }

    h1, h2, h3, h4, h5, h6, strong {
        font-family: 'Roboto', sans-serif ;
        font-weight: 500;
    }

    button {
        cursor: pointer;
    }

    [disabled] {
        opacity: 0.6;
        cursor: not-allowed ;
    }

`;
