export enum TipoIndiceFinanceiro {
	Indefinido = 0,
	EndividamentoGeral = 1,
	DividaBancariaEbitda = 2,
	CoberturaDaDivida = 3,
	CaixaBancosCP = 4,
	ROIC = 5,
	LiquidezCorrente = 6,
	Restritivos = 7,
	TempoFundacao = 8,
	Porte = 9,
	ClassificacaoInterna = 10,
	ListaSuja = 11,
}
