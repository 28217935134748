import { TextField } from "@mui/material"
import { useState } from "react";
import { Header } from "../Header";
import { Container, Content } from "./Style"


export const NewCNPJBlocking = () => {

    const [nomeDaEmpresa, setNomeDaEmpresa] = useState<string>('');
    const [cnpj, setCnpj] = useState<string>('');

    const handleChangeNomeDaEmpresa = ( {target}:any ) => {
        setNomeDaEmpresa (target.value);
    };
    
    const handleChangeCnpj = ( {target}:any ) => {
        setCnpj (target.value);
    };
    
    return (
        <>
            <Header/>
            <Container>
                <Content>
                        <div>
                            <h2>Novo Bloqueio de CNPJ</h2>
                        </div>
                        <div  className = "blocking">
                            <div>
                                <TextField 
                                fullWidth 
                                label="Nome da Empresa"
                                value={nomeDaEmpresa}
                                onChange={handleChangeNomeDaEmpresa}></TextField>
                            </div>
                            <div>
                                <TextField 
                                fullWidth 
                                label="CNPJ"
                                value={cnpj}
                                onChange={handleChangeCnpj}></TextField>
                            </div>
                            <div>
                                <button type = "button" className="save">Salvar</button>
                                <button type = "button" className="back">voltar</button>
                            </div>
                        </div>

                </Content>
            </Container>
        </>
    )
}