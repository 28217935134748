import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
	palette: {
		primary: {
			main: '#283593',
			light: '#001064',
			dark: '#5F5FC4',
		},
		secondary: {
			main: '#84FFFF',
			light: '#BAFFFF',
			dark: '4BCBCC',
		},
		text: {
			primary: '#212121',
			secondary: '#797979',
			disabled: '#BDBDBD',
		},
	},
	components: {
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: '10px',
				},
			},
			variants: [
				{
					props: { color: 'secondary' },
					style: {
						border: '1px solid #84ffff',
						color: '#001064',
						' > .MuiTouchRipple-root span': {
							color: '#4BCBCC',
						},
					},
				},
			],
		},
	},
});
