import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
	position: relative;

	.buttonStyle {
		background: var(--blue-light);
		width: 13em;
		height: 3em;
		cursor: pointer;
	
	}

	.buttonStyle:active {
    	background: var(--very-dark-blue); 
	}	

	.buttonStyle:hover {
    	background: var(--very-dark-blue);
		color: white; 
	}

	.buttonFinalizar {
		background: var(--blue-light);
		width: 13em;
		height: 3em;
		margin-top: 18px;
		margin-left: 91%;
		margin-bottom: 5%;
		cursor: pointer;
	}

	.buttonFinalizar:active {
    	background: var(--very-dark-blue); 
	}

	.buttonFinalizar:hover {
    	background: var(--very-dark-blue);
		color: white; 
	}

	.icone {
		color:var(--text-subtitle);
		font-size: 20px;
		font-family: 'Roboto';
		margin-top: -40px;
		left: 160px;
		position: absolute;
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
`;

export const Title = styled.h4`
	font-weight: 500;
	font-size: 31px;
	line-height: 36px;
	color: var(--text-subtitle);
	margin: 20px 10px;
`;

export const SubTitle = styled.span`
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.18px;
	color: var(--black);
	display: inline-block;
	margin-bottom: 30px;
`;

export const Form = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
`;

export const FormField = styled.div`
	width: 100%;
	display: flex;

 	margin: 10px;
 `;

export const HeaderModal = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ContentModal = styled.div`
	padding: 15px;
	width: 55vw;
	margin-left: 25%;
    margin-top: 5%;
	background: white;
	border: 2px solid #000;
	box-shadow: 24px;
	border-radius: 10px;
`;

export const Text = styled.span`
	max-width: 140px;
	min-width: 140px;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	margin: 0 20px;
`;

export const Input = styled.input`
	display: none;
`;

export const InputStyled: CSSProperties = {
	minWidth: '80%',
	marginBottom: '16px',
	width:'80%',
};

export const InputGrid: CSSProperties = {
	padding: '5px 5px',
	minWidth: '10%',
};

export const PaginationStyled: CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
};

export const LoadFile = styled.span`
	display: flex;
`;

export const Load = styled.div`
	background: var(--moderate-cyan);
	border-radius: 5px;
	max-width: 20%;
	margin-top: 15px;
	margin-bottom: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	div {
		margin-left: 15px;
		img{
			width: 30px;
			height: 30px;
		}
	}
	span {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 1.25px;
		text-transform: capitalize;
		color: var(--very-dark-blue);
	}
`;

export const AddNewFile = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 33px;
	margin-left: 15%;
	cursor: pointer;
`;