import styled from "styled-components";

export const Container = styled.div`
`;

export const Content = styled.div`

    .title {
        margin-top: 33px;
        margin-bottom: 33px;
    }
    h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--black);

        .colorSpanPack {
            color: var(--dark-blue);
            margin-left: 3px;
            margin-right: 3px;
        }}

        .CNPJlink {
            margin-top: 33px;
            margin-bottom: 33px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: var(--dark-blue);
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-decoration-line: underline;
        }

        .registration {
            margin-top: 10px;
            width: 375.5px;
            color: var(--black-off);

            margin-left: 27%;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            letter-spacing: 0.15px;

            input {
                height: 5px;
            }

        .Double {
            margin-top: 10px;
            margin-bottom: 10px;
            color: var(--black-off);
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;

            a {
                color: var(--text-title);
                margin-left: 10px;
                width: 81px;
                display: block;
                letter-spacing: 0.4px;
                text-decoration-line: underline;
 
            }
            }
        }

        .DoubleInput {
            margin-top: 10px;
            margin-left: 27%;
            color: var(--black-off);
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            letter-spacing: 0.15px;

            .num {
                width: 120.26px;
            }

            .complement {
                width: 231.58px;
                margin-left: 25px;
            }

            .state {
                width: 120.26px;
                margin-left: 25px;
            }

            .city {
                width: 231.58px;
            }

            input {
                height: 5px;
            }

            label {
                display: block;
            }
        }


`