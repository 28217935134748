import styled from "styled-components";

export const Container = styled.div `
    

    .containerButton {
        display: flex;
        justify-content: space-around ;
        margin-top: 33px;
    }
 
    .buttonAdvance {
        height: 53px;
        width: 196px;
        background: #84FFFF;
        border-radius: 10px;
        border: 0px;
        

        color: var(--very-dark-blue);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        text-transform: uppercase;
    }
`;