import React, { useState, useEffect } from "react";
import { TextField, Link, IconButton, InputAdornment } from "@mui/material";
import {
  Container,
  CardContent,
  CardHeader,
  CardBody,
  Title,
  Logo,
} from "./style";
import logo from "../../assets/logo.png";
import visibility from "../../assets/visibility.svg";
import visibilityOff from "../../assets/visibility_off.svg";
import { useParams, useNavigate } from "react-router-dom";
import { Loading } from "../Loading";
import { Button } from "../Button";
import { apiAutenticacao } from "../../services/api";

const ResetPassword = () => {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();
  const { code } = useParams();
  const [isValidCode, setIsValidCode] = useState(true);

  useEffect(() => {
    const validateCode = async () => {
      try {
        const response = await apiAutenticacao.post(
          `usuario/senha/codigo/${code}`
        );
        const data = await response.data;

        if (data.erro) {
          setIsValidCode(false);
          setShowPopup(true);
          setTimeout(() => {
            navigate("/login");
          }, 3000);
        }
      } catch (error) {
        console.error("Erro ao validar o código:", error);
      }
    };

    validateCode();
  }, [code, navigate]);

  const handleReset = async () => {
    if (password !== confirmPassword) {
      setErrorMessage("As senhas não coincidem.");
      return;
    }

    setShowLoading(true);
    try {
      const { data, status } = await apiAutenticacao.post(
        "usuario/senha/alterar",
        {
          Senha: password,
          ConfirmacaoSenha: confirmPassword,
          Solicitacao: code,
        }
      );

      if (data.erro) {
        setShowLoading(false);
        setErrorMessage(
          data.erros[0] || "Ocorreu um erro. Por favor, tente novamente."
        );
        return;
      }

      setShowLoading(false);
      setErrorMessage("");
      navigate("/login"); // Redireciona para a página de login após a redefinição bem-sucedida
    } catch (error) {
      setErrorMessage("Ocorreu um erro. Por favor, tente novamente.");
      setShowLoading(false);
    }
  };

  return (
    <Container>
      <CardContent>
        <CardHeader>
          <Logo src={logo} alt="Logo" />
        </CardHeader>
        <CardBody>
          <Title>Redefinir Senha</Title>

          <TextField
            id="passwordField"
            label="Nova Senha"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            style={{ width: "100%", margin: "20px 0", maxWidth: "360px" }}
            value={password}
            onChange={(event) => setPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <img src={visibilityOff} alt="" />
                    ) : (
                      <img src={visibility} alt="" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            id="confirmPasswordField"
            label="Confirme a Senha"
            variant="outlined"
            type={showPassword ? "text" : "password"}
            style={{ width: "100%", margin: "20px 0", maxWidth: "360px" }}
            value={confirmPassword}
            onChange={(event) => setConfirmPassword(event.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(event) => event.preventDefault()}
                    edge="end"
                  >
                    {showPassword ? (
                      <img src={visibilityOff} alt="" />
                    ) : (
                      <img src={visibility} alt="" />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          {showPopup && (
            <div className="popup">
              Não existe solicitação de alteração de senha ativa.
            </div>
          )}

          {isValidCode && (
            <Button className={"entrar"} onClick={handleReset}>
              Redefinir Senha
            </Button>
          )}
        </CardBody>
      </CardContent>
      <Loading mode="blurry" hidden={!showLoading}></Loading>
    </Container>
  );
};

export default ResetPassword;
