import styled from 'styled-components';

export const Container = styled.div`
    
    .containerText {
        h1 {
            font-family: 'Yantramanav';
            font-style: normal;
            font-weight: 1000;
            font-size: 80px;
            line-height: 68px;
            color: var(--purple-light);
        }

        h3 {
            color: var(--very-dark-blue);
            font-family: 'Yantramanav';
            font-style: normal;
            font-weight: 500;
            font-size: 40px;
            line-height: 68px;
        }

        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 31px;
            line-height: 36px;
        }

        .containerH4 {
            margin-top: 49px;
            max-width: 572px;
        }

        .container-btn {
            margin-top: 65px;
        }
    }

    @media (max-width: 1024px) { /* Para tablets */
        .containerText h1 {
            font-size: 48px; /* Menor para tablets */
            line-height: 54px;
        }

        .containerText h3 {
            font-size: 32px; /* Menor para tablets */
            line-height: 48px;
        }

        .containerText h4 {
            font-size: 24px; /* Menor para tablets */
            line-height: 28px;
        }

        .containerText .containerH4 {
            margin-top: 40px;
        }

        .containerText .container-btn {
            margin-top: 50px;
        }
    }

    @media (max-width: 768px) { /* Para smartphones */
        .containerText h1 {
            font-size: 36px; /* Menor para smartphones */
            line-height: 42px;
        }

        .containerText h3 {
            font-size: 24px; /* Menor para smartphones */
            line-height: 36px;
        }

        .containerText h4 {
            font-size: 20px; /* Menor para smartphones */
            line-height: 24px;
        }

        .containerText .containerH4 {
            margin-top: 30px;
        }

        .containerText .container-btn {
            margin-top: 40px;
        }
    }

    .btn-know-more {
        background: var(--blue-light);
        justify-content: center;
        width: 196px;
        height: 62px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        display: flex;
        align-items: center;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        border: none;
    }

	.verify {
		background: var(--blue-light) !important;
		width: 15em;
		height: 4em;
		border: none;
		margin-left: auto;
	}

    .verify:hover {
		background: #00a2e8;
		box-shadow: 0px 15px 20px rgba(0, 0, 0, 0.2);
	}
    
`;

export const Content =  styled.div`

`;

export const ContentFirstContainer = styled.div`
    background: linear-gradient(180.19deg,
        rgba(132, 255, 255, 0.8) 
        -12.3%, rgba(91, 137, 180, 0.2)
        68.87%, rgba(91, 137, 180, 0)
        110.06%);

    display: flex;
    flex: 1;
    align-items: center;
    padding-top: 45px;
    justify-content: center;
`;
export const ContentSecondContainter = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 60px;
    .containerImages {
        width: 328px;
        div {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 31px;
            line-height: 36px;
            color: var(--text-subtitle);
        }

        .containerText {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.5px;
            color: var(--text-body);
        }

        .lineBlue {
            margin-top: 7px;
            margin-bottom: 34px;
            width: 80px;
            height: 4px;
            left: 257px;
            top: 939px;
            background: var(--blue-light);
            transform: matrix(1, 0, 0, -1, 0, 0);
        }

        .containerImage img, .containerImages img {
            width: 100%;
            height: auto;
        }
        
    }

    @media (max-width: 768px) {
        .containerImages {
            width: 100%; /* Faz o container se ajustar à largura da tela */
            padding: 0 20px; /* Adiciona um pouco de padding nas laterais para não colar nos cantos */
    
            h4 {
                font-size: 24px; /* Diminui o tamanho da fonte para se ajustar melhor a telas menores */
                line-height: 28px; /* Ajusta o espaçamento da linha */
            }
    
            .containerText {
                font-size: 14px; /* Diminui o tamanho da fonte */
                line-height: 20px; /* Ajusta o espaçamento da linha */
            }
    
            /* Ajuste outros estilos conforme necessário */
        }

        .ContentFirstContainer, .containerImage, .containerImages {
            /* Adaptações para responsividade */
            flex-direction: column; /* Se estiver usando flexbox e quiser empilhar verticalmente em telas menores */
        }
    }

`;

export const ContentThirdContainer = styled.div`
    min-height: 1150px;
    padding-top: 60px;
    display: flex;
    flex: 1;
    flex-direction: column;
    background: linear-gradient(180.78deg, rgba(132, 255, 255, 0.8) -25.93%, rgba(91, 137, 180, 0.2) 67.26%, rgba(91, 137, 180, 0) 92.62%);

    h2 {
        color: var(--very-dark-blue);
        font-family: 'Yantramanav';
        font-style: normal;
        font-weight: 300;
        font-size: 60px;
        line-height: 72px;
        letter-spacing: -0.5px;
    }

    .subTitleContainer {
        margin-top: 67px;
        margin-bottom: 18px;
        div {
            flex-direction: column;
            margin-top: 5px;
        }
    }
    h4 {
        color: var(--text-subtitle);
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 31px;
        line-height: 36px;
    }

    h6 {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
    }
    .containerCenter {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .lineBlue {
        background: var(--blue-light);
        transform: matrix(1, 0, 0, -1, 0, 0);
        width: 240px;
        height: 4px;
    }
    .containerCards {
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .SecondTitle {
        
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (max-width: 768px) {
        .lineBlue {
            width: 120px; /* Ajusta a largura da linha azul para dispositivos menores */
        }
        .containerCards {
            flex-direction: column; /* Empilha os cards verticalmente em telas menores */
            margin-top: 20px; /* Reduz o espaçamento superior */
        }
        /* Adicionalmente, você pode ajustar fontes, margens, etc., para os outros elementos conforme necessário */
    }

    @media (max-width: 1024px) {
        .containerCenter h2 {
            font-size: 34px; /* Menor para tablets e abaixo */
            line-height: 42px;
            font-weight: bold;
        }
    
        .subTitleContainer h4 {
            font-size: 28px; /* Menor para tablets e abaixo */
            line-height: 36px;
        }
    
        h6 {
            font-size: 24px; /* Menor para tablets e abaixo */
            line-height: 36px;
        }
    }
    
    /* Ajustes para Smartphones */
    @media (max-width: 768px) {
        .containerCenter h2 {
            font-size: 34px; /* Menor para smartphones */
            line-height: 42px;
            font-weight: bold;
        }
    
        .subTitleContainer h4 {
            font-size: 28px; /* Menor para smartphones */
            line-height: 36px;
        }
    
        h6 {
            font-size: 24px; /* Menor para smartphones */
            line-height: 36px;
        }
    }
`;

export const ContentFourthContainer = styled.div`
       display: none;
        margin-bottom: 33px;
        .containerCenter {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;

            h3 {
                font-family: 'Yantramanav';
                font-style: normal;
                font-weight: 300;
                font-size: 60px;
                line-height: 72px;
                letter-spacing: -0.5px;
                color: var(--very-dark-blue);
            }
        }

        @media (max-width: 1024px) {
            .containerCenter h2 {
                font-size: 34px; /* Menor para tablets e abaixo */
                line-height: 42px;
                font-weight: bold;
            }
        
            .subTitleContainer h4 {
                font-size: 28px; /* Menor para tablets e abaixo */
                line-height: 36px;
            }
        
            h6 {
                font-size: 24px; /* Menor para tablets e abaixo */
                line-height: 36px;
            }
        }
        
        /* Ajustes para Smartphones */
        @media (max-width: 768px) {
            .containerCenter h2 {
                font-size: 34px; /* Menor para smartphones */
                line-height: 42px;
                font-weight: bold;
            }
        
            .subTitleContainer h4 {
                font-size: 28px; /* Menor para smartphones */
                line-height: 36px;
            }
        
            h6 {
                font-size: 24px; /* Menor para smartphones */
                line-height: 36px;
            }
        }
`;

export const ContentFifthContainer = styled.div `
    margin-top: 45px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: linear-gradient(180.78deg,
            rgba(132, 255, 255, 0.8) -25.93%,
            rgba(91, 137, 180, 0.2) 67.26%,
            rgba(91, 137, 180, 0) 92.62%);
    transform: rotate(-180deg);
    
    .fifthBody {
        transform: rotate(-180deg);
        display: flex;
        flex-direction: column;
        align-items: center;

        div {
            h2 {
                font-family: 'Yantramanav';
                font-style: normal;
                font-weight: 300;
                font-size: 60px;
                line-height: 72px;
                letter-spacing: -0.5px;
                color: var(--very-dark-blue);
            }

            h5 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 30px;
                text-align: center;
                letter-spacing: 0.18px;
                color: var(--dark-moderate-blue2);
            }

            img {
                position: relative;
                top: 91px;
            }
        }
    }

    @media (max-width: 1024px) {
        .fifthBody div h2 {
            font-size: 48px; /* Menor para tablets */
            line-height: 56px;
        }
    
        .fifthBody div h5 {
            font-size: 20px; /* Ajuste proporcional para tablets */
            line-height: 26px;
        }
    }
    
    /* Ajustes para Smartphones */
    @media (max-width: 768px) {
        .fifthBody div h2 {
            font-size: 34px; /* Menor para smartphones */
            line-height: 42px;
        }
    
        .fifthBody div h5 {
            font-size: 18px; /* Ajuste proporcional para smartphones */
            line-height: 24px;
        }
    }

    .lineBlueLarge {
        background: var(--blue-light);
        transform: matrix(1, 0, 0, -1, 0, 0);
        width: 445px;
        height: 2px;
    }
`;

export const ContentSixthConteiner = styled.div`
    background: var(--background-white);
    margin-top: 120px;
    display: flex;
    flex-direction:  column;
    align-items: center;
    margin-bottom: 66px;

    div {
        h2 {
            font-family: 'Yantramanav';
            font-style: normal;
            font-weight: 300;
            font-size: 60px;
            line-height: 72px;
            letter-spacing: -0.5px;
            color: var(--dark-blue);
        }


        h3 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 34px;
            line-height: 56px;
            color: var(--dark-blue);
        }
        .MuiButtonBase-root {
            background: #F1F4F4;
            padding-top: 20px;
            padding-bottom: 20px;
            border-radius: 20px;
        }
        .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
            border-radius: 20px;
        }
        
    }
    .questions {
        margin-top: 23px;
        margin-bottom: 91px;
    }

    .faqTitle h2 {
        font-family: 'Yantramanav', sans-serif;
        font-style: normal;
        font-weight: 300;
        font-size: 60px; /* Tamanho original para desktop */
        line-height: 72px;
        color: var(--very-dark-blue);
    }

    @media (max-width: 1024px) {
        .faqTitle h2 {
            font-size: 48px; /* Menor para tablets */
            line-height: 56px;
        }
    }
    
    /* Ajustes para Smartphones */
    @media (max-width: 768px) {
        .faqTitle h2 {
            font-size: 34px; /* Menor para smartphones */
            line-height: 42px;
        }
    }

    .btn-contact {
        background: #84FFFF;
        border-radius: 10px;
        min-width: 305px;
        min-height: 62px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 1.25px;
        text-transform: uppercase;
        color: var(--text-link);
    }

    .footerTitle {
        margin-bottom: 36px;
    }

    .footer {
        margin-top: 100px;
        display: flex;

        .corp {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            letter-spacing: 1.5px;
            text-transform: uppercase;
            color: var(--purple-light);
            margin-right: 390px;
            display: flex;
            align-items: center;
        }

        .email {
            margin-right: 30px;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
        }

        .phone {
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
        }
    }

    @media (max-width: 768px) {
        .footer {
            flex-direction: column;
            align-items: center;
            margin-top: 50px;
        }
    
        .corp, .email, .phone {
            margin-right: 0; /* Remove o espaçamento à direita para alinhamento central */
            justify-content: center; /* Centraliza o conteúdo para dispositivos menores */
        }
    
        .corp {
            margin-bottom: 20px; /* Adiciona um espaçamento entre os itens quando em coluna */
        }
    
        .email, .phone {
            margin-bottom: 10px; /* Espaçamento entre email e telefone */
        }
    }
`;