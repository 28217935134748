import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	width: 100%;
	z-index: 100;
	display: ${(props) => (props.hidden ? 'hidden' : 'flex')};
	align-items: center;
	justify-content: center;
	backdrop-filter: blur(3px);
`;

export const Content = styled.div`
	height: 50%;
	width: 50%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 10px;
`;

export const Title = styled.span`
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.18px;
`;

export const Text = styled.span`
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	text-align: center;
	letter-spacing: 0.15px;
	color: #979797;
`;

export const BlurrySyled: CSSProperties = {
	height: '100vh',
	position: 'fixed',
};

export const DefaultStyled: CSSProperties = {
	height: '100%',
};

export const Img = styled.img``;
