import { Form, InputStyled } from './style';
import { Box, Button, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IProps, filters } from './models';
import { useEffect, useState } from 'react';
import moment from 'moment';

export const FilterForm = (props: IProps) => {
	const [form, setForm] = useState({
		...filters,
	});
	const [error, setError] = useState({ ...filters });

	useEffect(() => {
		setForm({ ...props.dataFilter });
	}, [props.dataFilter]);

	const handleChange = (event: any) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	};

	const handleChangeDateInit = (date: any) => {
		if (!isNaN(date))
			setForm({
				...form,
				dataInicio: date,
			});
	};

	const handleChangeDateEnd = (date: any) => {
		if (!isNaN(date))
			setForm({
				...form,
				dataFim: date,
			});
	};

	const saveFilter = () => {
		if (validDates()) {
			const dataInicio =
				form.dataInicio !== ''
					? moment(form.dataInicio).format('DD/MM/YYYY')
					: '';
			const dataFim =
				form.dataFim !== '' ? moment(form.dataFim).format('DD/MM/YYYY') : '';

			props.onSave({
				...form,
				dataInicio,
				dataFim,
			});
		}
	};

	const clearForm = () => {
		setForm({ ...filters });
		setError({ ...filters });
		props.onSave({ ...filters });
	};

	const validDates = () => {
		if (!form.dataInicio && form.dataFim) {
			setError({ ...filters, dataInicio: 'Data Inicial Obrigatorio.' });
			return false;
		}
		if (form.dataInicio && !form.dataFim) {
			setError({ ...filters, dataFim: 'Data Final Obrigatorio.' });
			return false;
		}
		if (moment(form.dataFim).isBefore(form.dataInicio)) {
			setError({
				...filters,
				dataFim: 'Preencha com uma data Inferior a Inicial.',
			});
			return false;
		}
		setError({ ...filters });
		return true;
	};

	const formatDateToISO = (date: any) => {
		if (!date) return null;

		if (moment(date).isValid()) return date;

		const formated = date.split('/').reverse().join('-');
		return moment(formated).toDate();
	};
	return (
		<Box>
			<Form>
				<Box width={'50%'}>
					<TextField
						id='nomeCliente'
						label='Nome Do Cliente'
						variant='outlined'
						name='nomeCliente'
						type={'text'}
						style={InputStyled}
						value={form.nomeCliente}
						onChange={handleChange}
						helperText=' '
					/>
					<TextField
						id='cnpj'
						label='CNPJ'
						variant='outlined'
						name='cnpj'
						type={'text'}
						style={InputStyled}
						value={form.cnpj}
						onChange={handleChange}
						helperText=' '
					/>
					<TextField
						id='solicitante'
						label='Solicitante'
						variant='outlined'
						name='solicitante'
						type={'text'}
						style={InputStyled}
						value={form.solicitante}
						onChange={handleChange}
						helperText=' '
					/>
				</Box>
				<Box width={'50%'}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<DatePicker
							label='Data Inicial'
							inputFormat='dd/MM/yyyy'
							value={formatDateToISO(form.dataInicio)}
							onChange={handleChangeDateInit}
							minDate={new Date('2000-01-01')}
							maxDate={new Date('2999-12-31')}
							renderInput={(params) => (
								<TextField
									{...params}
									InputLabelProps={{ shrink: true }}
									style={InputStyled}
									error={error.dataInicio ? true : false}
									helperText={error.dataInicio || ' '}
								/>
							)}
						/>
						<DatePicker
							label='Data Final'
							inputFormat='dd/MM/yyyy'
							value={formatDateToISO(form.dataFim)}
							onChange={handleChangeDateEnd}
							minDate={new Date('2000-01-01')}
							maxDate={new Date('2999-31-12')}
							renderInput={(params) => (
								<TextField
									{...params}
									InputLabelProps={{ shrink: true }}
									style={InputStyled}
									error={error.dataFim ? true : false}
									helperText={error.dataFim || ' '}
								/>
							)}
						/>
					</LocalizationProvider>
				</Box>
			</Form>
			<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button
					sx={{
						width: '10.4em',
						marginLeft: 'auto',
					}}
					color='secondary'
					variant='contained'
					onClick={saveFilter}
				>
					Salvar Filtro
				</Button>
				<Button
					sx={{
						width: '10.4em',
						marginLeft: '32px',
					}}
					color='secondary'
					variant='contained'
					onClick={clearForm}
				>
					Limpar
				</Button>
			</Box>
		</Box>
	);
};
