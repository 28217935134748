import styled from "styled-components";

export const Container = styled.div`

    button {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        border-radius: 10px;
        color: var(--text-link);
    }
`;