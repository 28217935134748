import { Button, Step, StepLabel, Stepper, Typography } from '@mui/material';
import { useState } from 'react';
import { BusinessData } from '../Forms/BusinessData';
import { FormOfPayment } from '../Forms/FormOfPayment';
import { PackageChoice } from '../Forms/PackageChoice/PackageChoice';
import { Personaldata } from '../Forms/Personaldata';
import { Container } from './style';

function getSteps() {
	return [
		'Escolha do Pacote',
		'Dados Pessoais',
		'Dados Empresariais',
		'Dados de Pagamento',
	];
}

function getStepContent(stepIndex: number) {
	switch (stepIndex) {
		case 0:
			return <PackageChoice />;
		case 1:
			return <Personaldata />;
		case 2:
			return <BusinessData />;
		case 3:
			return <FormOfPayment />;
		default:
			return 'Unknown stepIndex';
	}
}

export const HeaderSteps = () => {
	const [activeStep, setActiveStep] = useState(0);
	const steps = getSteps();

	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<Container>
			<div>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div>
					{activeStep === steps.length ? (
						<div>
							<Typography>Finalizado com Sucesso</Typography>
							<Typography>
								Confira seu e-mail para verificar o status de processamento do
								seu pagamento.
							</Typography>
							<Typography>
								Os dados de acesso para a plataforma também serão enviados para
								seu e-mail.
							</Typography>
							<Button onClick={handleReset}>Reset</Button>
						</div>
					) : (
						<div>
							<Typography>{getStepContent(activeStep)}</Typography>
						</div>
					)}
				</div>
			</div>
			<div className='containerButton'>
				<div>
					<button
						className='buttonAdvance'
						disabled={activeStep === 0}
						onClick={handleBack}
					>
						Voltar
					</button>
				</div>
				<div>
					<button className='buttonAdvance' onClick={handleNext}>
						{activeStep === steps.length - 1 ? 'Finalizar' : 'Avançar'}
					</button>
				</div>
			</div>
		</Container>
	);
};
