import { useState } from "react";
import { Header } from "../Header";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Loading } from '../Loading';
import {
  Container,
  Content,
  ContentFifthContainer,
  ContentFirstContainer,
  ContentFourthContainer,
  ContentSecondContainter,
  ContentSixthConteiner,
  ContentThirdContainer,
} from "./style";
import creditonIlustraHome from "../../assets/creditonIlustraHome.svg";
import automateAnalysis from "../../assets/automateAnalysis.png";
import reduceCosts from "../../assets/reduceCosts.png";
import buyTime from "../../assets/buyTime.png";
import arrow_down from "../../assets/arrow_down.svg";
import searchResult from "../../assets/searchResult.svg";
import mail from "../../assets/mail.svg";
import phone_android from "../../assets/phone_android.svg";
import add from "../../assets/add.svg";
import { CardMedium } from "../CardMedium";
import { CardMin } from "../CardMin";
import { Tabs } from "../TabComponents/Tabs";
import { faqs } from "../../data/faqs";
import WhatsApp from "../../assets/whatsapp.png";
import { Helmet } from 'react-helmet';
import { apiAutenticacao } from '../../services/api';
import { useNavigate } from 'react-router-dom';

export const Home = () => {
  let navigate = useNavigate();
  const [expanded, setExpanded] = useState<string | false>(false);

  const [open, setOpen] = useState(false);
  const [lead, setLead] = useState({ nome: '', email: '', observacao: '' });
  const [hover, setHover] = useState(false);
  const [showLoading, setShowLoading] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const modalHandleChange = (event: any) => {
    const { name, value } = event.target;
    setLead({ ...lead, [name]: value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    
    setShowLoading(true);

    const { data, status } = await apiAutenticacao.post('identidade/lead', {
			nome: lead.nome,
			email: lead.email,
      texto: lead.observacao 
		});
		
    lead.nome = ""
    lead.email = ""
    lead.observacao = ""

    setShowLoading(false);
    handleClose();
    navigate(`/home`);
  };

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
  };

  const styleTextAccordion = {
    width: "100%",
    flexShrink: 0,
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "24px",
    letterSpacing: "0.18px",
    background: "#F1F4F4",
  };
  const card01 = {
    title: "Básico",
    description:
      "Tenha acesso a um Relatório de Crédito confiável com o melhor preço do mercado",
    items: [
      "Dados cadastrais",
      "Porte da Empresa",
      "Protestos",
      "Informações dos Sócios",
      "Pendências Trabalhistas",
      "Informações de Idoneidade",
      "Ações Judiciais - Informações Básicas",
    ],
    subItems: ["Score Credit-On", "Dados Exportáveis"],
    price: "1.000",
    optional: [],
    basicInfo: false,
    advanceInfo: false,
  };
  const card02 = {
    title: "Avançado",
    description:
      "Obtenha uma Análise de Crédito detalhada, com informações adicionais",
    items: [
      "Score de Mercado",
      "Pontualidade de Pagamentos",
      "Referências de Fornecedores",
      "Consultas por Crédito",
      "Cheques sem fundo",
      "Ações Judiciais - Informações Completas",
    ],
    subItems: ["Probabilidade de Inadimplência", "Limite de Crédito Sugerido"],
    price: "2.000",
    optional: ["Faturamento Estimado", "Alerta de Fraudes "],
    basicInfo: true,
    advanceInfo: false,
  };
  const card03 = {
    title: "Completo",
    description: "Exclusiva análise automática das Demonstrações Financeiras",
    items: [
      "Análise das Demonstrações Financeiras (exclusividade Credit-On)",
      "Rating Financeiro",
    ],
    subItems: [
      "Dados Financeiros dos Documentos Planilhados (Balancetes, Balanço, DRE)",
    ],
    price: "3.000",
    optional: [],
    basicInfo: false,
    advanceInfo: true,
  };
  const cards = [card01, card02, card03];

  const estiloLink = {
    height: "55px",
    display: "inline-block",
  };

  return (
    <>
      <Helmet>
        <title>CreditOn</title>
        <meta name="description" content="CreditOn - Análise de Crédito Rápida e Inteligente, com leitura automatizada de balanços" />
      </Helmet>
      <Header />
      <Container>
        <ContentFirstContainer>
          <Content className="containerText">
            <div>
              <div>
                <h1>Motor de Crédito Inteligente</h1>
              </div>
              <div>
                <h3>Análise de Crédito mais precisa do mercado </h3>
              </div>
              <div className="containerH4">
                <h4>
                  A única com leitura e análise automatizada de documentos
                </h4>
              </div>
            </div>
            <div>
              <br />
              <Button 
                className="verify"
                onClick={handleOpen}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}>
                Fale com nossos especialistas
              </Button>
            </div>
          </Content>
          <Content className="containerImages">
            <img style={{width: '100%', height: 'auto'}} src={creditonIlustraHome} alt="Imagem notebook com uma lupa" />
          </Content>
        </ContentFirstContainer>
        <ContentSecondContainter>
          <div className="containerImages">
            <div>
              <div>
                <img style={{width: '100%', height: 'auto'}}
                  src={automateAnalysis}
                  alt="Logo de um homem e uma mulher analisando dados"
                />
              </div>
              <div>
                <h4>Automatize sua análise</h4>
              </div>
              <div>
                <div className="lineBlue"></div>
              </div>
              <div>
                <div className="containerText">
                  Análise automatizada, independente e sem viezes, graças a
                  algoritmos e integrações baseadas em inteligência artificial.
                </div>
              </div>
            </div>
          </div>
          <div className="containerImages">
            <div>
              <div>
                <img style={{width: '100%', height: 'auto'}}
                  src={reduceCosts}
                  alt="Desenho de uma mulher colocando moeda em um porquinho"
                />
              </div>
              <div>
                <h4>Reduza seus custos</h4>
              </div>
              <div>
                <div className="lineBlue"></div>
              </div>
              <div>
                <div className="containerText">
                  Custos com infra-estrutura e equipe reduzidos, além da
                  simplificação de processos e redução de erros.
                </div>
              </div>
            </div>
          </div>
          <div className="containerImages">
            <div>
              <div>
                <img style={{width: '100%', height: 'auto'}} src={buyTime} alt="Homem ,calendario e um relogio" />
              </div>
              <div>
                <h4>Ganhe tempo!</h4>
              </div>
              <div>
                <div className="lineBlue"></div>
              </div>
              <div>
                <div className="containerText">
                  Tenha acesso a informações e a análises consistentes em uma
                  fração do tempo tradicional!{" "}
                </div>
              </div>
            </div>
          </div>
        </ContentSecondContainter>
        <ContentThirdContainer>
          <div>
            <div className="containerCenter">
              <h2>Como funciona a Análise de Crédito Padrão</h2>
            </div>
          </div>
          <div className="subTitleContainer">
            <div className="containerCenter">
              <h4>Planos Básico e Avançado</h4>
              <div className="lineBlue"></div>
            </div>
          </div>
          <div>
            <div>
              <h6>
                Tenha acesso a um relatório de crédito confiável e ao Score
                Credit-On.
              </h6>
            </div>
          </div>
          <div className="containerCards">
            <CardMedium
              card={{
                number: 1,
                lineRight: true,
                title: "Coleta de Dados",
                description:
                  "A partir do CNPJ da empresa, são coletados diversos dados cadastrais, restrições e idoneidade da empresa.",
              }}
            ></CardMedium>
            <CardMedium
              card={{
                number: 2,
                lineLeft: true,
                lineRight: true,
                title: "Análise de crédito automatizada",
                description:
                  "A partir do CNPJ da empresa, são coletados diversos dados cadastrais, restrições e idoneidade da empresa.",
              }}
            ></CardMedium>
            <CardMedium
              card={{
                number: 3,
                lineLeft: true,
                title: "Relatório",
                description:
                  "É criado um relatório com sugestão de limite de crédito para o CNPJ pesquisado, risco de inadimplência, restrições, entre outras informações financeiras.",
              }}
            ></CardMedium>
          </div>
          <div className="subTitleContainer">
            <div className="containerCenter">
              <h4>Plano Completo</h4>
              <div className="lineBlue"></div>
            </div>
          </div>
          <div className="SecondTitle">
            <div style={{ maxWidth: "995px" }}>
              <h6>
                Além das informações da análise Avançada, avalie informações
                econômico-financeiras do CNPJ por meio de análise automatizada
                de documentos, como DRE e balanço.
              </h6>
            </div>
          </div>
          <div className="containerCards">
            <CardMin
              card={{
                number: 1,
                lineRight: true,
                title: "Coleta de Dados",
                description:
                  "A partir do CNPJ da empresa, são coletados diversos dados cadastrais, restrições e idoneidade da empresa. ",
              }}
            />
            <CardMin
              card={{
                number: 2,
                lineRight: true,
                lineLeft: true,
                title: "Upload de documentos",
                description:
                  "Documentos como contrato social e demonstrativos financeiros são carregados em nossa plataforma.",
              }}
            />
            <CardMin
              card={{
                number: 3,
                lineRight: true,
                lineLeft: true,
                title: "Checagem de documentos",
                description:
                  "Documentos de Pessoa Jurídica são conferidos e encaminhados para análise.",
              }}
            />
            <CardMin
              card={{
                number: 4,
                lineRight: true,
                lineLeft: true,
                title: "Análise de crédito automatizada",
                description:
                  "Documentos e dados são decodificados e analisados de forma automatizada.",
              }}
            />
            <CardMin
              card={{
                number: 5,
                lineLeft: true,
                title: "Relatório",
                description:
                  "Além de todas informações visualizadas na análise completa, serão analisados os dados dos documentos fornecidos.",
              }}
            />
          </div>
        </ContentThirdContainer>
        <ContentFourthContainer>
          <div className="containerCenter">
            <div>
              <img src={arrow_down} alt="Seta para baixo" />
            </div>
            <div>
              <h3>Planos</h3>
            </div>
          </div>
          <div className="containerCenter">
            <Tabs cards={cards} />
          </div>
          <div></div>
        </ContentFourthContainer>
        <ContentFifthContainer>
          <div className="fifthBody">
            <div>
              <h2>Relatório Credit-On</h2>
            </div>
            <div>
              <h5>Conheça o relatório da Análise de Crédito Padrão </h5>
            </div>
            <div>
              <div className="lineBlueLarge"></div>
            </div>
            <div>
              <img style={{width: '100%', height: 'auto'}}
                src={searchResult}
                alt="Imagem da tela de resultado de pesquisa de CNPJ"
              />
            </div>
          </div>
        </ContentFifthContainer>
        <ContentSixthConteiner>
          <div className="faqTitle">
            <h2>Perguntas Frequentes</h2>
          </div>
          <div className="questions">
            {faqs.map((faq, index) => (
              <Accordion
                key={index}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                >
                  <Typography sx={styleTextAccordion}>
                    {faq.question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{faq.answer}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </div>
          <div className="footerTitle">
            <h3>
              Quer conhecer a análise de crédito PJ mais eficiente do mercado?
            </h3>
          </div>

          <div>
            <br />
            <Button 
              className="verify"
              onClick={handleOpen}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}>
              Fale com nossos especialistas
            </Button>
          </div>

          <div className="footer">
            <div className="corp">
              <span>@2021 Credit-On </span>
            </div>
            <div className="corp">
              <a href="https://crediton.tech/policy"><span>Visite nossa política de privacidade</span></a>
            </div>
            <div className="email">
              <img src={mail} alt="Icone email" />
              <a href="mailto:comercial@crediton.tech">
                comercial@crediton.tech
              </a>
            </div>
            <div>
              <a
                href="https://api.whatsapp.com/send?phone=41991781900&text=Olá Josefina, gostaria de falar sobre a CreditOn!"
                style={estiloLink}
                target="_blank"
              >
                <img style={estiloLink} src={WhatsApp} alt="Abrir WhatsApp" />
              </a>
            </div>
            <div className="phone">
              <span>+55 41 99178-1900</span>
            </div>
          </div>
        </ContentSixthConteiner>
      </Container>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="lead-modal-title"
        aria-describedby="lead-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            maxWidth: '700px',
            bgcolor: 'background.paper',
            borderRadius: '15px',
            p: 4,
            outline: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h2 id="lead-modal-title">Preencha suas informações</h2>
          <form
            id="lead-modal-description"
            onSubmit={handleSubmit}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextField
              label="Nome"
              variant="outlined"
              name="nome"
              value={lead.nome}
              onChange={modalHandleChange}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              value={lead.email}
              onChange={modalHandleChange}
              margin="normal"
              fullWidth
            />
            <TextField
              label="Observação"
              variant="outlined"
              name="observacao"
              value={lead.observacao}
              onChange={modalHandleChange}
              margin="normal"
              fullWidth
              multiline
              rows={4}
            />
            <Button type="submit" variant="contained" color="primary">
              Enviar
            </Button>
          </form>
        </Box>
      </Modal>
      <Loading mode='blurry'  hidden={!showLoading} ></Loading>
    </>
  );
};
