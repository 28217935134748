import { GeoCode, Location } from '../models/geocode';
import { apiMaps } from './api';

const urlApi = 'https://maps.googleapis.com/maps/api/';
const apiKey = 'AIzaSyCsh_tPuSk5IcqQBOScMqWmsPTEkYZ5HmE';

export const getUrlMap = async (endereco: string) => {
	let location: Location = { lat: 0, lng: 0 };

	const { data } = await apiMaps.get<GeoCode>(
		`/geocode/json?key=${apiKey}&address=${endereco}`,
	);
	var res = data.results.find((x) => x);
	location = res?.geometry?.location || location;

	return `${urlApi}staticmap?center=${location?.lat},${location?.lng}&zoom=15&size=1920x1080&markers=size:mid|Color:red|${endereco}&key=${apiKey}`;
};
