export interface IProps {
	onSave: (filter: object) => void;
	dataFilter: IFilter;
}

export interface IFilter {
	nomeCliente: string;
	cnpj: string;
	dataInicio: string;
	dataFim: string;
	solicitante: string;
}

export const filters: IFilter = {
	nomeCliente: '',
	cnpj: '',
	solicitante: '',
	dataInicio: '',
	dataFim: '',
};
