import styled from "styled-components";

export const Container = styled.div`
`;

export const Content = styled.div`

h6 {

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--black);

        }

        .payment {
                display: flex;

                p {
                        margin-top: 27px;
                }

        .circleBlue {
                margin-right: 10px;
                margin-top: 30px;
                box-sizing: border-box;

                width: 16px;
                height: 16px;

                background: var(--blue-light);
                border: 2px solid #001064;
                border-radius: 10px;
        }


        .Circle {
                margin-right: 10px;
                margin-left: 100px;
                margin-top: 30px;
                box-sizing: border-box;

                width: 16px;
                height: 16px;

                border: 2px solid var(--text-link);
                border-radius: 10px;
        }

        }

        .footerBaloon {
            border: 2px solid var(--blue-light);
            filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.06));
            border-radius: 20px;
            max-width: 592px;
            display: flex;
            align-items: center;
            justify-content:center ;
            padding: 20px;

            .firstContainer {
                margin-right: 28px;
                width: 250px;
                h6 {
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 24px;
                    letter-spacing: 0.15px;
                    color: var(--text-link);
                }
            }
        }
        
        .secondContainer {
            div {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 24px;
                letter-spacing: 0.5px;
            }

            h6 {
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                letter-spacing: 0.15px;
                color: var(--text-link);
            }
        }

        .paymentData {
                
                width: 326px;
                font-family: 'Roboto';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                div {
                    margin-bottom: 10px;
                }
                input {
                        height: 5px;
                }
        }

        .DoubleInput {
            margin-top: 10px;
            color: var(--black-off);
            display: flex;
            align-items: center;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;

            letter-spacing: 0.15px;

            .campDate {
                width: 149.09px;
            }

            .campCVC {
                width: 149.09px;
                margin-left: 28.91px;
            }

        
            input {
                height: 5px;
            }

            label {
                display: block;
            }
        }

        .lineBlue {
        margin-top: 33px;
        margin-bottom: 33px;
        div {
            border: 1px solid var(--blue-light);
        }
    }

    .request {
        color: var(--very-dark-blue);

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;

        letter-spacing: 0.15px;
    }
         

`