import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BusinessBlockList } from './components/BusinessBlockList';
import { ChooseYourPlan } from './components/ChooseYourPlan';
import { Home } from './components/Home';
import { Login } from './components/Login';
import { NewCNPJBlocking } from './components/NewCNPJBlocking';
import { Profile } from './components/Profile';
import { SettingsProfile } from './components/SettingsProfile';
import { NewSearch } from './components/newSearch';
import { Results } from './components/Results';
import { SearchHistory } from './components/SearchHistory';
import { Users } from './components/Users';
import { PrivateRouter } from './components/PrivateRoute';
import { AdminRouter } from './components/AdminRouter';
import { SearchManager } from './components/Admin/SearchManager';
import ResetPassword from './components/Login/resetPassword';
import { ParametrosBrf } from './components/BRF';
import { AnaliseBRT } from './components/AnaliseBRT';
import { PrivacyPolicy } from './components/Policies/privacidade'


export const RouteManagement = () => (
	<BrowserRouter>
		<Routes>
			<Route path='/' element={<Home />} />
			<Route element={<PrivateRouter />}>
				<Route path='/gerenciarpesquisa' element={<AdminRouter />}>
					<Route path='/gerenciarpesquisa' element={<SearchManager />}></Route>
				</Route>
				<Route path='/novapesquisa' element={<NewSearch />}></Route>
				<Route path='Perfil' element={<Profile />} />
				<Route path='configuracao' element={<SettingsProfile />} />
				<Route path='/analisebrt' element={<AnaliseBRT />}></Route>
				<Route path='novo-bloqueio-de-cnpj' element={<NewCNPJBlocking />} />
				<Route path='empresas-bloqueadas' element={<BusinessBlockList />} />
				<Route path='/historico' element={<SearchHistory />}></Route>
				<Route path='/usuarios' element={<Users />}></Route>
				<Route path='/resultados' element={<Results />} />
				<Route path='/resultados/:id' element={<Results />} />
				<Route path='/configuracao/Brf' element={<ParametrosBrf />}></Route>
			</Route>
			<Route path='/home' element={<Home />} />
			<Route path='login' element={<Login />} />
			<Route path='escolha-seu-plano' element={<ChooseYourPlan />} />
			<Route path='/*' element={<Home />} />
			<Route path="/reset-password/:code" element={<ResetPassword />} />
			<Route path='/policy' element={<PrivacyPolicy/>}/>
		</Routes>
	</BrowserRouter>
);
