import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
	.verify {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
		border: none;
		margin-left: auto;
	}

	.hide {
		display: none;
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;
`;

export const Title = styled.h4`
	font-weight: 500;
	font-size: 31px;
	line-height: 36px;
	color: var(--text-subtitle);
	margin: 40px 10px;
`;

export const SubTitle = styled.span`
	font-weight: 700;
	font-size: 24px;
	line-height: 24px;
	letter-spacing: 0.18px;
	color: var(--black);
	display: inline-block;
	margin-bottom: 30px;
`;

export const Form = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
`;
export const FormField = styled.div`
	width: 100%;
	display: flex;

	margin: 10px;
`;

export const Text = styled.span`
	max-width: 140px;
	min-width: 140px;
	font-size: 16px;
	line-height: 24px;
	text-align: right;
	margin: 0 20px;
`;

export const Input = styled.input`
	display: none;
`;

export const InputStyled: CSSProperties = {
	maxWidth: '360px',
	minWidth: '140px',
	marginRight: '30px',
};

export const InputFormStyled: CSSProperties = {
	maxWidth: '360px',
	minWidth: '140px',
};

export const Load = styled.div`
	background: var(--moderate-cyan);
	border-radius: 5px;
	max-width: 20%;
	margin-top: 15px;
	margin-bottom: 15px;
	padding-top: 10px;
	padding-bottom: 10px;
	display: flex;
	div {
		margin-left: 15px;
		img{
			width: 30px;
			height: 30px;
		}
	}
	span {
		font-family: 'Roboto';
		font-style: normal;
		font-weight: 500;
		font-size: 14px;
		line-height: 16px;
		display: flex;
		align-items: center;
		text-align: center;
		letter-spacing: 1.25px;
		text-transform: capitalize;
		color: var(--very-dark-blue);
	}
`;

export const LoadFile = styled.span`
	display: flex;
	
`

export const AddNewFile = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: 33px;
	margin-left: 15%;
	cursor: pointer;
`

export const ContainerForm = styled.div`

	display: flex;
	align-items: center;
	margin-bottom: 33px;
`;

export const ContainerTextArea = styled.div`
	display: flex;
	align-items: center;
	flex-direction:column ;
	margin-bottom: 33px;
`;