import { useState } from 'react';
import { TextField, Link, IconButton, InputAdornment } from '@mui/material';
import {
	CardBody,
	CardContent,
	CardHeader,
	Container,
	Logo,
	Title,
} from './style';
import { Button } from '../Button';

import logo from '../../assets/logo.png';
import visibility from '../../assets/visibility.svg';
import visibilityOff from '../../assets/visibility_off.svg';
import { Loading } from '../Loading';

import { useNavigate } from 'react-router-dom';
import {
	hasAdminUser,
	saveSessionToken,
	saveSessionUser,
} from '../../utils/users';
import { regex } from '../../utils/regex';
import { apiAutenticacao } from '../../services/api';

const urlencodeFormData = (fd: any) => {
	let s = '';
	function encode(s: any) {
		return encodeURIComponent(s).replace(/%20/g, '+');
	}
	for (let pair of fd.entries()) {
		if (typeof pair[1] == 'string') {
			s += (s ? '&' : '') + encode(pair[0]) + '=' + encode(pair[1]);
		}
	}
	return s;
};

const getToken = async (_login: string, _senha: string) => {
	try {
		const { data, status } = await apiAutenticacao.post('identidade/login', {
			login: _login,
			senha: _senha,
		});
		if (status !== 200) return false;
		const { valor } = data;
		const { access_Token, scope, login, empresa, perfil, nomeUsuario } = valor;
		saveSessionToken(access_Token);
		saveSessionUser({
			empresa,
			login,
			nomeUsuario,
			perfil,
			scope,
		});
		return true;
	} catch (error) {
		return false;
	}
};

export const Login = () => {
	let navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [showLoading, setShowLoading] = useState(false);
	const [forgotPassword, setForgotPassword] = useState(false);
	const [email, setEmail] = useState({ value: '', error: false, message: '' });
	const [userInvalid, setUserInvalid] = useState<boolean>(false);
	const [password, setPassword] = useState({
		value: '',
		error: false,
		message: '',
	});
	const [errorMessage, setErrorMessage] = useState('');

	const onLogin = async () => {
		setShowLoading(true);

		try {
			const hasSuccess = await getToken(email.value, password.value);
			if (hasSuccess) {
				setUserInvalid(false);
				setEmail({ value: '', error: false, message: '' });
				setPassword({ value: '', error: false, message: '' });
				setShowLoading(false);
				setShowLoading(false);

				const url = hasAdminUser() ? 'gerenciarpesquisa' : 'historico';
				navigate(`/${url}`);
			} else {
				setUserInvalid(true);
				setShowLoading(false);
			}
		} catch (error) {
			setUserInvalid(true);
			setShowLoading(false);
		}
	};

	const onForgotPassword = async () => {
		
		setShowLoading(true);
	
		try {
			const { data, status } = await apiAutenticacao.post('usuario/senha/solicitar', {
				Email: email.value,
			});

			if (data.erro) {
				if (status === 404) { // Supondo que a API retorne 404 quando o usuário não existir
					setShowLoading(false);
					setErrorMessage('Usuário não encontrado.');
				} else {
					setShowLoading(false);
					setErrorMessage('Ocorreu um erro. Por favor, tente novamente.');
				}
				return;
			}

			setShowLoading(false);
			setForgotPassword(false);
			setErrorMessage(''); // Limpa a mensagem de erro em caso de sucesso
	
		} catch (error) {
			setErrorMessage('Ocorreu um erro. Por favor, tente novamente.');
			setShowLoading(false);
		}
	};
	

	const handleButtonClick = () => {
		if (forgotPassword) {
		  onForgotPassword();
		} else {
		  onLogin();
		}
	  };

	const testEmail = (email: string) => {
		return regex.email.test(email.toLowerCase());
	};

	return (
		<Container>
			<CardContent>
				<CardHeader>
					<Logo src={logo} alt='Logo' />
				</CardHeader>
				<CardBody>
					<Title>Informe seus dados para acessar sua conta</Title>
					<TextField
						id='emailField'
						label='E-Mail'
						variant='outlined'
						type={'email'}
						style={{ width: '100%', margin: '20px 0', maxWidth: '360px' }}
						value={email.value}
						error={email.error}
						helperText={email.message}
						onChange={(event: any) =>
							setEmail({ ...email, value: event.target.value })
						}
					/>
					{errorMessage && <div className="error-message" style={{ color: 'red' }}>{errorMessage}</div>}
					<TextField
						id='passwordFiel'
						label='Senha'
						variant='outlined'
						type={showPassword ? 'text' : 'password'}
						style={{
							width: '100%',
							margin: '20px 0',
							maxWidth: '360px',
							visibility: forgotPassword ? 'hidden' : 'unset',
						}}
						value={password.value}
						error={password.error}
						helperText={password.message}
						onChange={(event: any) =>
							setPassword({ ...password, value: event.target.value })
						}
						InputProps={{
							endAdornment: (
								<InputAdornment position='end'>
									<IconButton
										aria-label='toggle password visibility'
										onClick={() => setShowPassword(!showPassword)}
										onMouseDown={(event) => event.preventDefault()}
										edge='end'
									>
										{showPassword ? (
											<img src={visibilityOff} alt='' />
										) : (
											<img src={visibility} alt='' />
										)}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					{userInvalid && (
						<div className='userInvalid'>
							<span>Usuario ou senha Invalidos</span>
						</div>
					)}
					<Link
						component='button'
						variant='body2'
						onClick={() => setForgotPassword(!forgotPassword)}
					>
						{forgotPassword ? 'Voltar' : 'Esqueci Minha Senha'}
					</Link>
					<Button className={'entrar'} onClick={handleButtonClick}>
						{forgotPassword ? 'Enviar' : 'Entrar'}
					</Button>
				</CardBody>
			</CardContent>
			<Loading mode='blurry' hidden={!showLoading}></Loading>
		</Container>
	);
};
