import { Container } from "./style";
import {TextField, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import checkIco from '../../../assets/checkIco.svg';
import customAnalysis from '../../../assets/customAnalysis.svg';
export const SecondTab = () => (
    <Container>
        <div className="firstContainer">
            <div>
                <h4>Projeto de análise de crédito personalizado com suas métricas</h4>
            </div>
            <div className="description">
                <span >
                    Customização dos motores de análise de crédito de acordo com a política de crédito específica de sua empresa.
                </span>
            </div>
            <div>
                <img src={customAnalysis} alt="Logo homem e mulher analisando graficos" />
            </div>
            <div className="subTitle">
                <span>
                    Além de acessar todos dados disponíveis na análise de Crédito Padrão completa, poderemos adicionar:
                </span>
            </div>
            <div>
                <div className="itemContainerTab" >
                    <img src={checkIco} alt="Icone Check" />
                    <div>
                        <span>Integrações a banco de dados específicos</span>
                    </div>
                </div>
                <div className="itemContainerTab" >
                    <img src={checkIco} alt="Icone Check" />
                    <div>
                        <span>Métricas específicas de análise</span>
                    </div>
                </div>
                <div className="itemContainerTab" >
                    <img src={checkIco} alt="Icone Check" />
                    <div>
                        <span>Novos campos no relatório final</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="secondContainer">
            <div className="secondTitle">
                <h6>Preencha o formulário e entraremos em contato:</h6>
            </div>
            <div className="containerForm">
                <form action="">
                    <div>
                        <InputLabel >Nome e Sobrenome</InputLabel>
                        <TextField  fullWidth  label="Nome e Sobrenome"/>
                    </div>
                    <div>
                    <InputLabel >Nome da Empresa</InputLabel>
                        <TextField fullWidth label="Nome da Empresa"/>
                    </div>
                    <div>
                    <InputLabel >E-mail</InputLabel>
                        <TextField fullWidth label="E-mail"/>
                    </div>
                    <div>
                    <InputLabel >Telefone</InputLabel>
                        <TextField fullWidth label="Telefone"/>
                    </div>
                    <div>
                        <InputLabel id="selectNumberFuncLabel">Número de funcionários</InputLabel>
                        <Select
                        fullWidth
                        labelId="selectNumberFuncLabel"
                        id="selectNumberFunc"
                        value={'1_10'}
                        >
                            <MenuItem value={'1_10'} >0 a 10 funcionários</MenuItem>

                        </Select>
                    </div>
                </form>
            </div>
        </div>
    </Container>
)