import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
	.verify {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
		border: none;
		margin-left: auto;
	}

	.hide {
		display: none;
	}
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;

	.buttonReports {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
	}
`;

export const Tittle = styled.h4`
	font-weight: 500;
	font-size: 31px;
	line-height: 36px;
	color: var(--text-subtitle);
	margin: 40px 10px;
`;
