import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { TipoVariavelFinanceira } from '../../../models/enums/TipoVariavelFinanceiraEnum';
import { IPropsFinancial } from './IPros';
import { filterVariableFinancial, selectColor } from './functions';

export const TableIndebtedness = (props: IPropsFinancial) => {
	const [dividaBancaria, setDividaBancaria] = useState<
		{ key: string; value: number }[]
	>([]);
	const [endivGeral, setEndivGeral] = useState<
		{ key: string; value: number }[]
	>([]);
	const [coberturaDivida, setCoberturaDivida] = useState<
		{ key: string; value: number }[]
	>([]);
	const [caixa, setCaixa] = useState<{ key: string; value: number }[]>([]);
	const [years, setYears] = useState<string[]>([]);

	const getdividaBancaria = () => {
		const dataFiltered = filterVariableFinancial(
			props.analiseDemonstrativo,
			TipoVariavelFinanceira.DividaBancaria,
		);
		if (dataFiltered) {
			setYears(dataFiltered.map((item) => item.key));
			setDividaBancaria(dataFiltered);
		}
	};
	const getEndivGeral = () => {
		const dataFiltered = filterVariableFinancial(
			props.analiseDemonstrativo,
			TipoVariavelFinanceira.EndividamentoGeral,
		);
		if (dataFiltered) {
			setEndivGeral(dataFiltered);
		}
	};
	const getCoberturaDivida = () => {
		const dataFiltered = filterVariableFinancial(
			props.analiseDemonstrativo,
			TipoVariavelFinanceira.CoberturaDivida,
		);
		if (dataFiltered) {
			setCoberturaDivida(dataFiltered);
		}
	};
	const getCaixa = () => {
		const dataFiltered = filterVariableFinancial(
			props.analiseDemonstrativo,
			TipoVariavelFinanceira.CaixaBancos,
		);
		if (dataFiltered) {
			setCaixa(dataFiltered);
		}
	};

	useEffect(() => {
		getdividaBancaria();
		getEndivGeral();
		getCoberturaDivida();
		getCaixa();
	}, []);
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: props.minWidth ? props.minWidth : 650 }} size='small'>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: 'var(--very-dark-blue)',
								th: { color: '#ffff' },
							}}
						>
							<TableCell>Endividamento</TableCell>
							{years.map((year) => (
								<TableCell>{year}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key={'EndividamentoGeral-1'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Endividamento Geral
							</TableCell>
							{endivGeral
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{ color: selectColor.endivGeral(Number(item.value)) }}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
						{/* <TableRow key={'EndividamentoFinanceiro'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Endividamento Financeiro
							</TableCell>
							<TableCell sx={{ color: selectColor.endivFinanceiro(0.5) }}>
								0,5
							</TableCell>
							<TableCell sx={{ color: selectColor.endivFinanceiro(1) }}>
								1
							</TableCell>
							<TableCell sx={{ color: selectColor.endivFinanceiro(5) }}>
								5
							</TableCell>
						</TableRow> */}
						<TableRow key={'Dívidabancária-2'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Dívida Bancária
							</TableCell>
							{dividaBancaria
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{
											color: selectColor.dividaBancaria(Number(item.value)),
										}}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
						{/* <TableRow key={'Dívidabancárialíquida'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Dívida bancária líquida
							</TableCell>
							<TableCell sx={{ color: selectColor.dividaLiquida(0.7) }}>
								0.7
							</TableCell>
							<TableCell sx={{ color: selectColor.dividaLiquida(1.75) }}>
								1.75
							</TableCell>
							<TableCell sx={{ color: selectColor.dividaLiquida(3) }}>
								3
							</TableCell>
						</TableRow> */}
						<TableRow key={'Coberturadadívida-3'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Cobertura da dívida
							</TableCell>
							{coberturaDivida
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{
											color: selectColor.coberturaDivida(Number(item.value)),
										}}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
						<TableRow key={'Caixa/Bancoscurtoprazo-4'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Caixa/Bancos curto prazo
							</TableCell>
							{caixa
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{
											color: selectColor.caixa(Number(item.value)),
										}}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
