import { Box } from '@mui/material';
import gif from '../../assets/loading.gif';
import {
	BlurrySyled,
	Container,
	DefaultStyled,
	Img,
	Text,
	Content,
} from './style';

interface IProps {
	hidden?: boolean;
	mode?: 'blurry' | 'default';
	messages?: string;
}

export const Loading = (props: IProps) => {
	const setStyle = () =>
		props.mode === 'blurry' ? BlurrySyled : DefaultStyled;
	return (
		<Container style={{ ...setStyle(), position: 'fixed', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 9999 }} hidden={props.hidden}>
			<Content>
				<Img src={gif} />
				<Box style={{ display: 'flex' }}>
					<Text>{props.messages}</Text>
				</Box>
			</Content>
		</Container>
	);
};
