import { validarCNPJ } from './validarCnpj';

export const cnpjMask = (value: any) => {

	var valor = value.replace(/\D+/g, '');

	if(valor.length == 14)
	{
		return valor
		.replace(/\D+/g, '')
		.replace(/(\d{2})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1/$2')
		.replace(/(\d{4})(\d)/, '$1-$2')
		.replace(/(-\d{2})\d+?$/, '$1');
	}

	if (valor.length == 11)
	{
		return valor
		.replace(/\D+/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
	}
};

export const cpfMask = (value: string) => {
	return value
		.replace(/\D+/g, '')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d)/, '$1.$2')
		.replace(/(\d{3})(\d{1,2})$/, '$1-$2');
};

export const cnpjOrCpfMask = (value: string) => {
	console.log('cnpjOrCpfMask', value);
	return value.length === 14
		? cnpjMask(value)
		: cpfMask(value.replace('000', ''));
};

export const mascaraMoeda = (event: any) => {
	const onlyDigits = event.target.value
		.split('')
		.filter((s: any) => /\d/.test(s))
		.join('')
		.padStart(3, '0');
	const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
	event.target.value = maskCurrency(digitsFloat);
};

const maskCurrency = (valor: any, locale = 'pt-BR', currency = 'BRL') => {
	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
	}).format(valor);
};
