import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;

	.buttonPesquisa {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
	}
`;

export const Title = styled.h4`
	font-weight: 500;
	font-size: 31px;
	line-height: 36px;
	color: var(--text-subtitle);
	margin: 20px 10px;
`;

export const SubTitle = styled.span`
	line-height: 24px;
	letter-spacing: 0.5px;
	color: var(--text-body);
	display: inline-block;
	margin-bottom: 20px;
`;

export const Form = styled.div`
	width: 100%;
	display: flex;
`;

export const HeaderModal = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const ContentModal = styled.div`
	padding: 15px;
	width: 55vw;
	margin-left: 25%;
    margin-top: 5%;
	background: white;
	border: 2px solid #000;
	box-shadow: 24px;
	border-radius: 10px;
`;

export const InputStyled: CSSProperties = {
	minWidth: '80%',
	marginBottom: '16px',
};
export const PaginationStyled: CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
};
