import  styled from "styled-components";

export const Container = styled.div`
    div {
        border: 2px dashed var(--blue-light);
        position: relative;
        

        
    }
   

    .hide {
        display: none;
    }
`;