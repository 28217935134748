import styled from "styled-components"

export const Container = styled.div`
`;

export const Content = styled.div`
    
    h6 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 144px;
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: 0.15px;
        color: var(--black);

        .colorSpanPack {
            color: var(--dark-blue);
            margin-left: 3px;
            margin-right: 3px;
        }}

        .registration {
            display: flex;
            align-items: center;
            flex-direction: column;
            color: var(--black-off);
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;

               input {
                width: 375.7px;
                height: 5px;
                &:hover{
                    border: 1px solid var(--pure-violet);
                }} 

                div {
                    margin-top: 12px;
                }
            }

         .button {
                .buttonAdvance {
                    height: 53px;
                    width: 196px;
                    background: #84FFFF;
                    border-radius: 10px;
                    border: 0px;
                    margin-left: 38%;
                    margin-top: 5%;

                    color: var(--very-dark-blue);
                    font-family: 'Roboto';
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 16px;

                    letter-spacing: 1.25px;
                    text-transform: uppercase;
                }
            }

`