import { TextField, Button, Modal, Box, Typography } from "@mui/material";
import { Container, Content } from "./Style";
import { useEffect, useState } from "react";
import { Header } from "../Header";
import { getSessionUser } from "../../utils/users";
import { apiAutenticacao, headers } from "../../services/api";
import { Loading } from "../Loading";
import { useNavigate } from 'react-router-dom';

export const Profile = () => {
    let navigate = useNavigate();

  const [nome, setNome] = useState<string | undefined>("");
  const [telefone, setTelefone] = useState<string>("");
  const [email, setEmail] = useState<string | undefined>("");
  const [senhaAtual, setSenhaAtual] = useState<string>("");
  const [senha, setSenha] = useState<string>("");
  const [confirmarSenha, setConfirmarSenha] = useState<string>("");
  const [showLoading, setShowLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const handleChangeNome = ({ target }: any) => {
    setNome(target.value);
  };

  const handleChangeTelefone = ({ target }: any) => {
    setTelefone(target.value);
  };

  const handleChangeEmail = ({ target }: any) => {
    setEmail(target.value);
  };

  const handleChangeSenhaAtual = ({ target }: any) => {
    setSenhaAtual(target.value);
  };

  const handleChangeSenha = ({ target }: any) => {
    setSenha(target.value);
  };

  const handleChangeConfirmarSenha = ({ target }: any) => {
    setConfirmarSenha(target.value);
  };

  useEffect(() => {
    const user = getSessionUser();
    if (user) {
      setNome(user?.nomeUsuario);
      setEmail(user?.login);
    }
  }, []);

  const editUser = async () => {
    setShowLoading(true);
    try {
      const config = {
        headers: headers(),
      };

      const response = await apiAutenticacao.post(
        "usuario/atualizar/usuario",
        {
          nome: nome,
          email: email,
          senha: senhaAtual,
          novaSenha: senha,
          confirmacaoSenha: confirmarSenha,
        },
        config
      );

      setShowLoading(false);
      setModalMessage(
        "A alteração será refletida no próximo login na plataforma!"
      );
      setOpenModal(true);
    } catch (error: any) {
      setShowLoading(false);
    }

    return;
  };

  const handleClose = () => {
    setOpenModal(false);

    navigate(`/historico`);
  };

  const body = (
    <Box
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 400,
        bgcolor: "background.paper",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
      }}
    >
      <Typography id="modal-modal-title" variant="h6" component="h2">
        {modalMessage}
      </Typography>
      <Button onClick={handleClose}>Fechar</Button>
    </Box>
  );

  return (
    <>
      <Header />
      <Container>
        <Content>
          <div>
            <h2>Editar perfil</h2>
          </div>
          <div className="ProfileData">
            <div>
              <h3>Dados Pessoais</h3>
            </div>
            <div>
              <TextField
                fullWidth
                label="Nome"
                value={nome}
                onChange={handleChangeNome}
              ></TextField>
            </div>
            <div style={{ display: "none" }}>
              <TextField
                fullWidth
                label="Telefone"
                value={telefone}
                onChange={handleChangeTelefone}
              ></TextField>
            </div>
            <div>
              <TextField
                fullWidth
                label="Email"
                value={email}
                onChange={handleChangeEmail}
              ></TextField>
            </div>
            <div>
              <TextField
                fullWidth
                label="Senha atual"
                value={senhaAtual}
                onChange={handleChangeSenhaAtual}
              ></TextField>
            </div>
            <div>
              <TextField
                fullWidth
                label="Senha"
                value={senha}
                onChange={handleChangeSenha}
              ></TextField>
            </div>
            <div>
              <TextField
                fullWidth
                label="Confirmar senha"
                value={confirmarSenha}
                onChange={handleChangeConfirmarSenha}
              ></TextField>
            </div>
            <div>
              <Button
                sx={{
                  width: "10.4em",
                  marginLeft: "auto",
                }}
                color="secondary"
                variant="contained"
                onClick={() => editUser()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </Content>
        <Loading
          mode="blurry"
          hidden={!showLoading}
          messages="Atualizando o perfil"
        ></Loading>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          {body}
        </Modal>
      </Container>
    </>
  );
};
