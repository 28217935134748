import { Container } from "./style";
import checkIco from '../../assets/checkIco.svg';
import checkBoxOutlineBlank from '../../assets/checkBoxOutlineBlank.svg';
import { Button } from "../Button";
import { ICard } from "./ICard";


interface IProps {
    card: ICard
    
}

export const CardLarge = (props: IProps) => (
    <Container>
        <div className="titleCard">
            <h4>{props.card.title}</h4>
        </div>
        <div>
            <div className="lineBlue"></div>
        </div>
        <div className="cardDescription">
            <span>
                {props.card.description}
            </span>
        </div>
        <div className="containerItem">
            {props.card?.advanceInfo &&  (
                <div className="titleItems">
                    <span>Todas as informações da Análise Básica
                        <br/>+</span>
                </div>
            )}
            {props.card?.basicInfo &&  (
                <div className="titleItems">
                    <span>Todas as informações da Análise Avançada
                        <br/>+</span>
                </div>
            )}
            {
                props.card?.items.length > 0 && (
                    props.card?.items.map((item, index) => (
                        <div className="itemContainer" key={index}>
                            <img src={checkIco} alt="Icone Check" />
                            <div>
                                <span>{item}</span>
                            </div>
                        </div>       
                    ))
                )
            }           
        </div>
        <div className={(props.card.optional?.length < 1 && !props.card.advanceInfo) ? 'firstCard' : ''}></div>
        <div className={props.card.advanceInfo ? 'lastCard' : ''}></div>
        {
            props.card.optional?.length > 0 &&  (
                <div className="containerOptional">
                    <div>
                       <p>Opcionais:</p>
                    </div>
                    {
                        props.card.optional?.map((opt, index) => (
                            <div key={index}>
                                <img  src={checkBoxOutlineBlank} alt="Icone Check" />
                                <span>{opt}</span>
                            </div>
                        ))
                    }
                </div>
            )
        }
        <div className="contentContainerFooter">
            <div className="cardSubTitle">
                    <h6>Você ainda vai obter:</h6>
            </div>
            <div className="containerIteFooter">
                {
                    props.card?.subItems.length > 0 && (
                        props.card?.subItems.map((subItem, index) => (
                            <div className="itemContainerFooter" key={index}>
                                <img  src={checkIco} alt="Icone Check" />
                                <div>
                                    <span>{subItem}</span>
                                </div>
                            </div>
                        ))
                    )
                }
            </div>
        </div>
        <div>
            <div className="lineBlueLarge"></div>
        </div>
        <div className="containerFooterMoney">
            <div>
                <span className="textFotterMoney">A partir de</span>
            </div>
            <div>
                <span className="textFotterMoney">R$ </span>
                    <span className="money">{props.card.price}</span> 
                <span className="textFotterMoney">/mês</span>
            </div>
            
        </div>
        <div>
            <Button className="btn-to-hire">Contratar</Button>
        </div>
    </Container>
)