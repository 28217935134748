import styled from "styled-components";

export const Container = styled.div`
    display: flex;
    flex: 1;
    .outlet {
        max-height: 1016px;
    }
    ul li div {
        min-width: 502px;
        
        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 31px;
            line-height: 36px;
            text-align: center;
            color: var(--very-dark-blue);
            
        }

        h6 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.15px;
            color: var(--moderate-cyan);
        }

    }
    
    .Tabs {
      

        border-radius: 2rem;
    }
    .disable {
        color: var(--text-disable) !important;
    }
    ul.nav {
       
        margin: 0 auto 2rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 3px solid var(--dark-blue);
        border-radius: 2rem;
    }
    ul.nav li {
       
        padding: 1rem;
        list-style: none;
        text-align: center;
        cursor: pointer;
        transition: all 0.7s;
        border-bottom-left-radius: 22px;
        border-top-left-radius: 22px;
    }

    ul.nav li:nth-child(2) {
        border-radius: 0;
        border-bottom-right-radius: 22px;
        border-top-right-radius: 22px;
    }

    ul.nav li:hover {
        background: rgba(50, 224, 196, 0.15);
    }

    ul.nav li:active {
        background: var(--shape);
    }

`;