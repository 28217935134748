import React from 'react';
import { lighten } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, {
	circularProgressClasses,
} from '@mui/material/CircularProgress';

interface IProps {
	children?: React.ReactNode;
	size?: number | string;
	value?: number;
	title?: string;
	color?: string | null;
}

export default function ProgressCircle(props: IProps) {
	return (
		<Box sx={{ position: 'relative', width: props.size, height: '100%' }}>
			<CircularProgress
				variant='determinate'
				sx={{
					color: (theme) =>
						lighten(`${props.color ?? theme.palette.primary.main}`, 0.7),
					display: 'flex',
				}}
				size={'100%'}
				thickness={4}
				value={100}
			/>
			<CircularProgress
				variant='determinate'
				sx={{
					color: (theme) => `${props.color ?? theme.palette.primary.main}`,
					animationDuration: '550ms',
					position: 'absolute',
					left: 0,
					[`& .${circularProgressClasses.circle}`]: {
						strokeLinecap: 'round',
					},
					top: 0,
					display: 'flex',
				}}
				size={'100%'}
				thickness={4}
				value={props.value}
			/>
			<Box
				sx={{
					top: 0,
					left: 0,
					bottom: 0,
					right: 0,
					position: 'absolute',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				{props.children}
			</Box>
		</Box>
	);
}
