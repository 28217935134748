import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Header } from '../../Header';
import { Loading } from '../../Loading';
import { Error } from '../../Error';

import { Container, Content, Tittle } from './style';

import { ClientReports } from '../../../models/reportsClient';
import { apiOperation, headers } from '../../../services/api';

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Typography,
	Button,
	Card,
	CardContent,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { cnpjOrCpfMask } from '../../../utils/mask';
import moment from 'moment';

export const SearchManager = () => {
	const [loadingModal, setShowLoading] = useState<boolean>(false);
	const [clientReports, setClientReports] = useState<ClientReports>();
	const [expanded, setExpanded] = useState<string | false>('');
	const [errorApi, setErrorApi] = useState({
		message: '',
		title: '',
		state: false,
	});

	const handleChange =
		(panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
			setExpanded(newExpanded ? panel : false);
		};

	useEffect(() => {
		async function get() {
			setShowLoading(true);
			try {
				const config = {
					headers: headers(),
				};
				const { data } = await apiOperation.get<ClientReports>(
					`/administracao/obter`,
					config,
				);
				if (data) {
					setClientReports(data);
				}
			} catch (error: any) {
				setErrorApi({
					title: `API Não disponivel`,
					message: `Falha ao baixar relatório!`,
					state: true,
				});
			} finally {
				setShowLoading(false);
			}
		}
		get();
	}, []);

	const getUrlsReports = async () => {
		try {
			const config = {
				headers: headers(),
			};
			const { data } = await apiOperation.get<string[]>(
				`administracao/relatorio`,
				config,
			);

			return data ?? [];
		} catch (error: any) {
			setErrorApi({
				title: `API Não disponivel`,
				message: `Caso o problema continue informe o seguinte erro: ${error?.response?.status}-${error?.response?.statusText}`,
				state: true,
			});
		} finally {
			setShowLoading(false);
		}
	};

	const onGenerateReport = async () => {
		var urlDonwloadReports = (await getUrlsReports()) || [];

		if (!urlDonwloadReports || urlDonwloadReports?.length === 0) {
			setErrorApi({
				title: `Nenhum relatorio foi encontrado`,
				message: ``,
				state: true,
			});
		} else {
			urlDonwloadReports.forEach((url) => window.open(url, '_blank'));
		}
	};

	return (
		<>
			<Header />
			<Loading mode='blurry' hidden={!loadingModal} />
			<Container>
				<Content>
					<Tittle>Gerenciamento de Pesquisa</Tittle>
					<Button
						disabled={(clientReports?.relatorioClientes || []).length <= 0}
						className={'buttonReports'}
						onClick={onGenerateReport}
					>
						Gerar Relatorio
					</Button>
				</Content>
				<Card>
					<CardContent>
						<div>
							{clientReports?.relatorioClientes.map((relatorio) => (
								<Accordion
									expanded={expanded === relatorio.nomeCliente}
									onChange={handleChange(relatorio.nomeCliente)}
								>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls='panel1bh-content'
										id='panel1bh-header'
									>
										<Typography sx={{ width: '33%', flexShrink: 0 }}>
											{relatorio.nomeCliente}
										</Typography>
										<Typography
											sx={{
												color: 'text.secondary',
												width: '33%',
												flexShrink: 0,
											}}
										>
											Sem Documento: {relatorio.quantidadeSemDocumento}
										</Typography>
										<Typography
											sx={{
												color: 'text.secondary',
												width: '33%',
												flexShrink: 0,
											}}
										>
											Com Documento: {relatorio.quantidadeComDocumento}
										</Typography>
									</AccordionSummary>
									<AccordionDetails>
										<TableContainer>
											<Table sx={{ minWidth: 650 }} aria-label='simple table'>
												<TableHead>
													<TableRow>
														<TableCell>#</TableCell>
														<TableCell>Cliente</TableCell>
														<TableCell>Data Inclusão</TableCell>
														<TableCell>Usuario Inclusão</TableCell>
														<TableCell>Status</TableCell>
														<TableCell>Tipo Analise</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{relatorio.analisesRelatorio.map((analise, index) => (
														<TableRow
															key={analise.cnpjOuCpf}
															sx={{
																'&:last-child td, &:last-child th': {
																	border: 0,
																},
															}}
														>
															<TableCell scope='row'>{++index}</TableCell>
															<TableCell component='th' scope='row'>
																{cnpjOrCpfMask(analise.cnpjOuCpf)}
															</TableCell>
															<TableCell>
																{moment(analise.dataInclusao).format(
																	'DD/MM/YYYY',
																)}
															</TableCell>
															<TableCell>{analise.usuarioInclusao}</TableCell>
															<TableCell>{analise.status}</TableCell>
															<TableCell>{analise.tipoAnalise}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</AccordionDetails>
								</Accordion>
							))}
						</div>
					</CardContent>
				</Card>
				<Error
					hasOpen={errorApi.state}
					handleClose={() => setErrorApi({ ...errorApi, state: false })}
					errorMsg={errorApi.message}
					title={errorApi.title}
				/>
			</Container>
		</>
	);
};
