import { RouteManagement } from './RouteManagement';
import { GlobalStyle } from './styles/global';
import { ThemeProvider } from '@mui/material/styles';
import { theme } from './styles/themeMaterial';

function App() {
	return (
		<>
			<ThemeProvider theme={theme}>
				<RouteManagement />
				<GlobalStyle />
			</ThemeProvider>
		</>
	);
}

export default App;
