import { useState } from "react"
import { InputLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material"
import { Container, Content } from "./style"

export const PackageChoice = () => {
    const [plan, setPlan] = useState<string>('1_10');

    const changePlan = (event: SelectChangeEvent) => {
        setPlan(event.target.value);
    }
    return (
        <>
        <Container>
            <Content>
                <div className="title">
                            <h6>
                                Para começar, escolha qual <span className="colorSpanPack"> Pacote de consultas </span> deseja contratar
                            </h6>
                </div>
                <div  className="containerSelect">
                    <InputLabel id="selectNumberFuncLabel">Plano selecionado: </InputLabel>
                    <Select
                    onChange={changePlan}
                    labelId="selectNumberFuncLabel"
                    id="selectNumberFunc"
                    value={plan}
                    >
                        <MenuItem value={'1_10'} >Análise Padrão - plano Completo</MenuItem>

                    </Select>
                </div>
                <div className="subTitle">
                    <span>
                        Escolha seu pacote de consultas mensal:
                    </span>
                </div>
                <div className="containerCotation">
                    <div className="cardCotation">
                        <div>xx - xx</div>
                        <div>consultas</div>
                        <div>
                            <span className="valueMoney">
                                R$xxxx    
                            </span>
                        </div>
                    </div>
                    <div className="othersCotation">
                        <div>
                            Acima de 300 consultas
                        </div>
                        <div className="pushCotation">
                            <span>
                                Solicitar cotação
                            </span>
                        </div>
                    </div>
                </div>
                <div className="lineBlue">
                    <div ></div>
                </div>
                <div className="footer">
                    <div className="labelMoney">
                        Valor a pagar:
                    </div>
                    <div>
                        <span className="price">R$ xxx,XX</span>
                    </div>
                </div>
                </Content>
            </Container>
        </>
    )
 }

