const bakground = '#E5E5E5';
const backgroundWhite = ' #F8F8F8;';
const purpleLight = '#5F5FC4';
const blueLight = '#84FFFF';
const veryDarkBlue = '#001064';
const darkModerateBlue2 = '#4255A3';
const darkModerateBlue = '#3C64B1';
const moderateCyan = ' #4BCBCC;';
const textDisable = '#B3B3B3';
const darkBlue = '#283593';
const green = '#33CC95';
const blue = '#5429cc';
const veryDarkGrayishCyan = '#373F41';
const black = '#000000';
const veryDarkGray = '#212121';
const pureViolet = '#6200EE';
const lightGray = '#DADADA';
const backGroundGray = '#F8F8F8';
const grey = '#4E5155';
const textTitle = '#5F5FC4';
const textSubtitle = '#283593';
const textBody = '#212121';
const textLink = '#001064';
const shape = '#FFF';
const blackOff = '#00000099';
const red = '#d9534f';

export {
	bakground,
	backgroundWhite,
	purpleLight,
	blueLight,
	veryDarkBlue,
	darkModerateBlue2,
	darkModerateBlue,
	moderateCyan,
	textDisable,
	darkBlue,
	green,
	blue,
	veryDarkGrayishCyan,
	black,
	veryDarkGray,
	pureViolet,
	lightGray,
	backGroundGray,
	grey,
	textTitle,
	textSubtitle,
	textBody,
	textLink,
	shape,
	blackOff,
	red,
};
