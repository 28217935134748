import { useEffect, useState } from 'react';
import {
	Container,
	Content,
	SubTitle,
	Title,
	Text,
	BoxStyled,
	InputStyled,
	TextNumber,
	CardStyled,
	CellContent,
	CellTitle,
	CellDivider,
	Row,
	HeaderModal,
	ContainerGrid,
	ContainerForm
} from './style';
import { Box, Button, Card, Divider, Modal, IconButton, TextField, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, FormHelperText, TextareaAutosize } from '@mui/material';
import { Close, InsertDriveFile } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import SendIcon from '@mui/icons-material/Send';
import moment from 'moment';
import { Header } from '../Header';
import amico from '../../assets/amico.svg';
import analysis from '../../assets/analysis.svg';
import ProgressCircle from '../../ui/ProgressCircle';
import { colorsProgress } from '../../data/colorsProgress';
import { useParams } from 'react-router-dom';
import { getSessionUser } from "../../utils/users";
import { IUser } from "../Login/ILogin";
import { Empresas } from "../../utils/empresa"
import { apiOperation, headers } from '../../services/api';
import {
	RetornoAnalise,
	Resultado,
	AnaliseDemonstrativo,
} from '../../models/retornoAnalise';
import { getUrlMap } from '../../services/maps';
import { TipoIndiceFinanceiro } from '../../models/enums/TipoIndiceFinanceiroEnum';
import { cnpjMask, cnpjOrCpfMask, cpfMask } from '../../utils/mask';
import { Loading } from '../Loading';
import { TipoVariavelFinanceira } from '../../models/enums/TipoVariavelFinanceiraEnum';
import { TipoPorte } from '../../models/enums/TipoPorteEnum';
import {
	TableDocuments,
	TableIndebtedness,
	TableLiquidity,
	TablePartners,
	TableProfitability,
} from './Tables';
import { ListFormat } from 'typescript';
import axios from 'axios';
import { isNullOrUndefined } from 'util';
import { Documento } from '../../models/analise';
import { ContainerTextArea } from '../newSearch/style';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '60%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

const r: Resultado = {};

let usuarioLogado: IUser | null = null;

interface FormData {
	codigoEmpresa: string;
	valorLimite: string;
	tipoLimite: string;
	valorFaturado?: string;
	valorCartao?: string;
	parecerComercial?: string;
}

export const Results = () => {
	const { id } = useParams();
	const [result, setResult] = useState<Resultado>(r);
	const [mapsUrl, setMapsUrl] = useState<string>();
	const [showLoading, setShowLoading] = useState(false);
	const [carregaItens, setCarregaItens] = useState(false);
	const [showDetaisIndicators, setShowDetaisIndicators] = useState({
		liquidity: true,
		indebtedness: true,
		profitability: true,
	});
	const [modalState, setModalState] = useState({
		open: false,
		title: '',
		children: <></>,
	});

	const viewDetaisIndicators = (demonstrativo: AnaliseDemonstrativo[]) => {
		if (demonstrativo.length > 0) {
			const countLiq = demonstrativo.filter(
				(item: AnaliseDemonstrativo) =>
					item.tipoVariavelFinanceira === TipoIndiceFinanceiro.LiquidezCorrente,
			);
			const countIndeb = demonstrativo.filter(
				(item: AnaliseDemonstrativo) =>
					item.tipoVariavelFinanceira ===
					TipoIndiceFinanceiro.DividaBancariaEbitda ||
					item.tipoVariavelFinanceira ===
					TipoIndiceFinanceiro.EndividamentoGeral ||
					item.tipoVariavelFinanceira ===
					TipoIndiceFinanceiro.CoberturaDaDivida ||
					item.tipoVariavelFinanceira === TipoIndiceFinanceiro.CaixaBancosCP,
			);

			const countProfit = demonstrativo.filter(
				(item: AnaliseDemonstrativo) =>
					item.tipoVariavelFinanceira === TipoIndiceFinanceiro.ROIC,
			);
			setShowDetaisIndicators({
				liquidity: countLiq.length > 0,
				indebtedness: countIndeb.length > 0,
				profitability: countProfit.length > 0,
			});
		} else {
			setShowDetaisIndicators({
				liquidity: false,
				indebtedness: false,
				profitability: false,
			});
		}
	};

	useEffect(() => {
		usuarioLogado = getSessionUser();
		if (usuarioLogado?.empresa === Empresas.BRT) {
			setCarregaItens(true)
		}
		async function get() {
			setShowLoading(true);
			try {
				const config = {
					headers: headers(),
				};
				const { data } = await apiOperation.get<RetornoAnalise>(
					`analise/obter?analiseId=${id}`,
					config,
				);
				if (data) {
					setResult(data.valor[0] || []);
					const endereco =
						data.valor[0]?.analiseCliente?.find((x) => x)?.cliente?.endereco ||
						'';
					const url = await getUrlMap(endereco);
					setMapsUrl(url);
					viewDetaisIndicators(data.valor[0].analiseDemonstrativo || []);
				}
			} catch (error: any) {
				// setResult(mock.valor);
			} finally {
				setShowLoading(false);
			}
		}
		get();
	}, []);

	const defineColor = (progress: number | undefined) =>
		progress
			? (colorsProgress.find((item) => item.key <= progress) || {}).value
			: null;


	const handleOpen = (title: string) => {
		let children =
			title === 'Liquidez' ? (
				<TableLiquidity
					analiseDemonstrativo={result?.analiseDemonstrativo || []}
				/>
			) : title === 'Endividamento' ? (
				<TableIndebtedness
					analiseDemonstrativo={result?.analiseDemonstrativo || []}
				/>
			) : title === 'Rentabilidade' ? (
				<TableProfitability
					analiseDemonstrativo={result?.analiseDemonstrativo || []}
				/>
			) : title === 'Socios' ? (
				<TablePartners listPartners={result?.analiseSociedade || []} />
			) : title === 'Documentos' ? (
				<TableDocuments listDocument={result?.analiseDocumentacao || []} />
			) : (
				<> </>
			);
		setModalState({
			open: true,
			title,
			children,
		});
	};

	const montarEndereco = () => {
		const cliente = result?.analiseCliente?.find((x) => x)?.cliente;

		return `${cliente?.endereco}, ${cliente?.bairro} - ${cliente?.cidade}, ${cliente?.estado} - ${cliente?.cep}`;
	};

	const textoRiscoCredito = () => {
		const valor = result?.analiseCredito?.find((x) => x)?.scoreCalculado || 0;
		return valor < 40 ? 'Alto' : valor > 70 ? 'Baixo' : 'Médio';
	};

	const prefixMath = () => {
		const value =
			result.analiseCredito?.find((x) => x)
				?.limiteCalculadoReceitaFaturamentoLiquido || 0;

		var suffixes = ['', 'Mil', 'Mi', 'Bi', 'Tri'];
		const suffixNum =
			0 === value ? value : Math.floor(Math.log(value) / Math.log(1000));
		let shortValue = parseFloat((value / Math.pow(1000, suffixNum)).toFixed(2));
		return shortValue + `${suffixes[suffixNum]}`;
	};

	const typePortText = () => {
		const value = result?.analisePorte?.find((x) => x)?.tipoPorte || 0;
		const index = Object.values(TipoPorte).indexOf(value);
		const port = Object.keys(TipoPorte)[index];
		return port;
	};

	const disabledButtonDocuments = () => {
		const docs = result?.analiseDocumentacao || [];
		return docs.length > 0 ? true : false;
	};

	const handleMask = (value: any) => {
		if (value?.cnpjOuCpf) {
			return value.cnpjOuCpf.length > 11 ? cnpjMask(value.cnpjOuCpf) : cpfMask(value.cnpjOuCpf)
		}
		return '';
	}

	const mascaraMoeda = (event: any) => {
		const onlyDigits = event.target.value
			.split('')
			.filter((s: any) => /\d/.test(s))
			.join('')
			.padStart(3, '0');
		const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
		event.target.value = maskCurrency(digitsFloat);
	};

	const maskCurrency = (valor: any, locale = 'pt-BR', currency = 'BRL') => {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
		}).format(valor);
	};

	const tipoDocumentoBusca = (value: any) => {
		if (value?.cnpjOuCpf) {
			return value.cnpjOuCpf.length > 11 ? 'CNPJ' : 'CPF'
		}
		return '';
	}

	const tipoRazaoNome = (value: any) => {
		if (value?.cnpjOuCpf) {
			return value.cnpjOuCpf.length > 11 ? 'Razão Social: ' : 'Nome: '
		}
		return '';
	}

	const tipoFundacaoNascimento = (value: any) => {
		if (value?.cnpjOuCpf) {
			return value.cnpjOuCpf.length > 11 ? 'Data Fundação: ' : 'Data de Nascimento: '
		}
		return '';
	}

	const tipoNomeFantasia = (value: any) => {
		if (value?.cnpjOuCpf) {
			return value.cnpjOuCpf.length > 11 ? 'Nome Fantasia: ' : 'Nome: '
		}
		return '';
	}


	const Download = async (params = "") => {
		try {
		  const config = {
			headers: headers(),
		  };
	
		  setShowLoading(true);
		  let urlString = `analise/relatorio
					?analiseId=${params}`;
	
		  const { data } = await apiOperation.get<Documento>(urlString, config);
	
		  window.open(data.valor, "_blank", "noopener,noreferrer");
	
		  setShowLoading(false);
		} catch (error: any) {
		  setShowLoading(false);
		}
	  };

	const [modalOpen, setModalOpen] = useState(false);
	const [formData, setFormData] = useState<FormData>({
		codigoEmpresa: '',
		valorLimite: '',
		tipoLimite: 'FAT',
		valorFaturado: '',
		valorCartao: '',
		parecerComercial: ''
	});
	const [errors, setErrors] = useState<Partial<FormData>>({});

	const handleClose = () => {
		setModalOpen(false);

		setModalState({
			open: false,
			title: '',
			children: <></>,
		});
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
		const { id, value } = e.target;
		setFormData({
			...formData,
			[id]: value
		});
		setErrors({
			...errors,
			[id]: ''
		});
	};

	const handleSelectChange = (e: SelectChangeEvent<string>) => {
		setFormData({
			...formData,
			tipoLimite: e.target.value
		});
		setErrors({
			...errors,
			tipoLimite: ''
		});
	};

	const atualizarLimiteSugerido = async () => {
		const newErrors: Partial<FormData> = {};
		if (!formData.codigoEmpresa) newErrors.codigoEmpresa = 'Código da empresa é obrigatório';
		if (formData.tipoLimite === 'AMBOS') {
			if (!formData.valorFaturado) newErrors.valorFaturado = 'Valor Faturado é obrigatório';
			if (!formData.valorCartao) newErrors.valorCartao = 'Valor Cartão é obrigatório';
		} else {
			if (!formData.valorLimite) newErrors.valorLimite = 'Limite Sugerido é obrigatório';
		}
		if (!formData.tipoLimite) newErrors.tipoLimite = 'Tipo de Limite é obrigatório';

		if (Object.keys(newErrors).length > 0) {
			setErrors(newErrors);
			return;
		}

		try {
			setShowLoading(true);
			const config = {
				headers: headers()
			}

			const data = formData.tipoLimite === 'AMBOS' ? {
				CodigoEmpresa: formData.codigoEmpresa,
				TipoLimite: handleMoneyValue(formData.tipoLimite),
				ValorFaturado: handleMoneyValue(formData.valorFaturado),
				ValorCartao: handleMoneyValue(formData.valorCartao),
				Descricao: formData.parecerComercial
			} : {
				ValorLimite: handleMoneyValue(formData.valorLimite),
				CodigoEmpresa: formData.codigoEmpresa,
				TipoLimite: formData.tipoLimite,
				Descricao: formData.parecerComercial
			};

			const response = await apiOperation.post('analise/alterarlimite', data, config);

			handleClose();
			setShowLoading(false);
			setFormData({
				codigoEmpresa: '',
				valorLimite: '',
				tipoLimite: 'FAT',
				valorFaturado: '',
				valorCartao: '',
				parecerComercial: ''
			});
		}
		catch (error) {
			handleClose();
			setShowLoading(false);
			setFormData({
				codigoEmpresa: '',
				valorLimite: '',
				tipoLimite: 'FAT',
				valorFaturado: '',
				valorCartao: '',
				parecerComercial: ''
			});
		}
	};

	const handleMoneyValue = (value: any) => {
		let money: any = 0;
		if (value) {
			money = value.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
		}
		else {
			money = '0.0'
		}
		return money;
	}

	return (
		<>
			<Header />
			<Container id='printPDF'>
				{showLoading ? (
					<Loading hidden={!showLoading} />
				) : (
					<>
						<Content>
							<Title>Resultado de sua pesquisa - {tipoDocumentoBusca(result)} </Title>
							<Button
								sx={{
									width: '10.4em',
									marginLeft: 'auto',
									marginRight: '1%',
								}}
								color='secondary'
								variant='contained'
								startIcon={<InsertDriveFile />}
								disabled={result.status?.descricao != 'Concluída'}
								onClick={() => Download(result.analiseId)}
							>
								Relatório
							</Button>
							
							<Button
								sx={{
									width: '10.4em',
								}}
								color='secondary'
								variant='contained'
								startIcon={<InsertDriveFile />}
								disabled={!disabledButtonDocuments()}
								onClick={() => handleOpen('Documentos')}
							>
								Documentos
							</Button>
						</Content>
						<Box style={{ display: 'flex' }}>
							<div>
								<img src={amico} alt='' />
							</div>
							<div style={{ width: '100%' }}>
								<Box
									style={{
										display: 'flex',
										justifyContent: 'center',
									}}
								>
									<Card
										sx={{
											...CardStyled,
											minHeight: '100px',
											width: '50%',
										}}
									>
										<Box
											style={{
												...BoxStyled,
												alignItems: 'unset',
											}}
										>
											<SubTitle>
												{tipoDocumentoBusca(result)}:{' '}
												<Text> {handleMask(result)}</Text>
											</SubTitle>
											<SubTitle>
												{tipoRazaoNome(result)}
												<Text>
													{result?.analiseCliente?.find((x) => x.cliente)
														?.cliente?.razao || ''}
												</Text>
											</SubTitle>
										</Box>
									</Card>
									<Card
										sx={{
											...CardStyled,
											minHeight: '100px',
											width: '50%',
										}}
									>
										<Box
											style={{
												...BoxStyled,
												alignItems: 'unset',
											}}
										>
											<SubTitle>
												Status: <Text>{result?.status?.descricao}</Text>
											</SubTitle>
											<SubTitle>
												Mensagem: <Text>{result?.analiseCredito?.find((x) => x)
													?.rating?.descricao}</Text>
											</SubTitle>
										</Box>
									</Card>
								</Box>
								<div style={{ display: 'flex' }}>
									<Card
										sx={{
											...CardStyled,
											minHeight: '220px',
											width: '80%',
										}}
									>
										<Box sx={{ ...BoxStyled, minWidth: '40%' }}>
											<ProgressCircle
												value={
													(result?.analiseCredito?.find((x) => x)
														?.scoreCalculado || 0)
												}
												size={'60%'}
												title={`${result?.analiseCredito?.find((x) => x)
													?.scoreCalculado || 'N/A'
													} de 100`}
												color={defineColor(
													result?.analiseCredito?.find((x) => x)?.scoreCalculado,
												)}
												children={
													<Box>
														<Text
															style={{ fontSize: '20px', display: 'block' }}
														>
															{
																result?.analiseCredito?.find((x) => x)
																	?.scoreCalculado
															}
														</Text>
														<Text style={{ fontSize: '14px' }}>de 100</Text>
													</Box>
												}
											></ProgressCircle>
										</Box>
										<Box style={{ ...BoxStyled, alignItems: 'unset' }}>
											<Title>Risco de Crédito</Title>
											<SubTitle>{textoRiscoCredito()}</SubTitle>
											<Text>{result?.analiseCredito?.find((x) => x)
												?.rating?.identificador}</Text>
											<Text>Dados coletados a partir do {tipoDocumentoBusca(result)} informado.</Text>
										</Box>
									</Card>

									<Card
										sx={{
											...CardStyled,
											minHeight: '220px',
											width: '50%',
											display: 'flex',
											justifyContent: 'center',
										}}
									>
										<Box style={{ ...BoxStyled, alignItems: 'unset' }}>
											<SubTitle>Limite Sugerido</SubTitle>
											<Text>
												R$
												<TextNumber>
													{result?.analiseCredito
														?.find((x) => x)
														?.limiteCalculado?.toLocaleString('pt-br', {
															minimumFractionDigits: 2,
														})}
												</TextNumber>
											</Text>
											{carregaItens && (
												<Button
													sx={{
														width: '10.4em',
														marginLeft: 'auto',
													}}
													color='secondary'
													variant='contained'
													startIcon={<RefreshIcon />}
													onClick={() => setModalOpen(true)}
												>
													Atualizar Limite
												</Button>
											)}
										</Box>
									</Card>
								</div>
							</div>
						</Box>
						<Box
							style={{
								display: 'flex',
								justifyContent: 'center',
							}}
						>
							<div style={{ width: '50%' }}>
								<div>
									<Box
										style={{
											...BoxStyled,
											flexDirection: 'unset',
										}}
									>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle>Pendências</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)
														?.qtdeInadimplencia || 0}
												</TextNumber>
											</Box>
										</Card>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle>Protestos</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)?.qtdeProtesto ||
														0}
												</TextNumber>
											</Box>
										</Card>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle style={{ textAlign: 'center' }}>
													Ações Judiciais
												</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)
														?.qtdeAcaoJudicial || 0}
												</TextNumber>
											</Box>
										</Card>
									</Box>
									<Box
										style={{
											...BoxStyled,
											flexDirection: 'unset',
										}}
									>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle style={{ textAlign: 'center' }}>
													Rec. Judiciais / Falências
												</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)
														?.qtdeFalenciaRecuperacaoJudicial || 0}
												</TextNumber>
											</Box>
										</Card>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle style={{ textAlign: 'center' }}>
													Cheques sem Fundo
												</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)
														?.qtdeChequeSemFundo || 0}
												</TextNumber>
											</Box>
										</Card>
										<Card
											sx={{
												...CardStyled,
												width: '30%',
												justifyContent: 'center',
											}}
										>
											<Box style={BoxStyled}>
												<SubTitle style={{ textAlign: 'center' }}>
													Pendências Trabalhistas
												</SubTitle>
												<TextNumber>
													{result.analisePorte
														?.find((x) => x)
														?.analiseRestritivo?.find((r) => r)?.qtdePendenciaTrabalhista ||
														0}
												</TextNumber>
											</Box>
										</Card>
									</Box>

									<Box>
										<Card
											sx={{
												...CardStyled,
												justifyContent: 'space-around',
												alignItems: 'unset',
												minHeight: '120px',
											}}
										>
											<Box
												style={{
													...BoxStyled,
													alignItems: 'unset',
													justifyContent: 'unset',
													width: '100%',
												}}
											>
												{/* <Row>
													<SubTitle>Faturamento Presumido Anual: </SubTitle>
													<TextNumber style={{ fontSize: '20px' }}>
														<Text> R$</Text>
														{prefixMath()}
													</TextNumber>
												</Row> */}
												<Row>
													<SubTitle>Porte: </SubTitle>
													<Text> {typePortText()} </Text>
												</Row>
											</Box>
										</Card>
									</Box>
									<Box>
										<Card
											sx={{
												...CardStyled,
												width: 'auto',
											}}
										>
											<Box
												style={{
													...BoxStyled,
													alignItems: 'unset',
													justifyContent: 'unset',
													width: '100%',
												}}
											>
												<SubTitle>Lista Suja: </SubTitle>
												<Box>
													<Row>
														<Text style={{ ...CellTitle, textAlign: 'start' }}>
															Problemas Contratuais:
														</Text>
														<Text>Não Consta</Text>
													</Row>
													<Row>
														<Text style={{ ...CellTitle, textAlign: 'start' }}>
															Trabalho Escravo:
														</Text>
														<Text>Não Consta</Text>
													</Row>
													<Row>
														<Text style={{ ...CellTitle, textAlign: 'start' }}>
															Pendências Trabalhistas:
														</Text>
														<Text>Não Consta</Text>
													</Row>
												</Box>
											</Box>
										</Card>
									</Box>
									<Box>
										<Card
											sx={{
												...CardStyled,
												width: 'auto',
											}}
										>
											<Box
												style={{
													...BoxStyled,
													alignItems: 'unset',
													justifyContent: 'unset',
													width: '100%',
												}}
											>
												<SubTitle>Mensagens: </SubTitle>
												<Box>
													{result.analiseMensagem?.map((item, index) => (
														<Row key={index}>
															<Text
																style={{ ...CellTitle, textAlign: 'start' }}
															>
																{item.mensagem}
															</Text>
														</Row>
													))}
												</Box>
											</Box>
										</Card>
									</Box>
								</div>
							</div>
							<div style={{ width: '50%' }}>
								<Box>
									<Card
										sx={{
											...CardStyled,
											width: 'auto',
											alignItems: 'unset',
											justifyContent: 'unset',
										}}
									>
										<Box
											style={{
												...BoxStyled,
												alignItems: 'unset',
												justifyContent: 'unset',
											}}
										>
											<SubTitle>Dados adicionais sobre o {tipoDocumentoBusca(result)}</SubTitle>
											<Box>
												<Row>
													<Text style={CellTitle}>{tipoNomeFantasia(result)}</Text>
													<CellDivider />
													<Text style={CellContent}>
														{result?.analiseCliente?.find((x) => x)?.cliente
															?.razao || ''}
													</Text>
												</Row>
												<Row>
													<Text style={CellTitle}>Situação Cadastral:</Text>
													<CellDivider />
													<Text style={CellContent}>
														{result?.analiseCliente?.find((x) => x)?.cliente
															?.ativo
															? 'Ativa'
															: 'Inativa'}
													</Text>
												</Row>
												<Row>
													<Text style={CellTitle}>{tipoFundacaoNascimento(result)}</Text>
													<CellDivider />
													<Text style={CellContent}>
														{moment(
															result?.analiseCliente?.find((x) => x)?.cliente
																?.dataFundacao,
														).format('DD/MM/YYYY')}
													</Text>
												</Row>
												{/* <Row>
													<Text style={CellTitle}>Natureza Jurídica:</Text>
													<CellDivider />
													<Text style={CellContent}>N/A</Text>
												</Row> */}
												<Row>
													<Text style={CellTitle}>Endereço:</Text>
													<CellDivider />
													<Text style={CellContent}>{montarEndereco()}</Text>
												</Row>
												<Row>
													<img
														src={mapsUrl}
														alt='Mapa com a localização da empresa'
														style={{ width: '100%', maxWidth: '880px' }}
													/>
												</Row>
												<Row>
													<Text style={CellTitle}>Telefone:</Text>
													<CellDivider />
													<Text style={CellContent}></Text>
												</Row>
												<Row>
													<Text style={CellTitle}>E-mail:</Text>
													<CellDivider />
													<Text
														style={{
															...CellContent,
															wordWrap: 'break-word',
															wordBreak: 'break-word',
														}}
													>
														{
															result?.analiseCliente?.find((x) => x)?.cliente
																?.email
														}
													</Text>
												</Row>
											</Box>
										</Box>
									</Card>
								</Box>
								<Box>

									{tipoDocumentoBusca(result).length == 3 ? (
										''
									) : (
										<Card
											sx={{
												...CardStyled,
												width: 'auto',
											}}
										>

											<Box
												style={{
													...BoxStyled,
													alignItems: 'unset',
													justifyContent: 'unset',
													width: '100%',
												}}

											>
												<SubTitle>
													Informações Societárias e Administrativas
												</SubTitle>
												<Box
													style={{
														minHeight: '160px',
													}}
												>

													<Row style={{ margin: '3% 0 0 0' }}>
														<SubTitle style={CellTitle}>
															Número de Sócios:
														</SubTitle>
														<CellDivider />
														<TextNumber
															style={{ ...CellContent, fontSize: '20px' }}
														>
															{result.analiseSociedade?.length}
														</TextNumber>
														<CellDivider />
														<Box
															style={{
																display: 'flex',
																justifyContent: 'space-around',
															}}
														>
															<Button
																color='primary'
																variant='text'
																onClick={() => handleOpen('Socios')}
															>
																Ver Mais
															</Button>
														</Box>
													</Row>
													<Row style={{ margin: 0 }}>
														<SubTitle style={CellTitle}>Capital Social:</SubTitle>
														<CellDivider />
														<Text style={{ ...CellContent, fontSize: '20px' }}>
															R$
															<TextNumber style={{ fontSize: '20px' }}>
																{result?.analiseDemonstrativo
																	?.find(
																		(x) =>
																			x.tipoParametro ===
																			TipoVariavelFinanceira.CapitalSocial,
																	)
																	?.valor?.toLocaleString('pt-br', {
																		minimumFractionDigits: 2,
																	})}
															</TextNumber>
														</Text>
													</Row>
												</Box>

											</Box>
										</Card>)}
									<Box>
										<Card
											sx={{
												...CardStyled,
												width: 'auto',
											}}
										>
											<Box
												style={{
													...BoxStyled,
													alignItems: 'unset',
													justifyContent: 'unset',
													width: '100%',
												}}
											>
												<SubTitle>Mensagens: </SubTitle>
												<Box>
													{result.analiseMensagem?.map((item, index) => (
														<Row key={index}>
															<Text
																style={{ ...CellTitle, textAlign: 'start' }}
															>
																{item.mensagem}
															</Text>
														</Row>
													))}
												</Box>
											</Box>
										</Card>
									</Box>
								</Box>
							</div>
						</Box>
						<Title>Resultado de sua Pesquisa - {tipoDocumentoBusca(result)}</Title>
						<Box>
							<Box display={'flex'}>
								<div style={{ width: '30%' }}>
									<img src={analysis} alt='' />
								</div>

							</Box>
						</Box>
						<Modal
							open={modalState.open}
							onClose={handleClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'
							sx={{ minWidth: '60%' }}
							style={{ minWidth: '60%' }}
						>
							<Box sx={style}>
								<HeaderModal>
									<SubTitle>{modalState.title}</SubTitle>
									<IconButton onClick={handleClose}>
										<Close />
									</IconButton>
								</HeaderModal>
								<Box>{modalState.children}</Box>
							</Box>
						</Modal>

						<Modal
							open={modalOpen}
							onClose={handleClose}
							aria-labelledby='modal-modal-title'
							aria-describedby='modal-modal-description'
							sx={{ minWidth: '60%' }}
							style={{ minWidth: '60%' }}
						>
							<Box sx={style}>
								<HeaderModal>
									<SubTitle>Atualizar Limite</SubTitle>
									<IconButton onClick={handleClose}>
										<Close />
									</IconButton>
								</HeaderModal>
								<Box>
									<div>
										<TextField
											id="codigoEmpresa"
											label="Código da empresa"
											variant="outlined"
											style={InputStyled}
											value={formData.codigoEmpresa}
											onChange={handleChange}
											error={!!errors.codigoEmpresa}
											helperText={errors.codigoEmpresa}
										/>
										<FormControl variant="outlined" style={InputStyled} error={!!errors.tipoLimite}>
											<InputLabel id="tipoLimite-label">Tipo de Limite</InputLabel>
											<Select
												labelId="tipoLimite-label"
												id="tipoLimite"
												value={formData.tipoLimite}
												onChange={handleSelectChange}
												label="Tipo de Limite"
											>
												<MenuItem value="FAT">Faturado</MenuItem>
												<MenuItem value="CRT">Cartão</MenuItem>
												<MenuItem value="AMBOS">Ambos</MenuItem>
											</Select>
											{!!errors.tipoLimite && <FormHelperText>{errors.tipoLimite}</FormHelperText>}
										</FormControl>
										{formData.tipoLimite === 'AMBOS' ? (
											<div style={{ display: 'flex', gap: '10px' }}>
												<TextField
													id="valorFaturado"
													label="Valor Faturado"
													variant="outlined"
													style={InputStyled}
													value={formData.valorFaturado}
													onChange={handleChange}
													onInput={mascaraMoeda}
													error={!!errors.valorFaturado}
													helperText={errors.valorFaturado}
												/>
												<TextField
													id="valorCartao"
													label="Valor Cartão"
													variant="outlined"
													style={InputStyled}
													value={formData.valorCartao}
													onChange={handleChange}
													onInput={mascaraMoeda}
													error={!!errors.valorCartao}
													helperText={errors.valorCartao}
												/>
											</div>
										) : (
											<TextField
												id="valorLimite"
												label="Limite Sugerido"
												variant="outlined"
												style={InputStyled}
												value={formData.valorLimite}
												onChange={handleChange}
												onInput={mascaraMoeda}
												error={!!errors.valorLimite}
												helperText={errors.valorLimite}
											/>
										)}
										<ContainerTextArea>
											<InputLabel>Parecer Comercial</InputLabel>
											<TextareaAutosize
												maxRows={12}
												minRows={8}
												id="parecerComercial"
												name="parecerComercial"
												aria-label="maximum height"
												placeholder="Descrição"
												value={formData.parecerComercial}
												style={{ width: '100%' }}
												onChange={handleChange}
											/>
										</ContainerTextArea>
										<Button
											sx={{
												width: '10.4em',
												marginLeft: 'auto',
											}}
											color='secondary'
											variant='contained'
											startIcon={<SendIcon />}
											onClick={atualizarLimiteSugerido}
										>
											Enviar
										</Button>
									</div>
								</Box>
							</Box>
						</Modal>
					</>
				)}
			</Container>
		</>
	);
};
