import styled from "styled-components"

export const Container = styled.div`
    background: var(--bakground);
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export const Content = styled.div`
    background: var(--background-white);
    padding: 33px;
    border-radius: 10px;

`;