import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
`;

export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;

	.buttonPesquisa {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
	}
`;

export const Title = styled.h4`
	font-weight: 700;
	font-size: 31px;
	line-height: 36px;
	color: var(--grey);
	margin: 20px 10px;
`;

export const SubTitle = styled.span`
	line-height: 24px;
	letter-spacing: 0.5px;
	color: var(--text-body);
	display: inline-block;
	margin-bottom: 20px;
`;

export const Form = styled.div`
	width: 100%;
	display: flex;
`;

export const InputStyled: CSSProperties = {
	maxWidth: '360px',
	minWidth: '140px',
	marginRight: '15px',
};
export const PaginationStyled: CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
};
