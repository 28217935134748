import { HeaderSteps } from "../HeaderSteps"
import { Container, Content } from "./style"

export const ChooseYourPlan = () => {

    return (
        <>
            <Container>   
                <Content>        
                    <HeaderSteps />
                </Content>                 
            </Container>
        </>
    )
}