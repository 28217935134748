import moment from 'moment';
import { AnaliseDemonstrativo } from '../../../models/retornoAnalise';
import * as color from '../../../styles/colors';

export const filterVariableFinancial = (
	analise: AnaliseDemonstrativo[],
	tipo: number,
) => {
	const dataFiltered = analise.filter(
		(item: AnaliseDemonstrativo) => item.tipoVariavelFinanceira === tipo,
	);
	return dataFiltered.map((item) => ({
		key: moment(item.vigencia).format('YYYY'),
		value: item.valor || 0,
	}));
};

const liqCorrente = (value: number) => {
	if (value > 1.5) return color.green;
	if (value < 0.9) return color.red;
	return color.blue;
};
const liqImediata = (value: number) => {
	if (value > 0.25) return color.green;
	if (value < 0.15) return color.red;
	return color.blue;
};
const endivGeral = (value: number) => {
	if (value > 2.5) return color.green;
	if (value < 0.7) return color.red;
	return color.blue;
};
const endivFinanceiro = (value: number) => {
	if (value < 1) return color.green;
	if (value > 3) return color.red;
	return color.blue;
};
const dividaBancaria = (value: number) => {
	if (value < 1) return color.green;
	if (value > 3) return color.red;
	return color.blue;
};
const dividaLiquida = (value: number) => {
	if (value < 0.85) return color.green;
	if (value > 2.5) return color.red;
	return color.blue;
};
const coberturaDivida = (value: number) => {
	if (value > 4) return color.green;
	if (value < 1.5) return color.red;
	return color.blue;
};
const caixa = (value: number) => {
	if (value > 0.85) return color.green;
	if (value < 0.3) return color.red;
	return color.blue;
};
const roic = (value: number) => {
	if (value > 15) return color.green;
	if (value < 0) return color.red;
	return color.blue;
};
const lucratividade = (value: number) => {
	if (value > 12) return color.green;
	if (value > -3) return color.red;
	return color.blue;
};

export const selectColor = {
	liqCorrente,
	liqImediata,
	endivGeral,
	endivFinanceiro,
	lucratividade,
	caixa,
	coberturaDivida,
	dividaBancaria,
	dividaLiquida,
	roic,
};
