import styled from "styled-components";

export const Container = styled.div`
    width: 373px;
    height: 1024px;
    background: #FFFFFF;
    border: 2px solid #001064;
    box-shadow: 0px 8px 4px rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 68px;
    margin-left: 26px;

    .titleCard {
        margin-top: 26px;
        margin-bottom: 9px;
    }
    .cardSubTitle {
        margin-bottom: 14px;
    }
    .lineBlue {
        background: var(--blue-light);
        transform: matrix(1, 0, 0, -1, 0, 0);
        width: 162px;
        height: 2px;
    }

    .lineBlueLarge {
        background: var(--blue-light);
        transform: matrix(1, 0, 0, -1, 0, 0);
        width: 294px;
        height: 2px;
    }
    .cardDescription {
        max-width: 268px;
        text-align: center;
        margin-top: 8px;
        margin-bottom: 70px;
    }

    .containerItem {
        margin-bottom: 85px;
    }
    
    .itemContainer {
        
        display: flex;
        margin-bottom: 17px;
        div {
            max-width: 250px;
        }
        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.5px;
            color: var(--black);
            
        }
    }
    .contentContainerFooter {
        max-width: 305px;
    }
    .itemContainer img {
        margin-right: 13px;
    }
    .firstCard {
        margin-top: 107px;
    }

    .lastCard {
        margin-top: 243px;
    }
    .titleItems {
        text-align: center;
        
        font-size: 16px;
        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            text-align: center;
            letter-spacing: 0.15px;
            color: var(--very-dark-blue);
            
        }
        
    }

    .itemContainerFooter {
        img {
            margin-right: 13px;
        }
        div {
            max-width: 243px;
        }

        span {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            letter-spacing: 0.15px;
            color: var(--black);
        }
    }
    .containerIteFooter {
        margin-bottom: 2px;
    }
    .itemContainerFooter {
        display: flex;
        margin-bottom: 22px;
        margin-right: 80px;
    }
    .containerOptional {
        position: relative;
        bottom: 70px;
        img {
            margin-right: 7px;
        }

        p {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.25px;
            color: var(--text-body);
            position: relative;
            left: 7px;
            margin-bottom: 9px;
        }
    }
    .textFotterMoney {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.5px;
        color: var(--very-dark-gray);
    }

    .containerFooterMoney {
        margin-top: 11px;
        margin-right: 133px;
    }

    .money {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 56px;
        text-transform: uppercase;
        color: var(--dark-blue)
    }
    .btn-to-hire {
        background: var(--blue-light);
        border-radius: 10px;
        min-width: 195px;
        height: 47px;

    }

    div span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.15px;
        color: var(--very-dark-grayish-cyan);
    }

    div {


        h4 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 31px;
            line-height: 36px;
            text-align: center;
            color: var(--very-dark-blue);
        }

        h6 {
            font-family: 'Roboto';
            font-style: normal;
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            align-items: center;
            text-align: center;
            letter-spacing: 0.15px;
            color: var(--very-dark-blue);
            
        }
    }
`;