import React from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

interface ErrorModalProps {
  open: boolean;
  handleClose: () => void;
  errorMsg: string;
  title: string;
  onExit: () => void;
  continuarAnalise: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ open, handleClose, errorMsg, title, onExit, continuarAnalise }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="error-modal-title"
      aria-describedby="error-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          maxWidth: '700px',
          bgcolor: 'background.paper',
          borderRadius: '15px',
          p: 4,
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h2 id="error-modal-title">{title}</h2>
        <div
          id="error-modal-description"
          style={{
            textAlign: 'center',
            marginBottom: '16px', // Add margin to create space between message and buttons
            marginTop: '20px'
          }}
        >
          <p>{errorMsg}</p>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Button onClick={() => {
            handleClose();
            continuarAnalise();
            }} variant="contained" color="primary">
            Continuar
          </Button>
          <Button onClick={() => {
            handleClose();
            onExit(); // Call the onExit callback when the button is clicked
            }} variant="contained" color="secondary">
            Sair
          </Button>
        </div>
      </Box>
    </Modal>
  );
};

export default ErrorModal;
