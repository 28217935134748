import { useEffect, useState } from "react";
import {
	AddNewFile,
	Container,
	Content,
	Form,
	FormField,
	Load,
	Text,
	Title,
	Input,
	InputStyled,
	HeaderModal,
	SubTitle,
	LoadFile,
	InputGrid,
} from './style';
import {
	Card,
	CardContent,
	Box,
	Button as ButtonUi,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	InputLabel,
	Select,
	SelectChangeEvent,
	MenuItem,
	FormHelperText,
	FormControl,
	IconButton,
} from "@mui/material";
import loadingArrow from '../../assets/arrowLoading.gif';
import icoAdd from '../../assets/add.svg';
import { UploadFile } from '@mui/icons-material';
import { Header } from "../Header";
import RefreshIcon from '@mui/icons-material/Refresh';
import { apiOperation, headers } from '../../services/api';
import { Button } from "../Button";
import moment from "moment";
import { cnpjMask, cnpjOrCpfMask } from "../../utils/mask";
import { Loading } from "../Loading";

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '60%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

interface HiddenItensList {
	id: string;
	valor: string
};

interface IArquivoGenerico {
	id: number;
	load?: boolean;
	name?: string | null;
	tipoDocumento: string;
};

interface atualizarLimiteCredito {
	analiseLimiteCreditoId: string;
	valorLimite: number;
	tipoLimite: string;
	valorLimiteFaturado?: number;
	valorLimiteCartao?: number;
	descricao?: string;
	percentualAumento: number;
};

interface ResponseInformacoesAnalise {
	statusCode: number;
	erro: boolean;
	erros: string[];
	valor: InformacoesAnalise[];
};

interface InformacoesAnalise {
	analiseLimiteCreditoId: string;
	clienteId: number;
	clienteNome: string;
	cnpj: string;
	limiteTotal: number;
	limiteConsumido: number;
	valorLimiteFaturado: number;
	valorLimiteCartao: number;
	percentualLimite: number;
	dataAtualizacao: string;
	statusAtualizacao: number;
	tipoLimite: string;
	analiseLimiteArquivoId: string;
	descricao: string;
	limiteDisponivel: number;
	dataUltimaAnalise: string;
	score: number;
	valorLimite: number
};

export const AnaliseBRT = () => {
	const [open, setOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [titleError, setTitleError] = useState<string>('');
	const [loadingModal, setLoadingModal] = useState<boolean>(false);
	const [hasLoadDocumento, setHasLoadDocumento] = useState<boolean>(false);
	const [showLoading, setShowLoading] = useState(true);
	const [hasError, setHasError] = useState<boolean>(true);
	const [informacoesAnalise, setInformacoesAnalise] = useState<InformacoesAnalise[]>([]);
	const [responseInformacoes, setResponseInformacoesAnalise] = useState<ResponseInformacoesAnalise[]>([]);
	const [listNewUpload, setListNewUpload] = useState<IArquivoGenerico[]>([]);
	const [listHiddenItens, setListHiddenItens] = useState<HiddenItensList[]>([]);
	const [analiseLimiteId, setAnaliseLimiteId] = useState<string>('');
	const [modalState, setModalState] = useState({
		open: false,
		title: '',
		children: <></>,
	});
	const [modalOpen, setModalOpen] = useState(false);
	const [atualizarLimiteCredito, setAtualizarLimiteCredito] = useState<atualizarLimiteCredito>({
		analiseLimiteCreditoId: '',
		valorLimite: 0,
		tipoLimite: 'FAT',
		valorLimiteFaturado: 0,
		valorLimiteCartao: 0,
		descricao: '',
		percentualAumento: 0,
	});
	const [errors, setErrors] = useState<Partial<atualizarLimiteCredito>>({});

	const serviceFile = async (file: File, controler: any, tipoDocumento: string) => {
		const formBody = new FormData();
		formBody.append('arquivo', file, file.name);

		controler(true);
		try {
			setShowLoading(true);

			const config = {
				headers: headers()
			}

			const response = await apiOperation.post(
				`/analise/limitecredito/upload?tipoDocumento=${tipoDocumento}`,
				formBody, config
			);

			const { data } = response;

			if (!data.erro) {
				setHasError(false);
				setLoadingModal(false);
				controler(false, file.name);

				const minhaAncora = document.getElementById('minhaAncora');
				if (minhaAncora) {
					minhaAncora.scrollIntoView({ behavior: 'smooth' });
				}

				setShowLoading(false);

			} else {
				setOpen(true);
				setLoadingModal(false);
				const msgError = data.erros?.map((err: string) => err);
				setErrorMsg(msgError);
				setTitleError('Falha no Upload do Arquivo!');
			}

			setShowLoading(false);
		} catch (error) {
			setLoadingModal(false);
			const msgError = `Falha no upload do arquivo ${file.name}. \n Erro: ${error} `;
			setErrorMsg(msgError);
			setTitleError('Falha no Upload do Arquivo!');
			controler(false);
		}
		setShowLoading(false);
	};

	const mascaraMoeda = (event: any) => {
		const onlyDigits = event.target.value
			.split('')
			.filter((s: any) => /\d/.test(s))
			.join('')
			.padStart(3, '0');
		const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
		event.target.value = maskCurrency(digitsFloat);
	};

	const maskCurrency = (valor: any, locale = 'pt-BR', currency = 'BRL') => {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency,
		}).format(valor);
	};

	const mascaraPorcentagem = (event: any) => {
		const onlyDigits = event.target.value
			.split('')
			.filter((s: any) => /\d/.test(s))
			.join('')
			.padStart(3, '0');
		const digitsFloat = onlyDigits.slice(0, -2) + '.' + onlyDigits.slice(-2);
		event.target.value = maskPercent(digitsFloat);
	};

	const maskPercent = (valor: any, locale = 'pt-BR') => {
		return new Intl.NumberFormat(locale, {
			style: 'percent',
		}).format(valor);
	};

	const handleChangeValor = (index: number, value: string, type: string) => {
		const newData = [...informacoesAnalise];
		const valor = parseFloat(handleMoneyValue(value));
		if(type==="FAT"){
			newData[index].valorLimiteFaturado = valor;
		} else if (type==="CRT"){
			newData[index].valorLimiteCartao = valor;
		} else {
			newData[index].valorLimite = valor;
		}
		setInformacoesAnalise(newData);
	};

	const handleChange = (index: number, value: string) => {
		const newData = [...informacoesAnalise];
		newData[index].tipoLimite = value;
		setInformacoesAnalise(newData);
	};

	const handleSelectChange = (e: SelectChangeEvent<string>) => {
		setAtualizarLimiteCredito({
			...atualizarLimiteCredito,
			tipoLimite: e.target.value
		});
		setErrors({
			...errors,
			tipoLimite: ''
		});
	};

	const handleClose = () => {
		setModalOpen(false);
		setModalState({
			open: false,
			title: '',
			children: <></>,
		});
	};

	const handleNewUpload = () => {
		setListNewUpload([...listNewUpload, { id: listNewUpload.length, name: null, load: false, tipoDocumento: '' },
		]);
	};

	const handleSetArquivoGenericoLoad = (load: boolean, fileName: string) => {
		const _list = listNewUpload.map((arq) => {
			if (arq.name === fileName) {
				arq.load = load;
			}
			return arq;
		});
		setListNewUpload(_list);
	};

	const handleArquivoGenerico = async (target: any, id: number) => {
		if (!target.files) {
			return;
		};

		let tipoDocumento = '';

		const file = target.files[0];

		const { name } = file;
		const newList = listNewUpload.map((arq) => {
			if (arq.id === id) {
				arq.name = name;

				tipoDocumento = arq.tipoDocumento;
			}
			return arq;
		});

		setListNewUpload(newList);
		serviceFile(file, handleSetArquivoGenericoLoad, tipoDocumento);
	};

	const alterarLimiteCredito = async () => {
		try {
			setShowLoading(true);
			const config = {
				headers: headers()
			};

			const data = atualizarLimiteCredito.tipoLimite === 'AMB' ? {
				AnaliseLimiteCreditoId: analiseLimiteId,
				TipoLimite: handleMoneyValue(atualizarLimiteCredito.tipoLimite),
				ValorLimiteFaturado: handleMoneyValue(atualizarLimiteCredito.valorLimiteFaturado),
				ValorLimiteCartao: handleMoneyValue(atualizarLimiteCredito.valorLimiteCartao),
				Descricao: atualizarLimiteCredito.descricao,
				PercentualAumento: handlePercentValue(atualizarLimiteCredito.percentualAumento),
			} : {
				AnaliseLimiteCreditoId: analiseLimiteId,
				TipoLimite: handleMoneyValue(atualizarLimiteCredito.tipoLimite),
				ValorLimite: handleMoneyValue(atualizarLimiteCredito.valorLimite),
				Descricao: atualizarLimiteCredito.descricao,
				PercentualAumento: handlePercentValue(atualizarLimiteCredito.percentualAumento),
			};

			const response = await apiOperation.post('analise/limitecredito/atualizar', data, config);

			handleClose();
			setAtualizarLimiteCredito({
				analiseLimiteCreditoId: '',
				valorLimite: 0,
				tipoLimite: 'FAT',
				valorLimiteFaturado: 0,
				valorLimiteCartao: 0,
				descricao: '',
				percentualAumento: 0,
			});

			setShowLoading(false);
		}
		catch (error) {
			handleClose();
			setAtualizarLimiteCredito({
				analiseLimiteCreditoId: '',
				valorLimite: 0,
				tipoLimite: 'FAT',
				valorLimiteFaturado: 0,
				valorLimiteCartao: 0,
				descricao: '',
				percentualAumento: 0,
			});
			setShowLoading(false);
		}
	};

	const handleMoneyValue = (value: any) => {
		let money: any = 0;
		if (value) {
			money = value.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
		}
		else {
			money = '0.0'
		};
		return money;
	};

	const handlePercentValue = (value: any) => {
		let percent: any = '0%';
		if (typeof value === 'string') {
			percent = value.replace('%', '').replace(/\./g, '').replace(',', '.');
		};
		return percent;
	};

	// const handleChangeCheckBox = (index: number, event: React.ChangeEvent<HTMLInputElement>) => {
	// 	const newData = [...informacoesAnalise];
	// 	newData[index].statusAtualizacao = event.target.checked ? 5 : 0;
	// 	setInformacoesAnalise(newData);
	// };


	//const analiseId = async (params = "") => {
	// 	try {
	// 		const config = {
	// 			headers: headers()
	// 		};

	// 		setAnaliseLimiteId(params);
	// 		setModalOpen(true);

	// / 	} catch (error: any) {
	// 		console.error("Erro ao buscar informações:", error);
	// 	};
	// };


	const buscaInformacoes = async (params = "") => {
		try {
			const config = {
				headers: headers()
			};

			setShowLoading(true);

			let urlString = `/analise/limitecredito/obter`;

			urlString += params;

			const { data } = await apiOperation.post<ResponseInformacoesAnalise>(urlString, {}, config);

			if (data && data.valor) {
				setResponseInformacoesAnalise([data]);
				setInformacoesAnalise(data.valor);
			};

			setShowLoading(false);

		} catch (error: any) {
			console.error("Erro ao buscar informações:", error);
		};
		setShowLoading(false);
	};

	const processar = async () => {
		try {
			setShowLoading(true);
			setTimeout(() => {
				window.location.reload();
			}, 500);

			setShowLoading(false);
		} catch (error) {
			const msgError = `Erro: ${error} `;
			setErrorMsg(msgError);
			setHasError(true);
		};
		setShowLoading(false);
	};

	const analisar = async (params = "") => {
		try {
			const config = {
				headers: headers()
			};

			setShowLoading(true);
			const response = await apiOperation.post(
				`/analise/limitecredito/analisar?analiseCreditoId=${params}`, config);

			setShowLoading(false);
		} catch (error) {
			const msgError = `Erro: ${error} `;
			setErrorMsg(msgError);
			setHasError(true);
		};
		setShowLoading(false);
	};

	const finalizar = async () => {
		try {
			const config = {
				headers: headers()
			};

			setShowLoading(true);
			let urlString = `/analise/limitecredito/processar`;

			const { data } = await apiOperation.post(urlString, informacoesAnalise, config);

			setTimeout(() => {
				window.location.reload();
			}, 500);

			setShowLoading(false);
		} catch (error) {
			const msgError = `Erro: ${error} `;
			setErrorMsg(msgError);
			setHasError(true);
		};
		setShowLoading(false);
	};

	const mapearStatus: { [key: number]: string } = {
		4: "Em análise...",
	};

	useEffect(() => {
		buscaInformacoes();
	}, []);
	return (
		<>
			<Header />
			<Container>
				<Content>
					<Title>Análise Limite de Crédito</Title>
					<hr></hr>
				</Content>
				<Button className={"buttonStyle"}
					onClick={processar}
				>Processar</Button>
				<CardContent>
					{hasLoadDocumento && (
						<Load>
							<div>
								<img src={loadingArrow} alt='gif de carregamento' />
							</div>
							<div>
								<span>Enviando documento...</span>
							</div>
						</Load>
					)}
					{!hasLoadDocumento && (
						<AddNewFile>
							<div className='icone'
								onClick={handleNewUpload}>
								Adicionar Arquivo <img src={icoAdd} alt='Icone Adicionar' />
							</div>
						</AddNewFile>
					)}
					<Form>
						{listNewUpload?.map((arquivo) => (
							<FormField key={arquivo.id} >
								<label htmlFor={`contained-arquivo${arquivo.id}`}>
									<ButtonUi
										variant='outlined'
										component='div'
										endIcon={<UploadFile />}
										sx={{
											borderStyle: 'dashed',
											borderRadius: '15px',
										}}
									>
										{!arquivo.name && 'Anexe seu arquivo em .xlsx'}
										<Input
											accept='.xlsx'
											id={`contained-arquivo${arquivo.id}`}
											multiple
											type='file'
											onChange={({ target }) => {
												handleArquivoGenerico(target, arquivo.id);
											}}
										/>
										{arquivo.load ? (
											<LoadFile>
												<img
													style={{ width: '25px' }}
													src={loadingArrow}
													alt=''
												/>
											</LoadFile>
										) : (
											<Box>{arquivo.name}</Box>
										)}
									</ButtonUi>
								</label>
							</FormField>
						))}
					</Form>
				</CardContent>
				<Card sx={{ minWidth: 275 }}>
					{showLoading ? (
						<Loading hidden={!showLoading} />
					) : (
						<>
							<CardContent>
								<TableContainer>
									<Table>
										<TableHead>
											<TableRow>
												<TableCell align="left">CNPJ</TableCell>
												<TableCell align="left">Nome do Cliente</TableCell>
												<TableCell align="left">Limite Liberado</TableCell>
												<TableCell align="left">Limite Consumido</TableCell>
												<TableCell align="left">Limite Disponível</TableCell>
												<TableCell align="left">Última Análise e Score</TableCell>
												<TableCell align="left">Analisar</TableCell>
												<TableCell align="center">Tipo do Limite</TableCell>
												<TableCell align="center">Novo Limite</TableCell>
											</TableRow>
										</TableHead>
										<TableBody>
											{informacoesAnalise.map((item, index) => (
												<TableRow key={index}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 },
													}}
													hover>
													<TableCell component="th" scope="row">
														{cnpjMask(item.cnpj)}
													</TableCell>
													<TableCell component="th" scope="row">
														{item.clienteNome}
													</TableCell>
													<TableCell>
														{maskCurrency(item.limiteTotal)}
													</TableCell>
													<TableCell align="left">
														Valor: {maskCurrency(item.limiteConsumido)}<br></br>
														Percentual: {item.percentualLimite}%
													</TableCell>
													<TableCell align="left">
														{maskCurrency(item.limiteDisponivel)}
													</TableCell>
													<TableCell align="left">
														Data: {moment(item.dataUltimaAnalise).format("DD/MM/YYYY")} <br></br>
														Score: {item.score}
													</TableCell>
													<TableCell align="left">
														{item.statusAtualizacao === 0 || item.statusAtualizacao === 1 ? (
															<Button className={"buttonStyle"}
																startIcon={<RefreshIcon />}
																onClick={() => {
																	analisar(item.analiseLimiteCreditoId);
																	setTimeout(() => {
																		window.location.reload();
																	}, 500);
																}}
															>Enviar</Button>)
															: (
																<div
																	style={{
																		backgroundColor: "orange",
																		borderRadius: "15px",
																		padding: "10px",
																		color: "white",
																		textAlign: "center",
																		width: "13em",
																		height: "3em",
																	}} >
																	{mapearStatus[item.statusAtualizacao]}</div>)}
													</TableCell>
													<TableCell align="center" >
														{item.statusAtualizacao === 4 ? "-" :
														<FormControl variant="outlined" style={InputGrid} error={!!errors.tipoLimite}>
															<Select
																labelId="tipoLimite-label"
																id="tipoLimite"
																value={item.tipoLimite}
																onChange={(e) => handleChange(index, e.target.value as string)}
															>
																<MenuItem value="ND" >Selecione</MenuItem>
																<MenuItem value="FAT">Faturado</MenuItem>
																<MenuItem value="CRT">Cartão</MenuItem>
																<MenuItem value="AMB">Ambos</MenuItem>
															</Select>
															{!!errors.tipoLimite && <FormHelperText>{errors.tipoLimite}</FormHelperText>}
														</FormControl>
														}
													</TableCell>
													<TableCell align="center">
														{item.statusAtualizacao === 4 ? "-" : item.tipoLimite === "ND" ? "-" : item.tipoLimite === "AMB" ? (
															<div style={{ display: 'flex', marginBottom: '46px' }}>
																<TextField
																	id="FAT"
																	label="Valor Faturado"
																	variant="outlined"
																	style={{ width: '50%', marginRight: '5px', height: '10px' }}
																	//value={item.valorLimiteFaturado}
																	onChange= {(e) => handleChangeValor(index, e.target.value as string, "FAT")}//{handleChangeValor}
																	onInput={mascaraMoeda}
																	error={!!errors.valorLimiteFaturado}
																	helperText={errors.valorLimiteFaturado}
																/>
																<TextField
																	id="CRT"
																	label="Valor Cartão"
																	variant="outlined"
																	style={{ width: '50%', height: '10px' }}
																	//value={item.valorLimiteCartao}
																	onChange={(e) => handleChangeValor(index, e.target.value as string, "CRT")}
																	onInput={mascaraMoeda}
																	error={!!errors.valorLimiteCartao}
																	helperText={errors.valorLimiteCartao}
																/>
															</div>
														) : (
															<TextField
																id="valorLimite"
																label="Valor Limite"
																variant="outlined"
																style={{ width: '70%' }}
																//value={item.limiteTotal}
																onChange={(e) => handleChangeValor(index, e.target.value as string, "GERAL")}
																onInput={mascaraMoeda}
																error={!!errors.valorLimite}
																helperText={errors.valorLimite}
															/>
														)}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
								</TableContainer>
							</CardContent>
						</>
					)}
				</Card>
				<Button className={"buttonFinalizar"}
					onClick={finalizar}
				> Finalizar</Button>
			</Container>
		</>
	)
};