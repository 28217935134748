import { useNavigate } from 'react-router-dom';
import { Header } from '../Header';
import {
	Container,
	Content,
	Form,
	InputStyled,
	PaginationStyled,
	SubTitle,
	Title,
} from './Style';
import {
	Button,
	Card,
	CardContent,
	IconButton,
	MenuItem,
	Pagination,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { Visibility, Download, Search, RestartAlt } from '@mui/icons-material';
import { useState } from 'react';
import { months } from '../../data/months';
const rows = [
	{
		id: 1,
		nomeEmpresa: 'Dias & Oliveira Advogados Associados',
		cnpj: '017.846.119/0001-65',
		solicitante: 'Soraia Garcia',
		dataConsulta: '20.03.2021 - 12:12:50',
	},

];

export const BusinessBlockList = () => {
	const [page, setPage] = useState(1);
	const [form, setForm] = useState({
		nomeCliente: '',
		cnpj: '',
		mes: '',
		ano: '',
	});
	const [error, setError] = useState({
		nomeCliente: '',
		cnpj: '',
		mes: '',
		ano: '',
	});

	const handleChange = (event: any) => {
		setForm({ ...form, [event.target.name]: event.target.value });
	};
	const clearForm = () => {
		setForm({ nomeCliente: '', cnpj: '', mes: '', ano: '' });
		setError({ nomeCliente: '', cnpj: '', mes: '', ano: '' });
	};

	const onSearch = () => {
		if (!form.mes && form.ano) {
			setError({ ...error, mes: 'Informe o mês.' });
		} else if (form.mes && !form.ano) {
			setError({ ...error, ano: 'Informe o ano.' });
		}
	};
	let navigate = useNavigate();
	return (
		<>
			<Header />
			<Container>
				<Content>
					<Title>Lista de Bloqueio de Empresas</Title>
					<Button 
						className={'buttonPesquisa'}
						onClick={()=> navigate('/novo-bloqueio-de-cnpj')}	
					>Adicionar novo</Button>
				</Content>
				<Card sx={{ minWidth: 275 }}>
					<CardContent>
						<TableContainer>
							<Table
								sx={{ minWidth: 650 }}
								size='small'
								aria-label='a dense table'
							>
								<TableHead>
									<TableRow>
										<TableCell>#</TableCell>
										<TableCell align='left'>Nome da Empresa</TableCell>
										<TableCell align='left'>CNPJ</TableCell>
										<TableCell align='left'>Solicitante</TableCell>
										<TableCell align='center'>Data do Bloqueio</TableCell>
										<TableCell align='center'></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{rows.map((row) => (
										<TableRow
											key={row.id}
											sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
											hover
											onClick={(event) => {/*console.log(event, row)*/}}
										>
                                            <TableCell align='left'>{row.id}</TableCell>
											<TableCell component='th' scope='row'>
												{row.nomeEmpresa}
											</TableCell>
											<TableCell align='left'>{row.cnpj}</TableCell>
											<TableCell align='left'>{row.solicitante}</TableCell>
											<TableCell align='center'>{row.dataConsulta}</TableCell>
											<TableCell align='center'></TableCell>
											<TableCell align='center'>
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
							<Pagination
								sx={PaginationStyled}
								count={10}
								page={page}
								onChange={(event, value) => setPage(value)}
							/>
						</TableContainer>
					</CardContent>
				</Card>
			</Container>
		</>
	);
};
