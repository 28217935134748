import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { cnpjOrCpfMask } from '../../../utils/mask';
import { IPropsPartners } from './IPros';

export const TablePartners = (props: IPropsPartners) => {
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Nome</TableCell>
							<TableCell>CPF/CNPJ</TableCell>
							<TableCell>Assinante</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Debito</TableCell>
							<TableCell>Fraude</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.listPartners.map((item, index) => (
							<>
								<TableRow hover key={index}>
									<TableCell>{item.razao}</TableCell>
									<TableCell>{cnpjOrCpfMask(item.cnpjOuCpf)}</TableCell>
									<TableCell>
										{item.assinaEmpresa === 'S' ? 'Sim' : 'Não'}
									</TableCell>
									<TableCell>{item.situacaoDocumento}</TableCell>
									<TableCell>{item.indicadorDebito}</TableCell>
									<TableCell>{item.indicadorFraude}</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
