import { Download } from '@mui/icons-material';
import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	IconButton,
} from '@mui/material';
import moment from 'moment';
import { IPropsDocumentation } from './IPros';

export const TableDocuments = (props: IPropsDocumentation) => {
	const onDownload = (url: string) => {
		const link = document.createElement('a');
		link.download = url;
		link.href = url;
		link.click();
	};

	const formatName = (url: string) => {
		const listSplit = url.split('/');
		return listSplit.pop();
	};
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: 650 }} size='small'>
					<TableHead>
						<TableRow>
							<TableCell>Nome</TableCell>
							<TableCell>Data De Inclusão</TableCell>
							<TableCell>Download</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.listDocument.map((item, index) => (
							<>
								<TableRow hover key={index}>
									<TableCell>{formatName(item.url || '')}</TableCell>
									<TableCell>
										{moment(item.dataInclusao).format('DD/MM/YYYY')}
									</TableCell>
									<TableCell>
										<IconButton
											onClick={() => onDownload(item.url || '')}
											color='primary'
											aria-label='Download Documento'
										>
											<Download />
										</IconButton>
									</TableCell>
								</TableRow>
							</>
						))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
