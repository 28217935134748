export const colorsProgress = [
	{
		key: 90,
		value: '#367D09',
	},
	{
		key: 80,
		value: '#58950C',
	},
	{
		key: 70,
		value: '#80AC10',
	},
	{
		key: 60,
		value: '#AFC314',
	},
	{
		key: 50,
		value: '#DAD018',
	},
	{
		key: 40,
		value: '#F0C21D',
	},
	{
		key: 30,
		value: '#F39A22',
	},
	{
		key: 20,
		value: '#F67227',
	},
	{
		key: 10,
		value: '#F94C2C',
	},
	{
		key: 0,
		value: '#FB313C',
	},
];
