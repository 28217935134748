import styled from 'styled-components';

export const Container = styled.header`
	background: var(--background-white);

	MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper:before::root {
		right: 35px !important;
	}
`;

export const Content = styled.div`
	display: flex;
	flex: 1;
	height: 80px;
	justify-content: space-around;

	div {
		display: flex;
		flex: 0.2;
		align-items: center;

		a {
			margin-right: 16px;
			font-family: 'Roboto', sans-serif;
			font-weight: 400;
			letter-spacing: 0.5px;
			text-decoration: none;
			color: var(--text-link);
		}

		.cadastrar {
			background: var(--blue-light);
			width: 10.4em;
			height: 2.4em;
			border: none;
			margin-right: 30px;
		}

		.login {
			background: var(--shape);
			width: 7.2em;
			height: 2.4em;
			border: 2px solid #84ffff;
		}
	}
`;

export const UserContainer = styled.div`
	div {
		flex: none;
	}
	p {
		line-height: 16px;
		display: flex;
		align-items: center;
		letter-spacing: 0.5px;
		text-transform: capitalize;
		inline-size: max-content;
		margin-right: -2px;
		margin-left: 8px;
	}
`;

export const Bar = styled.hr`
	transform: rotate(90deg);
	width: 20%;
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #001064;
	margin: 2px 0;
	padding: 0;
`;

export const Text = styled.span`
	font-weight: 700;
	line-height: 16px;
	display: flex;
	text-align: left;
	letter-spacing: 0.5px;
	text-transform: capitalize;
	color: var(--text-subtitle);
	margin: 0px 10px;
`;

export const Logo = styled.img`
	cursor: pointer;
`;
