import axios from 'axios';
import { getSessionToken } from '../utils/users';
const REACT_APP_OPERACAO_BASE_URL = process.env.REACT_APP_OPERACAO_BASE_URL;
const REACT_APP_IDENTIDADE_BASE_URL = process.env.REACT_APP_IDENTIDADE_BASE_URL;
const REACT_APP_ADMIN_BASE_URL = process.env.REACT_APP_ADMIN_BASE_URL;
const REACT_APP_EXTRATOR_BASE_URL = process.env.REACT_APP_EXTRATOR_BASE_URL;

export const headers = () => {
	return {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
		'Authorization': `Bearer ${getSessionToken()}`,
	};
}

export const apiOperation = axios.create({
	baseURL: REACT_APP_OPERACAO_BASE_URL,
	withCredentials: false,
});

export const apiIdentidade = axios.create({
	baseURL: REACT_APP_IDENTIDADE_BASE_URL,
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	},
});

export const apiAutenticacao = axios.create({
	baseURL: REACT_APP_ADMIN_BASE_URL,
	withCredentials: false,
	headers: {
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
	},
});

export const apiAdmin = axios.create({
	baseURL: REACT_APP_ADMIN_BASE_URL,
	withCredentials: false,
})

export const apiExtractor = axios.create({
	baseURL: REACT_APP_EXTRATOR_BASE_URL,
	withCredentials: false,
});

export const apiMaps = axios.create({
	baseURL: 'https://maps.googleapis.com/maps/api/',
});
