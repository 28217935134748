import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Avatar, Button as ButtonMui, Divider, ListItemIcon, ListItemText, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ManageAccounts, SupervisorAccount, Block, Settings, Logout } from '@mui/icons-material';
import { getSessionUser, clearSessionUser, clearSessionToken } from '../../utils/users';
import { IUser } from '../Login/ILogin';
import { Empresas } from "../../utils/empresa";
import { Bar, Container, Content, Logo, UserContainer, Text } from './style';
import logoPng from '../../assets/logo.png';
import { Button } from '../Button';
import { useUnsavedChanges } from '../../data/analiseContext';

let user: IUser | null = null;

export const Header: React.FC = () => {
  const [userLogged, setUserLogged] = useState<boolean>(false);
  const { hasUnsavedChanges } = useUnsavedChanges();

  useEffect(() => {
    user = getSessionUser();
    if (user) setUserLogged(true);
  }, []);

  return (
    <Container>
      <Content>{userLogged ? <Logged hasUnsavedChanges={hasUnsavedChanges} /> : <NotLogged hasUnsavedChanges={hasUnsavedChanges} />}</Content>
    </Container>
  );
};

interface NavigationProps {
  hasUnsavedChanges: boolean;
}

const NotLogged: React.FC<NavigationProps> = ({ hasUnsavedChanges }) => {
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const handleLogoClick = () => {
    debugger;
    if (hasUnsavedChanges && !currentLocation.pathname.includes('/resultados/')) {
      const confirmLeave = window.confirm('É preciso clicar em analisar no fim da pagina para enviar o cnpj para análise, Caso saia agora os dados serão perdidos. Deseja sair?');
      if (!confirmLeave) return;
    }
    navigate('/');
  };

  return (
    <>
      <div>
        <Logo onClick={handleLogoClick} src={logoPng} alt="Logo da CreditOn" />
      </div>
      <div>
        <Button className='login' onClick={() => navigate('/login')}>
          Login
        </Button>
      </div>
    </>
  );
};

const Logged: React.FC<NavigationProps> = ({ hasUnsavedChanges }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [carregaItensBrf, setCarregaItensBrf] = useState(false);
  const [userLogged, setUserLogged] = useState<boolean>(false);
  const [carregaItensBrt, setCarregaItensBrt] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    user = getSessionUser();
    if (user) setUserLogged(true);

		if (user?.empresa === Empresas.Brf) {
			setCarregaItensBrf(true)
		}
		if (user?.empresa === Empresas.BRT) {
			setCarregaItensBrt(true)
		}
	}, []);

  const handleLogoClick = () => {
    debugger
    if (hasUnsavedChanges && !currentLocation.pathname.includes('/resultados/')) {
      const confirmLeave = window.confirm('É preciso clicar em analisar no fim da pagina para enviar o cnpj para análise, Caso saia agora os dados serão perdidos. Deseja sair?');
      if (!confirmLeave) return;
    }
    navigate('/historico');
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function stringToColor(string: string) {
    let color = '#';
    let hash = 0;
    for (let i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
    for (let i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    return color;
  }

  function stringAvatar(name: string) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name[0]}`,
    };
  }

  const logoutUser = () => {
    clearSessionUser();
    clearSessionToken();
    navigate('/home');
  };

  const userProfile = user?.perfil;

	return (
    <>
      <div>
        <Logo onClick={handleLogoClick} src={logoPng} />
      </div>
      <UserContainer>
        <div>
          <Tooltip title='Configurações da Conta'>
            <ButtonMui
              onClick={handleClick}
              size='small'
              sx={{ minWidth: '240px' }}
              aria-controls={open ? 'account-menu' : undefined}
              aria-haspopup='true'
              aria-expanded={open ? 'true' : undefined}
              endIcon={<KeyboardArrowDownIcon />}
            >
              <Avatar {...stringAvatar(user?.nomeUsuario || '')} />
              <Typography>{user?.nomeUsuario}</Typography>
            </ButtonMui>
          </Tooltip>
          <Bar />
          <Text style={{ fontSize: '24px', display: 'none' }}>15</Text>
          <Text style={{ display: 'none' }}>Consultas Disponíveis</Text>
        </div>
        <Menu
          anchorEl={anchorEl}
          id='account-menu'
          open={open}
          onClose={handleClose}
          onClick={handleClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 0,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '&:before': {
                display: 'none',
              },
            },
          }}
          transformOrigin={{ horizontal: 'right', vertical: 'top' }}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
          <MenuItem>
            <ListItemIcon>
              <ManageAccounts fontSize='small' />
            </ListItemIcon>
            <ListItemText onClick={() => navigate('/perfil')}>
              Minha Conta
            </ListItemText>
          </MenuItem>
          {userProfile === 'Admin' && <Divider />}
          {userProfile === 'Admin' && (
            <MenuItem>
              <ListItemIcon>
                <SupervisorAccount fontSize='small' />
              </ListItemIcon>
              <ListItemText onClick={() => navigate('/usuarios')}>
                Usuários
              </ListItemText>
            </MenuItem>
          )}
          {userProfile === 'Admin' && <Divider />}
          {userProfile === 'Admin' && carregaItensBrf && (
            <MenuItem>
              <ListItemIcon>
                <SupervisorAccount fontSize='small' />
              </ListItemIcon>
              <ListItemText onClick={() => navigate('/configuracao/Brf')}>
                Configurações
              </ListItemText>
            </MenuItem>
          )}
          <MenuItem style={{ display: 'none' }}>
            <ListItemIcon>
              <Block fontSize='small' />
            </ListItemIcon>
            <ListItemText onClick={() => navigate('/empresas-bloqueadas')}>
              Lista de bloqueio
            </ListItemText>
          </MenuItem>
          <MenuItem style={{ display: 'none' }}>
            <ListItemIcon>
              <Settings fontSize='small' />
            </ListItemIcon>
            <ListItemText onClick={() => navigate('/configuracao')}>
              Configurações
            </ListItemText>
          </MenuItem>
          { (userProfile === 'Admin' && carregaItensBrt) && (
						<MenuItem>
							<ListItemIcon>
								<Settings fontSize='small' />
							</ListItemIcon>
							<ListItemText
								onClick={() => {
									navigate('/analisebrt');
								}}
							>
								Análise de Crédito
							</ListItemText>
						</MenuItem>
					)}
          <Divider />
          <MenuItem>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            <ListItemText onClick={logoutUser}>Sair</ListItemText>
          </MenuItem>
        </Menu>
      </UserContainer>
    </>
  );
};
