export enum TipoPorte {
	Indefinido = 0,
	Small = 1,
	SmallPlus = 2,
	Middle = 3,
	MiddlePlus = 4,
	Corporate = 5,
	CorporatePlus = 6,
	MEI = 7,
	ME = 8,
	EPP = 9,
	Demais1 = 10,
	Demais2 = 11,
	Demais3 = 12,
}
