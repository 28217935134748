import { TextField } from '@mui/material'
import { Container, Content } from './style'
import { useState } from 'react'
import { Header } from '../Header';


export const SettingsProfile = () => {

    const [cnpj, setCnpj] = useState<string>('');

    const handleChangeCnpj = ( {target}:any ) => {
        setCnpj (target.value);
    };

    return (
        <>
            <Header/>
            <Container>
                <Content>
                    <div>
                        <h2>Configurações</h2>
                    </div>
                    <div  className = "settingProfile">
                        <div>
                            <h3>Mensagem personalizada para CNPJ bloqueado:</h3>
                        </div>
                        <div>
                            <TextField 
                            fullWidth 
                            label="CNPJ"
                            value={cnpj}
                            onChange={handleChangeCnpj}></TextField>
                        </div>
                        <div>
                            <button type = "button" className="save">Salvar</button>
                            <button type = "button" className="back">voltar</button>
                        </div>
                    </div>
                </Content>
            </Container>
        </>
    )
}