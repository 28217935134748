import {useState} from 'react';
import { FirstTab } from '../../AllTabs/FirstTab';
import { SecondTab } from '../../AllTabs/SecondTab';
import { ICard } from '../../CardLarge/ICard';
import { Container } from "./style";

interface IProps {
    cards: ICard[]
}
export const Tabs = (props: IProps) => {
    const [activeTab, setActiveTab] = useState("tab1");

    const handleTab1 = () => {
        setActiveTab('tab1');
    };

    const handleTab2 = () => {
        setActiveTab('tab2');
    }

    return (
        <Container>
            <div className="Tabs">
                <ul className="nav">
                    <li 
                        className={activeTab === 'tab1' ? 'active' : ''}
                        onClick={handleTab1}
                    >
                        <div>
                            <div>
                                <h4 className={activeTab !== 'tab1' ? 'disable' : '' }>Análise de Crédito Padrão</h4>
                            </div>
                            <div>
                                <h6 className={activeTab !== 'tab1' ? 'disable' : '' }>Planos com as principais métricas</h6>
                            </div>
                        </div>
                    </li>
                    <li 
                        className={activeTab === 'tab2' ? 'active' : ''}
                        onClick={handleTab2}
                    >
                        <div>
                            <div>
                                <h4 className={activeTab !== 'tab2' ? 'disable' : '' }>Análise de Crédito Personalizada</h4>
                            </div>
                            <div>
                                <h6 className={activeTab !== 'tab2' ? 'disable' : '' }>Motor de análise seguindo as métricas da sua empresa</h6>
                            </div>
                        </div>
                    </li>
                </ul>

                <div className="outlet">
                    {activeTab === 'tab1' ? <FirstTab cards={props.cards}/> : <SecondTab/>}
                </div>
            </div>
        </Container>
    )
}