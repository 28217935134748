import { CSSProperties } from 'react';
import styled from 'styled-components';

export const Container = styled.div`
	padding: 0 6%;
`;
export const Content = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 20px 0;

	.buttonPesquisa {
		background: var(--blue-light);
		width: 15em;
		height: 4em;
	}
`;

export const Title = styled.h4`
	font-weight: 500;
	font-size: 31px;
	line-height: 36px;
	color: var(--text-subtitle);
`;

export const SubTitle = styled.span`
	display: inline-block;
	font-weight: 500;
	font-size: 20px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: var(--black);
`;

export const Text = styled.span`
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 0.15px;
	color: var(--text-body);
`;

export const TextNumber = styled.span`
	font-weight: 700;
	font-size: 48px;
	line-height: 36px;
	color: var(--text-link);
`;

export const Form = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
`;

export const HeaderModal = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
`;

export const InputStyled: CSSProperties = {
	minWidth: '140px',
	margin: '15px 0',
	padding: '10px',
	width: '100%', 
	fontSize: '14px',
	borderRadius: '10px',
};

export const CardStyled: CSSProperties = {
	margin: '16px',
	minWidth: '20%',
	padding: '16px',
	display: 'flex',
	borderRadius: '10px',
	minHeight: '14em',
	// justifyContent: 'center',
};

export const BoxStyled: CSSProperties = {
	display: 'flex',
	justifyContent: 'space-evenly',
	flexDirection: 'column',
	alignItems: 'center',
};

export const CellDivider = styled.div`
	display: table-cell;
	width: 5%;
`;

export const CellTitle: CSSProperties = {
	display: 'table-cell',
	width: '40%',
	textAlign: 'end',
	color: 'var(--black)',
};
export const CellContent: CSSProperties = {
	display: 'table-cell',
};

export const Row = styled.div`
	display: table;
	width: 100%;
	margin: 3% 0;
`;

export const ContainerGrid = styled.div `
	display: flex !important;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	div {
		margin-top: 33px;
	}
`;

export const ContainerForm = styled.div`

	display: flex;
	align-items: center;
	margin-bottom: 33px;
`;