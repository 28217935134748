import { TextField } from '@mui/material'
import { useState } from 'react'
import { Container, Content } from './Style'

export const  BusinessData = () => {
    
    const [cnpj, setCnpj] = useState<string>('');
    const [cep, setCep] = useState<string>('');
    const [rua, setRua] = useState<string>('');
    const [numero, setNumero] = useState<string>('');
    const [complemento, setComplemento] = useState<string>('');
    const [cidade, setCidade] = useState<string>('');
    const [estado, setEstado] = useState<string>('');

    const handleChangeCnpj = ( {target}:any ) => {
        setCnpj(target.value);
    };

    const handleChangeCep = ( {target}:any ) => {
        setCep(target.value);
    };

    const handleChangeRua = ( {target}:any ) => {
        setRua(target.value);
    };

    const handleChangeNumero = ( {target}:any ) => {
        setNumero(target.value);
    };

    const handleChangeComplemento = ( {target}:any ) => {
        setComplemento(target.value);
    };

    const handleChangeCidade = ( {target}:any ) => {
        setCidade(target.value);
    };

    

    const handleChangeEstado = ( {target}:any ) => {
        setEstado(target.value);
    }




    return (
        <>
            <Container>
                <Content>
                    <div className = "title">
                        <h6>
                         Agora, precisamos dos <span className = "colorSpanPack" > dados da sua empresa  </span> para concluir a criação da sua conta.
                        </h6>
                    </div>
                    <div className = "CNPJlink">
                         Não tem um CNPJ? Clique aqui e siga com a contração Pessoa Física
                    </div>
                    <div className = "registration">
                        <div>
                            <TextField 
                                fullWidth 
                                label="CNPJ"
                                value={cnpj}
                                onChange={handleChangeCnpj}
                            ></TextField>
                        </div>
                        <div className = "Double">
                            <TextField 
                                fullWidth 
                                label="CEP"
                                value={cep}
                                onChange={handleChangeCep}
                            ></TextField>
                            <div>
                                <a> Não sei o CEP </a>
                            </div>
                        </div>
                        <div>
                            <TextField 
                                fullWidth 
                                label="Rua"
                                value={rua}
                                onChange={handleChangeRua}
                            ></TextField>
                        </div>
                    </div>
                    <div className = "DoubleInput">
                        <div className = "num">
                            <TextField 
                                fullWidth 
                                label="Numero"
                                value={numero}
                                onChange={handleChangeNumero}
                            ></TextField>
                        </div>
                        <div className = "complement">
                            <TextField 
                                fullWidth 
                                label="Complemento"
                                value={complemento}    
                                onChange={handleChangeComplemento}
                            ></TextField>
                        </div>
                    </div>
                    <div className = "DoubleInput">
                        <div className = "city">
                            <TextField 
                                fullWidth 
                                label="Cidade"
                                value={cidade}
                                onChange={handleChangeCidade}    
                            ></TextField>
                        </div>
                        <div className = "state">
                            <TextField 
                                fullWidth 
                                label="Estado"
                                value={estado}
                                onChange={handleChangeEstado}    
                            ></TextField>
                        </div>
                    </div>
                </Content>
            </Container>
        </>
    )
}