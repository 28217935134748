import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material'
import { useState } from 'react';
import { Container, Content } from './style'

export const FormOfPayment = () => {

    const [paymentForm, setPaymentForm] = useState<string>('');
    const [numberCard, setNumberCard] = useState<string>('');
    const [fullName, setFullName] = useState<string>('');
    const [validateDate, setValidateDate] = useState<string>('');
    const [cvc, setCvc] = useState<string>('');

    const handleChangePaymentForm = ( {target}:any ) => {
        setPaymentForm(target.value);
    };

    const handleChangeNumberCard = ( {target}:any ) => {
        setNumberCard(target.value);
    };

    const handleChangeFullName = ( {target}:any ) => {
        setFullName(target.value);
    };

    const handleChangeValidateDate = ( {target}:any ) => {
        setValidateDate(target.value);
    };

    const handleChangeCvc = ( {target}:any ) => {
        setCvc(target.value);
    };

    
    return (
        <>
            <Container>
                <Content>
                    <div className = "title">
                        <h6> 
                            Escolha a forma de pagamento:
                        </h6>
                    </div>
                    <div className= "payment">
                    <RadioGroup
                        row
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="boleto"
                        value={paymentForm}
                        onChange={handleChangePaymentForm}
                        name="radio-buttons-group"
                    >
                        <FormControlLabel value="cartaoCredito" control={<Radio />} label="Cartão de Crédito" />
                        <FormControlLabel value="boleto" control={<Radio />} label="Boleto Bancário" />
                    </RadioGroup>
                    </div>
                    <div className = "paymentData">
                        <div>
                            <TextField 
                                fullWidth 
                                label="Numero do cartão"
                                value={numberCard}
                                onChange={handleChangeNumberCard}    
                            ></TextField>
                        </div>
                        <div>
                            <TextField 
                                fullWidth 
                                label="Nome completo do titular"
                                value={fullName}
                                onChange={handleChangeFullName}    
                            ></TextField>
                        </div>
                    </div>
                    <div className = "DoubleInput">
                        <div className = "campDate">
                            <TextField 
                                fullWidth 
                                label="Data de validade"
                                value={validateDate}
                                onChange={handleChangeValidateDate}    
                            ></TextField>
                        </div>
                        <div className = "campCVC">
                            <TextField 
                                fullWidth 
                                label="CVC"
                                value={cvc}
                                onChange={handleChangeCvc}
                            ></TextField>
                        </div>
                    </div>
                <div className="lineBlue">
                    <div ></div>
                </div>
                <div className = "request">
                    <p>Resumo do pedido: </p>
                </div>
                <div className='footerBaloon'>
                    <div className='firstContainer'>
                        <div>
                            Plano
                        </div>
                        <h6>
                            Análise Padrão Completo
                        </h6>
                    </div>
                    <div className='secondContainer'>
                        <div>
                            Plano completo. Análise baseada nas informações comportamentais e análise de documentos da empresa consultada.
                        </div>
                        <div style={{display: 'flex'}}>
                            Pacote mensal: &nbsp;<h6>Até 99 consultas</h6>
                        </div>
                    </div>
                </div>
                </Content>
            </Container>
        </>        
            )
            
}