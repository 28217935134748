import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import { useState, useEffect } from 'react';
import { TipoVariavelFinanceira } from '../../../models/enums/TipoVariavelFinanceiraEnum';
import { IPropsFinancial } from './IPros';
import { filterVariableFinancial, selectColor } from './functions';

export const TableProfitability = (props: IPropsFinancial) => {
	const [roic, setRoic] = useState<{ key: string; value: number }[]>([]);
	const [years, setYears] = useState<string[]>([]);

	const getRoic = () => {
		const dataFiltered = filterVariableFinancial(
			props.analiseDemonstrativo,
			TipoVariavelFinanceira.Roic,
		);
		if (dataFiltered) {
			setYears(dataFiltered.map((item) => item.key));
			setRoic(dataFiltered);
		}
	};
	useEffect(() => {
		getRoic();
	}, []);
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: props.minWidth ? props.minWidth : 650 }} size='small'>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: 'var(--very-dark-blue)',
								th: { color: '#ffff' },
							}}
						>
							<TableCell>Rentabilidade</TableCell>
							{years.map((year) => (
								<TableCell>{year}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow key={'ROIC-5'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Capital Investido(ROIC)
							</TableCell>
							{roic
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{
											color: selectColor.roic(Number(item.value)),
										}}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
						{/* <TableRow
							key={'Lucratividade'}
							// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Lucratividade
							</TableCell>
							<TableCell sx={{ color: selectColor.lucratividade(15) }}>
								15%
							</TableCell>
							<TableCell sx={{ color: selectColor.lucratividade(0) }}>
								0%
							</TableCell>
							<TableCell sx={{ color: selectColor.lucratividade(-5) }}>
								-5%
							</TableCell>
						</TableRow> */}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
