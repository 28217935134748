import { IUser } from '../components/Login/ILogin';

export const getSessionUser = (): IUser | null => {
	const session = sessionStorage.getItem('@user');
	if (session) {
		return JSON.parse(session);
	} else {
		return null;
	}
};

export const saveSessionUser = (_user: IUser) => {
	if (_user) sessionStorage.setItem('@user', JSON.stringify(_user));
};

export const clearSessionUser = () => {
	sessionStorage.removeItem('@user');
};

export const saveSessionToken = (token: string) => {
	if (token) sessionStorage.setItem('@token', token);
};

export const getSessionToken = (): string | null => {
	const token = sessionStorage.getItem('@token');
	if (token) {
		return token;
	} else {
		return null;
	}
};

export const clearSessionToken = () => {
	sessionStorage.removeItem('@token');
};

export const hasAdminUser = () => {
	return getSessionUser()?.perfil === 'Master' ? true : false;
};
