import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
} from '@mui/material';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { TipoVariavelFinanceira } from '../../../models/enums/TipoVariavelFinanceiraEnum';
import { AnaliseDemonstrativo } from '../../../models/retornoAnalise';
import { IPropsFinancial } from './IPros';
import { selectColor } from './functions';

export const TableLiquidity = (props: IPropsFinancial) => {
	const [liqCorrente, setLiqCorrente] = useState<
		{ key: string; value: number }[]
	>([]);
	const [years, setYears] = useState<string[]>([]);

	const getLiqCorrente = () => {
		const dataFiltered = props.analiseDemonstrativo.filter(
			(item: AnaliseDemonstrativo) =>
				item.tipoVariavelFinanceira === TipoVariavelFinanceira.LiquidezCorrente,
		);
		const x = dataFiltered.map((item) => ({
			key: moment(item.vigencia).format('YYYY'),
			value: item.valor || 0,
		}));
		if (x) {
			setYears(x.map((item) => item.key));
			setLiqCorrente(x);
		}
	};

	useEffect(() => {
		getLiqCorrente();
	}, []);
	return (
		<>
			<TableContainer component={Paper}>
				<Table sx={{ minWidth: props.minWidth ? props.minWidth : 650 }} size='small'>
					<TableHead>
						<TableRow
							sx={{
								backgroundColor: 'var(--very-dark-blue)',
								th: { color: '#ffff' },
								td: { color: 'red' },
							}}
						>
							<TableCell>Liquidez</TableCell>
							{years.map((year) => (
								<TableCell>{year}</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{/* <TableRow key={'liquidez'}>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Imediata
							</TableCell>
							<TableCell sx={{ color: selectColor.liqImediata(0.2) }}>
								0.2
							</TableCell>
							<TableCell sx={{ color: selectColor.liqImediata(0.1) }}>
								0.1
							</TableCell>
							<TableCell sx={{ color: selectColor.liqImediata(0.3) }}>
								0.3
							</TableCell>
						</TableRow> */}
						<TableRow
							key={'Corrente'}
							// sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
						>
							<TableCell component='th' scope='row' sx={{ fontWeight: 500 }}>
								Corrente
							</TableCell>
							{liqCorrente
								.sort((a, b) => Number(a.key) - Number(b.key))
								.map((item) => (
									<TableCell
										sx={{ color: selectColor.liqCorrente(Number(item.value)) }}
									>
										{item.value}
									</TableCell>
								))}
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};
