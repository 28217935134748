import { useState } from 'react';
import {
	Card,
	CardContent,
	TextField,
	Button as ButtonUi,
	Box,
	InputLabel,
	Select,
	MenuItem,
	TextareaAutosize,
	SelectChangeEvent,
	Grid,
	Modal,
	IconButton,
	Typography,
} from '@mui/material';
import { Button } from '../Button';
import { Header } from '../Header';
import {
	Container,
	Form,
	FormField,
	Input,
	SubTitle,
	Title,
	Text,
	Load,
	LoadFile,
	AddNewFile,
	ContainerForm,
	ContainerTextArea,
} from './style';
import loadingArrow from '../../assets/arrowLoading.gif';
import { Close, UploadFile } from '@mui/icons-material';
import { cnpjMask, mascaraMoeda } from '../../utils/mask';
import { apiOperation, apiExtractor, headers } from '../../services/api';
import { Loading } from '../Loading';
import icoAdd from '../../assets/add.svg';
import { useNavigate } from 'react-router-dom';
import ErrorModal from '../ErrorModal';
import { getSessionUser } from "../../utils/users";
import { useEffect } from "react";
import { IUser } from "../Login/ILogin";
import { Empresas } from "../../utils/empresa"
import React from 'react';
import { Listar } from '../../models/analise';
import { HeaderModal } from '../Results/style';
import { useUnsavedChanges } from '../../data/analiseContext';

interface IArquivoGenerico {
	id: number;
	load?: boolean;
	name?: string | null;
	tipoDocumento: string;
}

interface IFormfind {
	cnpj: string;
	valorSolicitado: number;
	classificacaoInterna: EclassificacaoInterna | '';
	parecerComercial: string;
	tipoEmpresa: string;
	cnpjMatriz: string;
	urlSite: string;
	tipoDeAnalise: EtipoAnalise | '';
	tempoCooperativa: string;
	comprometimentoRenda: string;
	postoAtendimento: string;
	tipoFuncionario: string;
	faturamento: number;
	cnpjSolidario: string;
}

enum EclassificacaoInterna {
	Otimo = 'otimo',
	Bom = 'bom',
	Ruim = 'ruim'
}

enum EtipoAnalise {
	Corporate = "Corporate",
	Revenda = "Revenda",
	Aditivo = "Aditivo"
}

enum EtipoDocumento {
	BalancoDre = 'balanco ou dre',
}
let usuarioLogado: IUser | null = null;

interface response {
	erro: boolean;
	erros: string[];
	statusCode: number;
	valor: OpcaoConfiguracao[]
}

interface OpcaoConfiguracao {
	opcaoParametroId: string;
	descricaoParamentro: string;
	peso: number;
	fator: number;
	ativo: boolean;
	tipoParametro: number;
}

interface HistoryResult {
	found: boolean;
	analiseId?: string;
}

const InputStyled = { marginBottom: '8px', width: '100%' };

const dateMask = (value: string) => {
	// Remove any non-numeric characters
	value = value.replace(/\D/g, '');

	// Format the value as dd-MM-yyyy
	if (value.length > 2) {
		value = value.slice(0, 2) + '-' + value.slice(2);
	}
	if (value.length > 5) {
		value = value.slice(0, 5) + '-' + value.slice(5);
	}
	if (value.length > 10) {
		value = value.slice(0, 10);
	}
	return value;
};

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '30%',
	bgcolor: 'background.paper',
	boxShadow: 24,
	p: 4,
	borderRadius: '10px',
};

interface ModalState {
	open: boolean;
	title: string;
	message: string;
	existingId?: string;
}

export const NewSearch = () => {
	let navigate = useNavigate();
	const [form, setForm] = useState<IFormfind>({
		cnpj: '',
		valorSolicitado: 0,
		classificacaoInterna: '',
		parecerComercial: '',
		tipoEmpresa: '',
		cnpjMatriz: '',
		urlSite: '',
		tipoDeAnalise: '',
		tempoCooperativa: '',
		comprometimentoRenda: '',
		postoAtendimento: '',
		tipoFuncionario: '',
		faturamento: 0,
		cnpjSolidario: ''
	});
	const [open, setOpen] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [titleError, setTitleError] = useState<string>('');
	const [hasError, setHasError] = useState<boolean>(true);
	const [isMatriz, setTipoEmpresa] = useState<boolean>(true);
	const [hasLoad, setHasLoad] = useState<boolean>(false);
	const [hasLoadDocumento, setHasLoadDocumento] = useState<boolean>(false);
	const [loadingModal, setLoadingModal] = useState<boolean>(false);
	const [tresBalancos, setTresBalancos] = useState<string>('');
	const [tresBalancosLoad, setTresBalancosLoad] = useState<boolean>(false);
	const [analiseId, setAnaliseId] = useState<string>('');
	const [listNewUpload, setListNewUpload] = useState<IArquivoGenerico[]>([]);
	const [messagesLoading, setMessagesLoading] = useState<string>('');
	const [carregaItensSimpress, setCarregaItens] = useState(false);
	const [carregaItensBrf, setCarregaItensBrf] = useState(false);
	const [carregaItensTriunfante, setCarregaItensTriunfante] = useState(false);
	const [carregaItensBRT, setCarregaItensBRT] = useState(false);
	const [carregaItensJnsSeguradora, setCarregaItensJnsSeguradora] = useState(false);
	const [isValid, setIsValid] = useState(true);
	const [isValidSolidario, setIsValidSolidario] = useState(true);
	const [errorMessage, setErrorMessage] = useState("");
	const [opcaoConfiguracao, setOpcaoConfiguracao] = useState<OpcaoConfiguracao[]>([])
	const { setHasUnsavedChanges } = useUnsavedChanges();

	const [modalState, setModalState] = useState({
		open: false,
		title: '',
		message: '',
		existingId: '',
	});

	const validateCnpjCpf = (value: string) => {
		const cleanValue = value.replace(/[^\d]+/g, ''); // Remove todos os caracteres não numéricos
		let isValid = false;

		if (cleanValue.length === 11) {
			isValid = isCpf(cleanValue);
		} else if (cleanValue.length === 14) {
			isValid = isCnpj(cleanValue);
		}

		setIsValid(isValid);
		setErrorMessage(isValid ? "" : "CNPJ/CPF não é válido.");

		return isValid;
	};


	const handleChange = (event: any) => {
		const { name, value } = event.target;

		if (event.target.name === "cnpj") {
			var isValid = validateCnpjCpf(value);

			setIsValid(isValid);
			setErrorMessage(isValid ? "" : "CNPJ/CPF não é válido.");
		}

		if (event.target.name === "cnpjSolidario") {
			var isValid = validateCnpjCpf(value);

			setIsValidSolidario(isValid);
			setErrorMessage(isValid ? "" : "CNPJ/CPF não é válido.");
		}

		setForm({ ...form, [event.target.name]: event.target.value });
	};

	const handleChangeClassificacaoInterna = (event: any) => {
		setForm({ ...form, classificacaoInterna: event.target.value });
	};

	const handleChangeTipoDeAnalise = (event: any) => {
		setForm({ ...form, tipoDeAnalise: event.target.value });
	};

	const handleChangeTipoEmpresa = (event: any) => {

		if (event.target.value === 'filial') {
			setTipoEmpresa(false);
			setForm({ ...form, tipoEmpresa: event.target.value });
		}
		else {
			setTipoEmpresa(true);
			setForm({ ...form, tipoEmpresa: event.target.value });
		}
	};

	const handleInputChange = () => {
		setHasUnsavedChanges(true);
	};

	const handleSaveChanges = () => {
		// Lógica para salvar as alterações
		setHasUnsavedChanges(false);
	};

	useEffect(() => {

		usuarioLogado = getSessionUser();
		if (usuarioLogado?.empresa === Empresas.Simpress) {
			setCarregaItens(true)
		}
		else if (usuarioLogado?.empresa === Empresas.Brf) {
			getOptions()
			setCarregaItensBrf(true)
		}
		else if (usuarioLogado?.empresa === Empresas.Triunfante) {
			setCarregaItensTriunfante(true)
		}
		else if (usuarioLogado?.empresa === Empresas.BRT) {
			setCarregaItensBRT(true)
		}
		else if (usuarioLogado?.empresa === Empresas.JnsSeguradora) {
			setCarregaItensJnsSeguradora(true)
		}

	}, []);

	const getOptions = async (params = "") => {
		try {
			const config = {
				headers: headers(),
			};

			let urlString = 'configuracao/obter/ativos';

			const { data } = await apiOperation.get<response>(urlString, config);

			if (data) {
				setOpcaoConfiguracao(data.valor)
			}
		} catch (error: any) {

		}
	};

	const serviceFile = async (file: File, controler: any, tipoArquivo: string, idanalise: string = "") => {
		const formBody = new FormData();
		formBody.append('arquivo', file, file.name);

		controler(true);
		try {

			setLoadingModal(true);
			setMessagesLoading("Salvando documento...");

			const config = {
				headers: headers()
			}

			var analise = idanalise != "" ? idanalise : analiseId;
			debugger

			const response = await apiExtractor.post(
				`documento/upload?analiseId=${analise}&tipoDocumento=${tipoArquivo}`,
				formBody, config
			);

			const { data } = response;

			if (!data.erro) {
				setHasError(false);
				setLoadingModal(false);
				controler(false, file.name);

				const minhaAncora = document.getElementById('minhaAncora');
				if (minhaAncora) {
					minhaAncora.scrollIntoView({ behavior: 'smooth' });
				}
			} else {
				setOpen(true);
				setLoadingModal(false);
				const msgError = data.erros?.map((err: string) => err);
				setErrorMsg(msgError);
				setTitleError('Falha no Upload do Arquivo!');
			}

		} catch (error) {
			setLoadingModal(false);
			const msgError = `Falha no upload do arquivo ${file.name}. \n Erro: ${error} `;
			setErrorMsg(msgError);
			setTitleError('Falha no Upload do Arquivo!');
			controler(false);
		}
	};

	const postAnalyze = async () => {
		try {
			const config = {
				headers: headers()
			}
			setMessagesLoading('Processando...');
			setLoadingModal(true);

			if(carregaItensJnsSeguradora){
				await apiExtractor.post(`extrator/extrair/documento`, {
					analiseId: analiseId,
				}, config);
			}
			else{
				await apiExtractor.post(`extrator/extrair`, {
					analiseId: analiseId,
				}, config);
			}

			setMessagesLoading('Redirecionando para página de histórico...');
			setTimeout(() => {
				navigate('/historico');
				setLoadingModal(false);
			}, 2000);
		} catch (error) {
			const msgError = `Erro: ${error} `;
			setErrorMsg(msgError);
			setTitleError('Falha na Analise do CNPJ!');
			setHasError(true);
			setLoadingModal(false);
		}
	};

	const handleBalancos = ({ target }: any) => {
		if (!target.files) {
			return;
		}
		const file = target.files[0];
		serviceFile(file, setTresBalancosLoad, '');
		const { name } = file;
		setTresBalancos(name);
	};

	const handleClose = () => {
		setOpen(false);

		setModalState({
			open: false,
			title: '',
			message: '',
			existingId: '',
		});
	};

	const updateAnaliseStatus = async () => {

		const config = {
			headers: headers()
		}

		setLoadingModal(true);
		setMessagesLoading("Atualizando status...");

		var response = await apiOperation.get(
			`analise/atualizar?analiseId=${analiseId}`,
			config,
		);

		if (response.data.valor) {
			setTimeout(() => {
				handleSaveChanges();
				navigate('/historico');
				setLoadingModal(false);
			}, 2000);
		}
		else {
			setLoadingModal(false);
		}
	}

	const continuarAnalise = () => {
		setHasError(false);
		setLoadingModal(false);

		const minhaAncora = document.getElementById('minhaAncora');

		if (minhaAncora) {
			minhaAncora.scrollIntoView({ behavior: 'smooth' });
		}
	}

	const handleMoneyValue = (value: any) => {
		let money: any = 0;
		if (value) {
			money = value.replace('R$ ', '').replace(/\./g, '').replace(',', '.');
		}
		else {
			money = '0.0'
		}
		return money;
	}

	const analysisCNPJ = async (_form: IFormfind) => {

		var result = await getHistory();

		if (result.found) {
			setLoadingModal(false);
			openModalWithMessage('Registro existente com menos de 3 meses, \n Deseja continuar com a análise?', result.analiseId ?? "");
		}
		else {
			setLoadingModal(false);
			setModalState({
				open: false,
				title: '',
				message: '',
				existingId: '',
			});
			await verificarCNPJ(_form);
		}
	};

	const verificarCNPJ = async (_form: IFormfind) => {
		setHasLoad(true);
		const body = {
			cnpjOuCpf: form.cnpj.replace(/\D/g, ''),
			ValorSolicitado: handleMoneyValue(form.valorSolicitado),
			ClassificacaoInterna: form.classificacaoInterna,
			ParecerComercial: form.parecerComercial,
			TipoEmpresa: form.tipoEmpresa,
			CnpjMatriz: form.cnpjMatriz.replace(/\D/g, ''),
			UrlSite: form.urlSite,
			TipoDeAnalise: form.tipoDeAnalise,
			tempoCooperativa: form.tempoCooperativa,
			ComprometimentoRenda: form.comprometimentoRenda,
			PostoAtendimento: form.postoAtendimento,
			TipoFuncionario: form.tipoFuncionario,
			Faturamento: handleMoneyValue(form.faturamento),
			CnpjSolidario: form.cnpjSolidario

		};
		try {

			setLoadingModal(true);
			setMessagesLoading("Verificando CNPJ...")
			const config = {
				headers: headers()
			}
			const response = await apiOperation.post(`/analise/verificar`, body, config);

			const { data } = response;

			if (!data.erro) {
				setHasError(false);
				setLoadingModal(false);
				const { valor } = data;
				const { analiseId } = valor;
				setAnaliseId(analiseId);
				handleInputChange();
			} else {
				setOpen(true);
				const msgError = data.erros?.map((err: string) => err);
				setErrorMsg(msgError);
				const { valor } = data;
				const { analiseId } = valor;
				setAnaliseId(analiseId);
				setLoadingModal(false);
			}
		} catch (error: any) {
			setHasError(true);
			setOpen(true);
			const msgError = `${error.code}
			Atualize a pagina e tente novamente!`;
			setErrorMsg(msgError);
			setLoadingModal(false);
		}
	};

	const openModalWithMessage = (message: string, existingId: string) => {
		setModalState({
			open: true,
			title: 'Registro Existente',
			message: message,
			existingId: existingId,
		});
	};

	const getHistory = async (params = ""): Promise<HistoryResult> => {
		try {
			const config = {
				headers: headers(),
			};
			setLoadingModal(true);
			let urlString = `analise/listar
					?pagina=1
					&registrosPorPagina=10&cnpjOuCpf=${form.cnpj.replace(/\D/g, '')}`;

			const { data } = await apiOperation.get<Listar>(urlString, config);

			if (!data.erro && data.valor.dados.length > 0) {

				const now = new Date();
				for (const analise of data.valor.dados) {
					const dataInclusao = new Date(analise.dataInclusao);
					const diffMonths = (now.getFullYear() - dataInclusao.getFullYear()) * 12 + (now.getMonth() - dataInclusao.getMonth());

					if (diffMonths < 3 && analise.status === 'Concluída') {
						return { found: true, analiseId: analise.analiseId };
					}
				}
				setLoadingModal(false);
			}

			return { found: false };
		} catch (error: any) {
			setLoadingModal(false);
			return { found: false };
		}
	};


	const handleContinue = () => {
		handleClose();
		verificarCNPJ(form);
	};

	const handleViewExisting = () => {
		window.location.href = `/resultados/${modalState.existingId}`;
	};

	const handleNewUpload = () => {
		setListNewUpload([...listNewUpload, { id: listNewUpload.length, name: null, load: false, tipoDocumento: '' },
		]);
	};

	const handleSetArquivoGenericoLoad = (load: boolean, fileNAme: string) => {
		const _list = listNewUpload.map((arq) => {
			if (arq.name === fileNAme) {
				arq.load = load;
			}
			return arq;
		});
		setListNewUpload(_list);
	};
	const handleArquivoGenerico = async (target: any, id: number) => {
		if (!target.files) {
			return;
		}

		let cnpjInput = document.getElementById('cnpj') as HTMLInputElement;
		let cnpjValue = cnpjInput.value.replace(/\D/g, '');

		if(cnpjValue == "")
		{
			alert("CNPJ é Obrigatório!")
			return;
		}

		let tipoArquivo = '';

		const validaTipoDoc = listNewUpload.map((arq) => {
			if (arq.id === id) {
				tipoArquivo = arq.tipoDocumento;
			}
			return arq;
		});

		if (tipoArquivo === '') {
			setOpen(true);
			const msgError = 'Selecione um Tipo de Documento';
			setErrorMsg(msgError);
			setTitleError('Falha no Upload do Arquivo!');

			return;
		}

		const file = target.files[0];

		const { name } = file;
		const newList = listNewUpload.map((arq) => {
			if (arq.id === id) {
				arq.name = name;

				tipoArquivo = arq.tipoDocumento;
			}
			return arq;
		});

		debugger
		if(analiseId == '')
		{
			//cria uma analise
			setLoadingModal(true);
			setMessagesLoading("Salvando documento...");

			try{
				const config = {
					headers: headers()
				}
	
				const response = await apiExtractor.post(
					`extrator/criar?cnpj=${cnpjValue}`,
					'', config
				);
	
				const { data } = response;
				if(response.status == 200){
					setAnaliseId(data)
	
					setListNewUpload(newList);
					await serviceFile(file, handleSetArquivoGenericoLoad, tipoArquivo, data);
				}
				else
				{
					setLoadingModal(false);
					const msgError = `Falha no upload do arquivo.`;
					setErrorMsg(msgError);
					setTitleError('Falha no Upload do Arquivo!');
					return;
				}
			}
			catch (error: any){
				setLoadingModal(false);
				setOpen(true);
				const msgError = `${error.code}
				Atualize a pagina e tente novamente!`;
				setErrorMsg(msgError)
			}
			
		}
		else
		{
			setListNewUpload(newList);
			await serviceFile(file, handleSetArquivoGenericoLoad, tipoArquivo);
		}

		setLoadingModal(false);
	};

	const handleChangeTipoDocumento = (event: any, id: number) => {
		const newList = listNewUpload.map((arq) => {
			if (arq.id === id) {
				arq.tipoDocumento = event.value
			}

			return arq;
		});

		setListNewUpload(newList);
	};

	const isCnpj = (cnpj: string): boolean => {
		const multiplicador1 = [5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];
		const multiplicador2 = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2];

		let soma: number;
		let resto: number;
		let digito: string;
		let tempCnpj: string;

		cnpj = cnpj.trim().replace(/[^\d]+/g, '');

		if (cnpj.length !== 14) return false;

		tempCnpj = cnpj.substring(0, 12);
		soma = 0;

		for (let i = 0; i < 12; i++) {
			soma += parseInt(tempCnpj[i]) * multiplicador1[i];
		}

		resto = (soma % 11);
		resto = resto < 2 ? 0 : 11 - resto;
		digito = resto.toString();
		tempCnpj = tempCnpj + digito;
		soma = 0;

		for (let i = 0; i < 13; i++) {
			soma += parseInt(tempCnpj[i]) * multiplicador2[i];
		}

		resto = (soma % 11);
		resto = resto < 2 ? 0 : 11 - resto;
		digito = digito + resto.toString();

		return cnpj.endsWith(digito);
	};

	const isCpf = (cpf: string): boolean => {
		const multiplicador1 = [10, 9, 8, 7, 6, 5, 4, 3, 2];
		const multiplicador2 = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2];

		let soma: number;
		let resto: number;
		let digito: string;
		let tempCpf: string;

		cpf = cpf.trim().replace(/[^\d]+/g, '');

		if (cpf.length !== 11) return false;

		tempCpf = cpf.substring(0, 9);
		soma = 0;

		for (let i = 0; i < 9; i++) {
			soma += parseInt(tempCpf[i]) * multiplicador1[i];
		}

		resto = soma % 11;
		resto = resto < 2 ? 0 : 11 - resto;
		digito = resto.toString();
		tempCpf = tempCpf + digito;
		soma = 0;

		for (let i = 0; i < 10; i++) {
			soma += parseInt(tempCpf[i]) * multiplicador2[i];
		}

		resto = soma % 11;
		resto = resto < 2 ? 0 : 11 - resto;
		digito = digito + resto.toString();

		return cpf.endsWith(digito);
	};

	const handleSelectChange = (field: keyof IFormfind) => (event: SelectChangeEvent<string>) => {
		setForm(prevForm => ({
			...prevForm,
			[field]: event.target.value,
		}));
	};

	const tiposParametros = {
		tempoCooperativa: 111,
		comprometimentoRenda: 109,
		postoAtendimento: 110,
	};

	const opcoesFiltradas = (tipo: number) => opcaoConfiguracao.filter(opcao => opcao.tipoParametro === tipo);

	const handleDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const maskedValue = dateMask(event.target.value);
		setForm({ ...form, tempoCooperativa: maskedValue });
	};

	return (
		<>
			<Header />
			<Loading
				mode='blurry'
				hidden={!loadingModal}
				messages={messagesLoading}
			/>
			<Container>

				<Title>Nova Pesquisa</Title>

				<Card sx={{ minWidth: 275 }}>
					<CardContent>

						{!carregaItensJnsSeguradora && (
							<div>
								<SubTitle>Digite o CNPJ da empresa que deseja pesquisar</SubTitle>
								<ContainerForm>
									<div style={{ padding: "10px" }}>
										<TextField
											id="cnpj"
											label="CNPJ"
											variant="outlined"
											name="cnpj"
											type="text"
											style={InputStyled}
											value={cnpjMask(form.cnpj)}
											onChange={handleChange}
											error={!isValid}
											helperText={!isValid && errorMessage}
										/>
									</div>
									<div style={{ padding: "10px" }}>
										<TextField
											id='valorSolicitado'
											label='Valor Solicitado'
											variant='outlined'
											name='valorSolicitado'
											type={'text'}
											style={InputStyled}
											value={form.valorSolicitado}
											onChange={handleChange}
											onInput={mascaraMoeda}
										/>
									</div>
									{carregaItensTriunfante && (
										<div style={{ padding: "10px" }}>
											<TextField
												id='faturamento'
												label='Faturamento'
												variant='outlined'
												name='faturamento'
												type={'text'}
												style={InputStyled}
												value={form.faturamento}
												onChange={handleChange}
												onInput={mascaraMoeda}
											/>
										</div>
									)}
									{carregaItensSimpress && (
										<div>
											<TextField
												id='urlSite'
												label='Url Site'
												variant='outlined'
												name='urlSite'
												type={'text'}
												style={InputStyled}
												value={form.urlSite}
												onChange={handleChange}
											/>
										</div>
									)}
									<div style={{ paddingBottom: '30px' }}>
										<InputLabel id="classificacaoInternaLabel">Classificacao Interna</InputLabel>
										<Select
											fullWidth
											labelId="classificacaoInternaLabel"
											id="classificacaoInterna"
											value={form.classificacaoInterna}
											onChange={handleChangeClassificacaoInterna}
										>
											<MenuItem value={EclassificacaoInterna.Otimo} >{EclassificacaoInterna.Otimo}</MenuItem>
											<MenuItem value={EclassificacaoInterna.Bom} >{EclassificacaoInterna.Bom}</MenuItem>
											<MenuItem value={EclassificacaoInterna.Ruim} >{EclassificacaoInterna.Ruim}</MenuItem>

										</Select>
									</div>

									{carregaItensSimpress && (
										<div style={{ paddingBottom: '30px', paddingLeft: '25px' }}>
											<InputLabel id="tipoAnaliseLabel">Tipo análise</InputLabel>
											<Select
												fullWidth
												labelId="tipoAnaliseLabel"
												id="tipoAnalise"
												value={form.tipoDeAnalise}
												onChange={handleChangeTipoDeAnalise}
											>
												<MenuItem value={EtipoAnalise.Corporate} >{EtipoAnalise.Corporate}</MenuItem>
												<MenuItem value={EtipoAnalise.Revenda} >{EtipoAnalise.Revenda}</MenuItem>
												<MenuItem value={EtipoAnalise.Aditivo} >{EtipoAnalise.Aditivo}</MenuItem>

											</Select>
										</div>
									)}

								</ContainerForm>
								<div>
									<Grid container spacing={2} alignItems="flex-start">

										{carregaItensBRT && (
											<Grid item xs={12} sm={6} md={2}>
												<TextField
													id="cnpjSolidario"
													label="CPF Responsável Solidário"
													variant="outlined"
													name="cnpjSolidario"
													type="text"
													style={InputStyled}
													value={cnpjMask(form.cnpjSolidario)}
													onChange={handleChange}
													error={!isValidSolidario}
													helperText={!isValidSolidario && errorMessage}
												/>
											</Grid>
										)}
									</Grid>
								</div>

								<ContainerTextArea>
									<InputLabel>Parecer Comercial</InputLabel>
									<TextareaAutosize
										maxRows={12}
										minRows={8}
										id="parecerComercial"
										name="parecerComercial"
										aria-label="maximum height"
										placeholder="Parecer Comercial"
										value={form.parecerComercial}
										style={{ width: '100%' }}
										onChange={handleChange}
									/>
								</ContainerTextArea>
								<div style={{ display: 'flex' }}>
									{isValid && (
										<Button
											className='verify'
											onClick={() => {
												analysisCNPJ(form);
											}}
											sx={{
												width: '10.4em',
											}}
										>
											Verificar CNPJ
										</Button>
									)}

								</div>

								{hasLoad && (
									<Loading
										mode='blurry'
										hidden={!loadingModal}
										messages={messagesLoading}
									/>
								)}
							</div>
						)}

						{carregaItensJnsSeguradora && (
							<ContainerForm>
								<div style={{ padding: "10px" }}>
									<TextField
										id="cnpj"
										label="CNPJ"
										variant="outlined"
										name="cnpj"
										type="text"
										style={InputStyled}
										value={cnpjMask(form.cnpj)}
										onChange={handleChange}
										error={!isValid}
										helperText={!isValid && errorMessage}
									/>
								</div>
							</ContainerForm>
						)}

						{!carregaItensJnsSeguradora && !hasError && (
							<CardContent>
								<SubTitle>
									Carregue os documentos legais para a pesquisa completa
								</SubTitle>
								<Form>
									{listNewUpload.length > 0 &&
										listNewUpload?.map((arquivo) => (
											<FormField key={arquivo.id} style={{ padding: '22px' }}>
												<div style={{ paddingLeft: '22px', marginTop: '-35px' }}>
													<InputLabel id="tipodocumentoLabel">Tipo de documento</InputLabel>
													<Select
														fullWidth
														labelId="tipodocumentoLabel"
														id="tipodocumento"
														value={arquivo.tipoDocumento}
														onChange={({ target }) => {
															handleChangeTipoDocumento(target, arquivo.id);
														}}
													>
														<MenuItem value={EtipoDocumento.BalancoDre} >{EtipoDocumento.BalancoDre}</MenuItem>
													</Select>
												</div>
												
												{arquivo.tipoDocumento === EtipoDocumento.BalancoDre && (
													<div>
														<Text>Arquivo {arquivo.id}:</Text>
														<label htmlFor={`contained-arquivo${arquivo.id}`}>
															<ButtonUi
																variant='outlined'
																component='div'
																endIcon={<UploadFile />}
																sx={{
																	borderStyle: 'dashed',
																	borderRadius: '15px',
																}}
															>
																{!arquivo.name && 'Selecione Seu Arquivo Em PDF'}
																<Input
																	accept='.pdf'
																	id={`contained-arquivo${arquivo.id}`}
																	multiple
																	hidden
																	type='file'
																	onChange={({ target }) => {
																		handleArquivoGenerico(target, arquivo.id);
																	}}
																/>
																{arquivo.load ? (
																	<LoadFile>
																		<img
																			style={{ width: '25px' }}
																			src={loadingArrow}
																			alt=''
																		/>
																	</LoadFile>
																) : (
																	<Box>{arquivo.name}</Box>
																)}
															</ButtonUi>
														</label>
													</div>
												)}
											</FormField>
										))}
								</Form>
								{hasLoadDocumento && (
									<Load>
										<div>
											<img src={loadingArrow} alt='gif de carregamento' />
										</div>
										<div>
											<span>Enviando documento...</span>
										</div>
									</Load>
								)}

								{!hasLoadDocumento && (
									<AddNewFile>
										<div onClick={handleNewUpload}>
											<img src={icoAdd} alt='Icone Adicionar' />
										</div>
									</AddNewFile>
								)}
								<div id="minhaAncora">
									<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<ButtonUi
											variant='contained'
											color='secondary'
											onClick={postAnalyze}
											sx={{
												width: '10.4em',
											}}
										>
											Analisar
										</ButtonUi>
									</Box>
								</div>

							</CardContent>
						)}

						{carregaItensJnsSeguradora && (
							<CardContent>
								<SubTitle>
									Carregue os documentos legais para a pesquisa completa
								</SubTitle>
								<Form>
									{listNewUpload.length > 0 &&
										listNewUpload?.map((arquivo) => (
											<FormField key={arquivo.id} style={{ padding: '22px' }}>
												<div style={{ paddingLeft: '22px', marginTop: '-35px' }}>
													<InputLabel id="tipodocumentoLabel">Tipo de documento</InputLabel>
													<Select
														fullWidth
														labelId="tipodocumentoLabel"
														id="tipodocumento"
														value={arquivo.tipoDocumento}
														onChange={({ target }) => {
															handleChangeTipoDocumento(target, arquivo.id);
														}}
													>
														<MenuItem value={EtipoDocumento.BalancoDre} >{EtipoDocumento.BalancoDre}</MenuItem>
													</Select>
												</div>
												
												{arquivo.tipoDocumento === EtipoDocumento.BalancoDre && (
													<div>
														<Text>Arquivo {arquivo.id}:</Text>
														<label htmlFor={`contained-arquivo${arquivo.id}`}>
															<ButtonUi
																variant='outlined'
																component='div'
																endIcon={<UploadFile />}
																sx={{
																	borderStyle: 'dashed',
																	borderRadius: '15px',
																}}
															>
																{!arquivo.name && 'Selecione Seu Arquivo Em PDF'}
																<Input
																	accept='.pdf'
																	id={`contained-arquivo${arquivo.id}`}
																	multiple
																	hidden
																	type='file'
																	onChange={({ target }) => {
																		handleArquivoGenerico(target, arquivo.id);
																	}}
																/>
																{arquivo.load ? (
																	<LoadFile>
																		<img
																			style={{ width: '25px' }}
																			src={loadingArrow}
																			alt=''
																		/>
																	</LoadFile>
																) : (
																	<Box>{arquivo.name}</Box>
																)}
															</ButtonUi>
														</label>
													</div>
												)}
											</FormField>
										))}
								</Form>
								{hasLoadDocumento && (
									<Load>
										<div>
											<img src={loadingArrow} alt='gif de carregamento' />
										</div>
										<div>
											<span>Enviando documento...</span>
										</div>
									</Load>
								)}

								{!hasLoadDocumento && (
									<AddNewFile>
										<div onClick={handleNewUpload}>
											<img src={icoAdd} alt='Icone Adicionar' />
										</div>
									</AddNewFile>
								)}
								<div id="minhaAncora">
									<Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
										<ButtonUi
											variant='contained'
											color='secondary'
											onClick={postAnalyze}
											sx={{
												width: '10.4em',
											}}
										>
											Analisar
										</ButtonUi>
									</Box>
								</div>

							</CardContent>
						)}
					</CardContent>
				</Card>
				<ErrorModal
					open={open}
					handleClose={handleClose}
					errorMsg={errorMsg}
					title="Pontos de antção na análise!"
					onExit={updateAnaliseStatus}
					continuarAnalise={continuarAnalise}
				/>

				<Modal
					open={modalState.open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'
					sx={{ minWidth: '60%' }}
				>
					<Box sx={style}>
						<HeaderModal>
							<SubTitle>{modalState.title}</SubTitle>
							<IconButton onClick={handleClose}>
								<Close />
							</IconButton>
						</HeaderModal>
						<Box>
							<Typography>{modalState.message}</Typography>
							<ButtonUi
								sx={{
									width: '10.4em',
									margin: '2%',
								}}
								color='secondary'
								variant='contained'
								onClick={handleContinue}
							>
								Continuar com a análise
							</ButtonUi>

							<ButtonUi
								sx={{
									width: '10.4em',
									margin: '2%',
								}}
								color='secondary'
								variant='contained'
								onClick={handleViewExisting}
							>
								Ver análise existente
							</ButtonUi>
						</Box>
					</Box>
				</Modal>

			</Container>
		</>
	);
};
